import { Vue, Component, VModel, Prop } from 'vue-property-decorator';

@Component({})
export default class ConfirmationDialog extends Vue {
  @VModel()
  showConfirmationDialog: any;
  @Prop({
    required: false,
    default: 'primary'
  })
  confirmationColor: any;
  @Prop({
    required: false,
    default: 'grey'
  })
  cancelColor: any;

  cancel() {
    this.showConfirmationDialog = false;
    this.$emit('cancel');
  }

  submit() {
    this.showConfirmationDialog = false;
    this.$emit('submit');
  }
}

import { AuthResultViewModel, SystemPermission } from '@/api/api';

import { Component, Vue } from 'vue-property-decorator';

import { Prop } from 'vue-property-decorator';

import { AuthAction, AuthGetter, AuthNamespace } from '@/store/auth/auth.module.types';
import { testPermission } from '@/utilities/auth/auth.permissions';

type PermissionCheckMode = 'any' | 'all';

@Component({
  name: 'PermissionChecker'
})
export default class PermissionChecker extends Vue {
  @AuthNamespace.Getter(AuthGetter.credential)
  readonly credential!: AuthResultViewModel;

  @Prop({ default: 'any' }) readonly enforceMode!: PermissionCheckMode;
  @Prop({ default: [] }) readonly permissions!: SystemPermission[];

  get hasPermission() {
    if (!this.credential) {
      return false;
    }

    if (this.credential.permissions?.includes(SystemPermission.ScanToPickAdmin)) {
      return true;
    }

    if (this.enforceMode === 'any') {
      for (const permission of this.permissions) {
        if (testPermission(this.credential.permissions ?? [], permission)) {
          return true;
        }
      }

      return false;
    } else if (this.enforceMode === 'all') {
      for (const permission of this.permissions) {
        if (!testPermission(this.credential.permissions ?? [], permission)) {
          return false;
        }
      }
      return true;
    }

    return false;
  }
}

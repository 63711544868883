
import { SessionConnection } from './api/hubConnection';

import { dispatchAuthAction } from '@/store/auth/auth.dispatch';
import { AuthAction, AuthGetter, AuthNamespace } from './store/auth/auth.module.types';
import { dispatchConfigurationAction } from './store/configuration/configuration.dispatch';
import { ConfigurationAction } from './store/configuration/configuration.module.types';
import { AlertAction, AlertNamespace, SnackbarMessage } from './store/alert/alert.module-types';
import { AuthResultViewModel, OrderListOutput, SystemPermission, AmsOrderLineItemsClient } from '@/api/api';

import { Component, Provide, Vue, Watch } from 'vue-property-decorator';

import Snackbar from './components/alerts/Snackbar.vue';
import PermissionChecker from '@/views/_components/PermissionChecker.vue';
import UserProfileCard from '@/views/_components/UserProfileCard.vue';
import AssignOrder from '@/views/_components/assign-order/AssignOrder.vue';
import { dispatchUserAction } from '@/store/user/user.dispatch';
import { UserAction } from '@/store/user/user.module.types';
import ClockBlockOverlay from '@/views/_components/clock-block/ClockBlockOverlay.vue';

export const assignOrderFuncKey = Symbol();
//
@Component({
  name: 'App',
  components: {
    Snackbar,
    UserProfileCard,
    PermissionChecker,
    AssignOrder,
    ClockBlockOverlay,
  },
})
export default class App extends Vue {
  readonly UP = SystemPermission;
  $refs!: Vue['$refs'] & {
    userProfileCard: UserProfileCard;
  };
  assignOrderDialog = false;
  assignOrder: OrderListOutput | null = null;
  isMobile = false;
  drawer = false;
  pickerUsername = "";
  tabs = [
    {
      label: 'All Pickers',
      to: '/clerk/pickers',
      permissions: [SystemPermission.ScanToPickClerk, SystemPermission.ScanToPickAdmin]
    },
    {
      label: 'Clerk Dashboard',
      to: '/clerk/orders',
      permissions: [SystemPermission.ScanToPickClerk, SystemPermission.ScanToPickAdmin]
    },
    {
      label: 'QC Dashboard',
      to: '/qc',
      permissions: [SystemPermission.ScanToPickQC, SystemPermission.ScanToPickAdmin]
    },
    {
      label: 'Pick',
      to: '/pick',
      permissions: [SystemPermission.ScanToPickPicker, SystemPermission.ScanToPickAdmin]
    },
    {
      label: 'Admin',
      to: '/admin',
      permissions: [SystemPermission.ScanToPickAdmin]
    }
  ];

  @AuthNamespace.Getter(AuthGetter.credential)
  readonly credential!: AuthResultViewModel;


  @AlertNamespace.Action(AlertAction.showSnackbar)
  readonly showSnackbar!: (payload: SnackbarMessage) => void;

  get showNavbar() {
    return this.credential != null && this.$route.meta?.hideNavbar !== true;
  }

  incorrectLanesInterval: number | null = null;
  incorrectLanes: number | null = null;

  @Watch('credential', { immediate: true })
  async onUserLogin() {
    if (this.credential) {
      const [user] = await Promise.all([
        dispatchAuthAction(AuthAction.getCurrentUserInfo),
        dispatchUserAction(UserAction.refreshClockedTime),
      ]);

      if (user) {
        if (user.permissions == null) {
          user.permissions = [];
        }

        if (user.permissions.includes(SystemPermission.ScanToPickClerk) || user.permissions.includes(SystemPermission.ScanToPickAdmin)) {
          const amsOrderLineItemsClient = new AmsOrderLineItemsClient();
          // ensuring we don't have multiple requests running at the same time
          let lastCountPromise: Promise<number> | null = null;
          const getCounts = async () =>{
            if (lastCountPromise == null) {
              lastCountPromise = amsOrderLineItemsClient.getIncorrectLaneReportsCount();
              lastCountPromise.then((res) => {
                this.incorrectLanes = res;
                lastCountPromise = null;
              });
            }
          }

          getCounts();
          this.incorrectLanesInterval = setInterval(getCounts, 10000);
        }

        this.pickerUsername = user.firstName + " " + user.lastName
        await dispatchConfigurationAction(ConfigurationAction.loadConfigurations);
      }
    } else {
      clearInterval(this.incorrectLanesInterval!);
      this.incorrectLanes = null;
    }
  }

  created() {
    this.checkIfMobile();
    window.addEventListener('resize', this.checkIfMobile);
  }

  destroyed() {
    window.removeEventListener('resize', this.checkIfMobile);
  }

  checkIfMobile() {
    // console.log('checking if mobile', this.$vuetify.breakpoint.sm);
    // don't check the bools, check the thresholds. they take a while to update
    if (this.$vuetify.breakpoint.thresholds.xs < window.innerWidth) {
      this.isMobile = false;
    } else {
      this.isMobile = true;
    }
  }

  openUserProfileCard() {
    this.$refs.userProfileCard.openDialog();
  }

  @Provide('assignOrderFunc')
  assignOrderFunc(order: OrderListOutput): void {
    this.assignOrderDialog = true;
    this.assignOrder = order;
  }

}

const connectAuthHub = async () => {
  const removeTokenCallback = () => {
    dispatchAuthAction(AuthAction.logoutCredential);
  };

  const connection = new SessionConnection(removeTokenCallback);
  await connection.startConnection();
};

// currently disabled because it was intended for the old auth system
// connectAuthHub();

import { OrderItemViewModel } from '@/api/api';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';
import { dispatchOrderAction } from '@/store/order/order-dispatch';
import {
  OrderAction,
  OrderGetter,
  OrderNamespace
} from '@/store/order/order-module-types';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component
export default class ClerkOrderLineItemNoteDialog extends Vue {
  @OrderNamespace.Getter(OrderGetter.orderLineItemNoteDialogVisible)
  orderLineItemNoteDialogVisible!: boolean;
  @OrderNamespace.Getter(OrderGetter.orderLineItem)
  orderLineItem!: OrderItemViewModel;

  note = '';
  isLoading = false;

  @Watch('orderLineItem')
  orderLineItemChange(value: OrderItemViewModel): void {
    if (value) {
      this.note = value.note ?? '';
    }
  }

  closeDialog(): void {
    dispatchOrderAction(OrderAction.showOrderLineItemNoteDialog, null);
  }

  clearNote(): void {
    this.note = '';
  }

  async save(): Promise<void> {
    this.isLoading = true;
    try {
      const payload = {
        id: this.orderLineItem.id,
        note: this.note
      };
      await dispatchOrderAction(OrderAction.editOrderLineItemNote, payload);
      dispatchAlertAction(
        AlertAction.showSuccess,
        'Order Line Item Note edited.'
      );
      dispatchOrderAction(
        OrderAction.loadOrderDetail,
        this.orderLineItem.orderId
      );

      this.closeDialog();
    } catch (error) {
      dispatchAlertAction(AlertAction.showError, error as string);
    } finally {
      this.isLoading = false;
    }

  }
}

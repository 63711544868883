import { OrderViewModel } from '@/api/api';
import { VForm } from '@/utilities/form';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  dispatchPickerAction,
  PickerAction,
  PickerGetter,
  pickerNamespace
} from '../_state/picker-module-type';

@Component
export default class PickerCompleteOrderDialog extends Vue {
  $refs!: Vue['$refs'] & {
    form: VForm;
  };

  @pickerNamespace.Getter(PickerGetter.order)
  readonly order!: OrderViewModel;
  @pickerNamespace.Getter(PickerGetter.confirmCompleteOrderDialog)
  readonly dialog!: boolean;

  isFormValid = false;
  loading = false;

  @Prop({
    type: Function,
    required: true
  })
  readonly onSubmit!: () => Promise<void>;
  @Prop({
    type: Boolean,
    required: false
  })
  readonly isFinal: boolean = false;
  @Prop({
    type: Number,
    required: false
  })
  readonly orderLineItemId: number = 0;

  close(): void {
    dispatchPickerAction(PickerAction.hideConfirmCompleteOrderDialog);
  }
  async submit(): Promise<void> {
    if (this.$refs.form.validate()) {
      try {
        this.loading = true;
        await this.onSubmit();
      } finally {
        this.loading = false;
      }
    }
  }
}

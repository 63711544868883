import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    default: 'light',
    options: { customProperties: true },

    themes: {
      light: {
        clockedIn: '#47a44b',
        onBreak: '#878787',
        clockedOut: '#d1392d',
      },
    },
  }
});

import {
  PickerCardAction,
  PickerCardGetter,
  PickerCardModule,
  PickerCardMutation,
  PickerCardState
} from './pickerCard-module-types';

export const pickerCardModule: PickerCardModule = {
  namespaced: true,
  state: {
    [PickerCardState.pickerCards]: [],
    [PickerCardState.filters]: {}
  },
  mutations: {
    [PickerCardMutation.setPickerCards]: (state, payload) => {
      state[PickerCardState.pickerCards] = payload || [];
    },
    [PickerCardMutation.setFilters]: (state, payload) => {
      state[PickerCardState.filters] = payload;
    }
  },
  actions: {
    [PickerCardAction.setFilters]: async ({ commit }, filters) => {
      commit(PickerCardMutation.setFilters, { ...filters });
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    [PickerCardAction.loadPickers]: async () => {}
  },
  getters: {
    [PickerCardGetter.pickerCards]: (state) => {
      return state[PickerCardState.pickerCards];
    },
    [PickerCardGetter.filters]: (state) => {
      return state[PickerCardState.filters];
    },
    [PickerCardGetter.filteredPickers]: (state) => {
      const { statusCode, searchText } = state.filters;
      let pickers = state.pickerCards.map((p) => ({
        ...p,
        searchOrders: p.orders?.map((o) => o.id).join(',')
      }));
      if (statusCode) {
        pickers = pickers.filter((p) => p.statusCode === statusCode);
      }
      if (searchText) {
        pickers = pickers.filter(
          (p) =>
            p.name?.toLowerCase().includes(searchText.toLowerCase()) ||
            p.searchOrders?.includes(searchText)
        );
      }
      return pickers;
    }
  }
};

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"scrollable":"","width":750},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 d-flex align-center px-4 user-profile-title"},[_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.userFullname))]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.closeModal}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-spacer'),_c('v-layout',{staticClass:"mt-5 user-profile-card",attrs:{"wrap":"","flex-column-reverse":"","flex-sm-row":""}},[_c('permission-checker',{attrs:{"permissions":[_vm.SystemPermission.ScanToPickPicker]}},[_c('v-flex',{attrs:{"xs12":"","sm7":"","pr-0":"","pr-sm-6":"","mt-4":"","mt-sm-0":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.orders,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',{class:{
                    'blue lighten-5': item.orderStatusCode === _vm.AmsOrderStatusEnum.InProgress,
                    'green lighten-5': item.orderStatusCode === _vm.AmsOrderStatusEnum.Completed
                  }},[_c('td',[_c('router-link',{attrs:{"to":`/pick/orders/${item.orderId}`},nativeOn:{"click":function($event){return _vm.closeModal.apply(null, arguments)}}},[_vm._v(" "+_vm._s(item.amsOrderId?.toFixed(1))+" ")])],1),_c('td',{class:{
                      'primary--text': item.orderStatusCode === _vm.AmsOrderStatusEnum.InProgress,
                      'success--text': item.orderStatusCode === _vm.AmsOrderStatusEnum.Completed
                    }},[_vm._v(" "+_vm._s(_vm._f("orderStatusString")(item.orderStatusCode))+" ")]),_c('td',[_vm._v(_vm._s(item.numberOfCases)+" / "+_vm._s(item.numberOfBottles))])])]}}])})],1)],1),_c('v-flex',{attrs:{"xs12":"","sm5":""}},[_c('TimeStatsWidget',{attrs:{"enabled":_vm.dialog}}),_c('UserClockWidget',{model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":_vm.isSavingStatus,"color":"error","text":""},on:{"click":_vm.logout}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-arrow-left-bold-box-outline")]),_c('span',[_vm._v("LOGOUT")])],1),_c('v-spacer')],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
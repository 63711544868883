<template>
  <fragment>
    <v-container>
      <v-sheet rounded elevation="4" class="pa-0 mt-4">

        <div class="d-flex flex-column pl-4" style="position: relative;">
          <div class="d-flex align-center mr-5 fill-height mb-2 mb-sm-0">
            <span style="font-weight: 300;" class="text-h4">Incorrect Lanes</span>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-icon color="#00000069" v-on="on" class="ml-2">mdi-help-circle</v-icon>
              </template>
              <span>
                These Order Line Items have been reported by pickers as having been in the wrong lane/s.
                Marking them as correct signifies that there is no longer a discrepancy between AMS and the physical location.
              </span>
            </v-tooltip>

            <v-spacer></v-spacer>

            <v-checkbox
              v-model="options.hideCorrected"
              label="Hide Corrected?"
              class="mr-2"
            ></v-checkbox>
            <v-btn
              icon
              color="primary"
              :loading="loading"
              @click="fetchIncorrectLaneReports"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </div>
        </div>

        <v-divider></v-divider>

        <v-data-table
          :headers="headers"
          :items="incorrectLanes"
          :items-per-page="10"
          :server-items-length="totalCount"
          :options.sync="options"
          item-key="incorrectLaneReportId"
          :loading="loading"
          no-data-text="🥳 You have no incorrect lanes to correct! 🍾"
        >
          <template v-slot:item.orderLineItem.order.amsOrderId="{ item }">
            <router-link :to="NwdLocations.clerk.orderDetail.detail(item.orderLineItem.orderId)">
              {{ item.orderLineItem.order.amsOrderId.toFixed(1) }}
            </router-link>
          </template>

          <template v-slot:item.wasCorrected="{ item }">
            <!-- v-chip of correction type -->
            <v-chip
              v-if="item.wasCorrected"
              :color="item.correctionType === ScanToPickLaneCorrectionTypeEnum.Ams ? 'purple' : (item.correctionType === ScanToPickLaneCorrectionTypeEnum.Physical ? 'brown' : 'success')"
              text-color="white"
              label
              x-small
              class="px-1"
            >
              <span v-if="item.correctionType === ScanToPickLaneCorrectionTypeEnum.Ams">
                <v-icon class="mr-1" x-small>mdi-glass-wine</v-icon>
                AMS
              </span>
              <span v-else-if="item.correctionType === ScanToPickLaneCorrectionTypeEnum.Physical">
                <v-icon class="mr-1" x-small>mdi-warehouse</v-icon>
                Physical
              </span>
              <span v-else><v-icon x-small>mdi-check</v-icon></span>
            </v-chip>
            <span v-else>
              <v-icon color="error">mdi-close</v-icon>
            </span>
          </template>


          <template v-slot:item.dateCorrected="{ item }">
            {{ formatDate(item.dateCorrected) }}
          </template>

          <template v-slot:item.dateAdded="{ item }">
            {{ formatDate(item.dateAdded) }}
          </template>

          <template v-slot:item.dateModified="{ item }">
            {{ formatDate(item.dateModified) }}
          </template>

          <template v-slot:item.actions="{ item }">
            <v-btn
              v-if="!item.wasCorrected"
              color="primary"
              small
              @click="openCorrectDialog(item)"
            >
              <v-icon left>mdi-auto-fix</v-icon>
              Correct
            </v-btn>
          </template>
        </v-data-table>
      </v-sheet>
    </v-container>

    <v-dialog v-model="correctDialogOpen" max-width="350">
      <v-card>
        <v-card-title class="headline">Mark Lane as Corrected</v-card-title>
        <v-card-text>
          <!-- radio buttons picking between the two correction types -->
          <v-radio-group v-model="correctionType" row>
            <v-radio class="mb-3" label="AMS Correction" :value="ScanToPickLaneCorrectionTypeEnum.Ams"></v-radio>
            <v-radio label="Physical Correction" :value="ScanToPickLaneCorrectionTypeEnum.Physical"></v-radio>
          </v-radio-group>

          <!-- info about item -->
          <v-divider class="my-2"></v-divider>
          <v-list v-if="currentReport" rounded>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Order #</v-list-item-title>
                <v-list-item-subtitle>{{ currentReport.orderLineItem.order.amsOrderId.toFixed(1) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Product SKU</v-list-item-title>
                <v-list-item-subtitle>{{ currentReport.orderLineItem.partNo }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Expected Lane</v-list-item-title>
                <v-list-item-subtitle>{{ currentReport.originalLane }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Found In</v-list-item-title>
                <v-list-item-subtitle>{{ currentReport.correctedLane }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Reporter</v-list-item-title>
                <v-list-item-subtitle>{{ currentReport.systemUser.name }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>Date Reported</v-list-item-title>
                <v-list-item-subtitle>{{ formatDate(currentReport.dateAdded) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="mr-4"
            @click="correctDialogOpen = false"
          >Cancel</v-btn>

          <v-btn
            color="primary"
            :disabled="correctionType == null"
            @click="markAsCorrected(currentReport)"
          >
            Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </fragment>
</template>

<script>

import NwdLocations from '@/router/nwd-router';
import { AmsOrderLineItemsClient, MarkLaneCorrectedInput, ScanToPickLaneCorrectionTypeEnum } from '@/api/api'; // Adjust the import path as needed
import moment from 'moment';

export default {
  data() {
    return {
      ScanToPickLaneCorrectionTypeEnum,
      NwdLocations,

      options: {
        itemsPerPage: 10,
        page: 1,
        sortBy: ['dateAdded'],
        sortDesc: [true],
        hideCorrected: true,
      },
      totalCount: 0,
      loading: false,
      headers: [
        { text: 'Ams Order #', value: 'orderLineItem.order.amsOrderId', sortable: false },
        { text: 'Product SKU', value: 'orderLineItem.partNo', sortable: false },
        { text: 'Expected Lane', value: 'originalLane' },
        { text: 'Found In', value: 'correctedLane' },
        { text: 'Reporter', value: 'systemUser.name', sortable: false },
        { text: 'Date Reported', value: 'dateAdded' },
        { text: 'Corrected?', value: 'wasCorrected' },
        { text: 'Date Corrected', value: 'dateCorrected' },
        { text: 'Corrected By', value: 'correctedByUser.name', sortable: false },
        { text: 'Storage Customer', value: 'orderLineItem.order.wineryName', sortable: false },
        { text: '', value: 'actions', sortable: false },
      ],
      incorrectLanes: [],
      amsOrderLineItemsClient: new AmsOrderLineItemsClient(),

      currentReport: null,
      correctDialogOpen: false,
      /* ScanToPickLaneCorrectionTypeEnum */
      correctionType: null,
    };
  },
  mounted() {
    this.fetchIncorrectLaneReports();
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.fetchIncorrectLaneReports().then((newLanes) => {
          this.incorrectLanes = newLanes.results || [];
          this.totalCount = newLanes.resultCount;
        });
      },
    },
  },

  methods: {
    formatDate(date) {
      if (date == null || date === '') return '';
      return moment(date).format('MM/DD/YYYY h:mm A');
    },

    async fetchIncorrectLaneReports() {
      this.loading = true;

      try {
        const formattedSorts = this.options.sortBy.map((sort, i) => {
          return `${sort}|${this.options.sortDesc[i] ? 'desc' : 'asc'}`;
        });

        const response = await this.amsOrderLineItemsClient.getIncorrectLaneReports(
          this.options.hideCorrected,
          this.options.itemsPerPage,
          this.options.page - 1,
          formattedSorts
        );

        return response;

      } catch (error) {
        console.error('Error fetching incorrect lane reports:', error);
      } finally {
        this.loading = false;
      }
    },

    openCorrectDialog(report) {
      this.currentReport = report;
      this.correctDialogOpen = true;
    },

    async markAsCorrected(item) {
      try {
        await this.amsOrderLineItemsClient.markLaneCorrected(item.incorrectLaneReportId, new MarkLaneCorrectedInput({
          laneCorrectionType: this.correctionType,
        }));

        // trigger a re-fetch of the incorrect lanes
        this.fetchIncorrectLaneReports().then((newLanes) => {
          this.incorrectLanes = newLanes.results || [];
          this.totalCount = newLanes.resultCount;
        });

        this.correctDialogOpen = false;
        this.correctionType = null;

      } catch (error) {
        console.error('Error marking lane as corrected:', error);
      }
    },
  },
};
</script>

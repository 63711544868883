
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
//import BarcodeDetector from './barcode.d.ts';

@Component
export default class BarcodeScanner extends Vue {

  $refs!: Vue['$refs'] & {
    scannerVideo: HTMLVideoElement;
  };

  @Prop({ default: false }) readonly enabled!: boolean;

  cameraStream: MediaStream | null = null;
  detectionInterval: any = null;

  torchEnabled: boolean = false;
  torchAvailable: boolean = false;

  detectBarcodeRateMs: number = 250;
  zoomStepRateMs: number = 10;

  zoomAvailable: boolean = true;
  zoomMin: number = 1;
  zoomMax: number = 5;
  zoomStep: number = 0.5;

  readonly ZOOM_STORE_KEY = 'barcode-scanner-camera-zoom';

  zoomLevel: number = 1;
  initialPinchDistance: number = 0;

  viewportHeight = 420; //nice

  mounted() {
    const storedZoom = window.localStorage.getItem(this.ZOOM_STORE_KEY);
    if (storedZoom) {
      this.zoomLevel = parseFloat(storedZoom) ?? this.zoomMin ?? 1;
    }
    this.onEnabledChange();
  }

  beforeDestroy() {
    this.closeScanner();
  }

  initScanner() {
    navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: 'environment',
        width: { ideal: 1500 },
        height: { ideal: 1500 },
        aspectRatio: { ideal: 1 }
      }
    }).then((mediaStream) => {
      this.cameraStream = mediaStream;
      this.$refs.scannerVideo.srcObject = this.cameraStream;
      this.$refs.scannerVideo.onloadedmetadata = () => {
        this.$refs.scannerVideo.play();
      }

      // loop through tracks and find min/max zoom
      this.cameraStream.getVideoTracks().forEach((track) => {
        const capabilities = track.getCapabilities();
        if (capabilities.zoom) {
          this.zoomMin = capabilities.zoom.min;
          this.zoomMax = capabilities.zoom.max;
          this.zoomStep = capabilities.zoom.step;
          this.applyZoom();
        }
      });

      this.torchAvailable = this.cameraStream.getVideoTracks().some((track) => track.getCapabilities().torch);
      this.zoomAvailable = this.cameraStream.getVideoTracks().some((track) => track.getCapabilities().zoom);

      // eslint-disable-next-line no-undef
      const barcodeDetector = new BarcodeDetector({ formats: ['code_128']});

      this.$refs.scannerVideo.oncanplay = () => {
        this.viewportHeight = this.$refs.scannerVideo.videoHeight / this.$refs.scannerVideo.videoWidth * this.$refs.scannerVideo.clientWidth;
        this.detectionInterval = setInterval(() => {
          barcodeDetector.detect(this.$refs.scannerVideo).then((barcodes) => {
            if (barcodes.length > 0) {
              const barcode = barcodes[0];
              console.log(barcode);
              this.validateCode(barcode.rawValue);
            }
          }).catch((err) => {
            this.emitFailedToLoad(err);
          });
        }, this.detectBarcodeRateMs);
      }
    }
    ).catch((err) => {
      this.emitFailedToLoad(err);
    });
  }

  closeScanner() {
    this.cameraStream?.getTracks().forEach((track) => track.stop());
    clearInterval(this.detectionInterval);
  }

  zoomInterval: any = null;
  startZoomInterval(delta: number) {
    this.stopZoomInterval();
    this.updateZoom(delta);

    this.zoomInterval = setInterval(() => {
      this.updateZoom(delta);
    }, this.zoomStepRateMs);
  }

  stopZoomInterval() {
    clearInterval(this.zoomInterval);
  }

  updateZoom(delta: number) {
    this.zoomLevel = Math.min(Math.max(this.zoomLevel + delta, this.zoomMin), this.zoomMax);
    window.localStorage.setItem(this.ZOOM_STORE_KEY, this.zoomLevel.toString());
    this.applyZoom();
  }

  applyZoom() {
    if (!this.zoomAvailable) {
      return;
    }

    this.cameraStream?.getVideoTracks().forEach((track) => {
      track.applyConstraints({
        advanced: [{ zoom: this.zoomLevel }]
      });
    });
  }

  toggleTorch() {
    this.torchEnabled = !this.torchEnabled;
    this.cameraStream?.getVideoTracks().forEach((track) => {
      track.applyConstraints({
        advanced: [{ torch: this.torchEnabled }]
      });
    });
  }

  @Watch('enabled', { immediate: false })
  onEnabledChange() {
    if (this.enabled) {
      if ('BarcodeDetector' in window) {
        this.initScanner();
      } else {
        this.emitFailedToLoad('BarcodeDetector not supported in this browser.');
      }
    } else {
      this.closeScanner();
    }
  }

  @Emit('partNoDetected')
  emitPartNoDetected(partNo: string) {
    return partNo;
  }

  @Emit('amsOrderNoDetected')
  emitAMSOrderDetected(OrderNo: string) {
    return OrderNo;
  }

  @Emit('failedToLoad')
  emitFailedToLoad(error: any) {
    console.error(error);
    return error;
  }

  validateCode(code: string) {
    // the scanned will be wrong on occasion (containing garbage symbols), so we validate it to ensure we aren't annoying the user
    if (code.toUpperCase().match(/^[0-9A-Z\s\-.]+$/)) {
      // If it contains only numbers and a decimal, it's an AMS order number
      if (code.match(/^[0-9]+.[0-9]+$/)) {
        this.emitAMSOrderDetected(code);
      }
      else if (/**code.match(/[A-Z]/) && **/code.match(/[0-9]/)) {
        this.emitPartNoDetected(code);
      }
    }
  }
}

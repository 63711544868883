import {
    AmsSyncResultViewModel
  } from '@/api/api';
  import { getDispatch } from '@/store/get-dispatch';
  import { NwdRootState } from '@/store/nwd-web-root-state';
  import { DispatchAction } from '@/store/utility/dispatch-action';
  import { TypedModule } from '@/store/utility/module-utility-types';
  import { Module } from 'vuex';
  import { namespace } from 'vuex-class';
  
  export const enum AdminState {
    results = 'results',
  }
  
  interface AdminStateTypes {
    [AdminState.results]: AmsSyncResultViewModel[];
  }
  
  export const enum AdminMutation {
    setResults = 'setResults'
  }
  
  interface AdminMutationPayloads {
    [AdminMutation.setResults]: AmsSyncResultViewModel[];
  }
  
  export const enum AdminAction {
    loadResults = 'loadResults'
  }
  
  interface AdminActions {
    [AdminAction.loadResults]: () => void;
  }
  
  export const enum AdminGetter {
    results = 'results',
  }
  
  interface AdminGetterTypes {
    [AdminGetter.results]: AmsSyncResultViewModel[];
  }
  
  export const ADMIN_NAMESPACE = 'AdminState';
  export const adminNamespace = namespace(ADMIN_NAMESPACE);
  export const dispatchAdminAction: DispatchAction<AdminActions> =
    getDispatch(ADMIN_NAMESPACE);
  
  export type AdminModuleType = Override<
    Module<AdminStateTypes, NwdRootState>,
    TypedModule<
      AdminStateTypes,
      AdminMutationPayloads,
      AdminActions,
      AdminGetterTypes,
      NwdRootState
    >
  >;
import {
  AlertMutation,
  AlertAction,
  AlertModule,
  AlertState,
  SnackbarMessage
} from './alert.module-types';

const defaultAlertMessage = {
  message: null,
  color: null,
  timeout: 3000,
  canBeClosed: true
};

export const alertModule: AlertModule = {
  namespaced: true,
  state: {
    snackbarMessage: defaultAlertMessage,
    spinnerStack: 0,
    snackbarHideTimeout: null
  },
  mutations: {
    showSnackbar: (state, payload) => {
      clearTimeout(state[AlertState.snackbarHideTimeout] as number);
      state[AlertState.snackbarMessage] = payload;
    },
    closeSnackbar: (state) => {
      // wait for the animation to finish before hiding the snackbar, otherwise we can see the snackbar reset to the default message
      state[AlertState.snackbarHideTimeout] = setTimeout(() => {
        state[AlertState.snackbarMessage] = defaultAlertMessage;
      }, 200);
    },
    [AlertMutation.addToSpinnerStack]: (state, input) => {
      state.spinnerStack += input;
    }
  },
  actions: {
    [AlertAction.showSnackbar]: ({ commit }, payload) => {
      commit(AlertMutation.showSnackbar, payload);
    },
    [AlertAction.closeSnackbar]: ({ commit }) => {
      commit(AlertMutation.closeSnackbar);
    },
    [AlertAction.pushSpinnerStack]: ({ commit }) => {
      commit(AlertMutation.addToSpinnerStack, 1);
    },
    [AlertAction.popSpinnerStack]: ({ commit }) => {
      commit(AlertMutation.addToSpinnerStack, -1);
    },
    [AlertAction.showSuccess]({ dispatch }, message) {
      const snackbarMessage: SnackbarMessage = {
        message,
        color: 'success'
      };
      dispatch(AlertAction.showSnackbar, snackbarMessage);
    },
    [AlertAction.showError]({ dispatch }, message) {
      const snackbarMessage: SnackbarMessage = {
        message,
        color: 'error'
      };
      dispatch(AlertAction.showSnackbar, snackbarMessage);
    },
    [AlertAction.showWarning]({ dispatch }, message) {
      const snackbarMessage: SnackbarMessage = {
        message,
        color: 'warning'
      };
      dispatch(AlertAction.showSnackbar, snackbarMessage);
    },
    [AlertAction.showInfo]({ dispatch }, message) {
      const snackbarMessage: SnackbarMessage = {
        message,
        color: 'info'
      };
      dispatch(AlertAction.showSnackbar, snackbarMessage);
    }
  },
  getters: {
    message: (state) => state[AlertState.snackbarMessage]?.message,
    timeout: (state) => state[AlertState.snackbarMessage]?.timeout ?? 3000,
    color: (state) => state[AlertState.snackbarMessage]?.color,
    canBeClosed: (state) =>
      state[AlertState.snackbarMessage]?.timeout === undefined
        ? true
        : state[AlertState.snackbarMessage]?.canBeClosed,
    showSpinner: (state) => state.spinnerStack > 0
  }
};

import { render, staticRenderFns } from "./PickerList.vue?vue&type=template&id=b228ff88&scoped=true&"
import script from "./PickerList.ts?vue&type=script&lang=ts&"
export * from "./PickerList.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b228ff88",
  null
  
)

export default component.exports
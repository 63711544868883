import { IPickerListOutput, PickerListOutput, ScanToPickUserStatusEnum } from '@/api/api';
import { timeFilter } from '@/filters/date.filter';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Provide, Watch } from 'vue-property-decorator';
import {
  ClerkAction,
  clerkNamespace,
  ClerkState,
  dispatchClerkAction,
  PickersGet
} from '../../_state/clerk-module-type';

// Components
import PickerListFilter from './PickerListFilter.vue';
import PickerCard from './PickerCard.vue';

@Component({
  name: 'PickerList',
  components: {
    PickerCard,
    PickerListFilter
  },
  filters: {
    timeFilter
  }
})
export default class PickerList extends Vue {
  @Prop({
    type: Boolean,
    default: false
  })
  showOrders!: boolean;
  @Prop({
    type: Boolean,
    default: false
  })
  isFilterDisplaying!: boolean;

  @clerkNamespace.State(ClerkState.pickers)
  pickers!: PickerListOutput[];

  get npickers(): (IPickerListOutput & { showOrders: boolean; timeFilter: string })[] {
    return this.pickers.map((p) => ({
      ...p,
      showOrders: p.statusCode !== ScanToPickUserStatusEnum.Offline,
      // will show the start time if the picker is online/paused, otherwise will show the end time
      timeFilter: timeFilter(p.lastClockedTime?.endTime ? p.lastClockedTime?.endTime : p.lastClockedTime?.startTime),
      isLazy: false
    }));
  }

  loading = false;
  options: PickersGet = {
    pickerStatus: ScanToPickUserStatusEnum.All,
    searchText: '',
    pageNumber: 0,
    pageSize: -1,
    sortCriteria: ['statusCode|asc']
  };

  async mounted(): Promise<void> {
    this.options.pickerStatus = ScanToPickUserStatusEnum.All;
    this.onOptionsChanges(this.options);
  }

  minHeightPickerCard(picker: IPickerListOutput): number {
    const times = picker.ordersCount >= 10 ? 10 : picker.ordersCount;
    const res = 126.67 + 48 + 48 * times;
    return res;
  }

  @Watch('options')
  async onOptionsChanges(input: PickersGet): Promise<void> {
    try {
      this.loading = true;
      await dispatchClerkAction(ClerkAction.getPickers, {
        input: input
      });
    } finally {
      this.loading = false;
    }
  }

  @Provide('updatePickersGet')
  private updatePickersGet(updates: Partial<PickersGet>) {
    this.options = {
      ...this.options,
      ...updates
    };
  }
}

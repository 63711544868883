
import NwdLocations from '@/router/nwd-router';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({})
export default class Admin extends Vue {
  public readonly NwdLocations = NwdLocations;

  public activeRoute: string = '';

  @Watch('$route', { immediate: true })
  public onRouteChanged(): void {
    this.activeRoute = this.$route.name || '';
  }
}

import {
  IOrderListOutput,
  OrderListOutput,
  AmsOrdersClient,
  PickerListOutput,
  PickersClient,
  AmsOrderStatusEnum
} from '@/api/api';
import eventBus from '@/eventBus';
import NwdLocations from '@/router/nwd-router';
import { STATUS_PROGRESS_ORDER_STRING } from '@/shared/constants/common';
import { getFormatStringHour } from '@/utilities/datetime-converter';
import moment from 'moment-timezone';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class PickerOrders extends Vue {
  headers = [
    { text: '', value: 'prepend', sortable: false },
    { text: 'AMS Order #', value: 'amsOrderId', sortable: false },
    { text: 'Status', value: 'orderStatusCode', sortable: false },
    { text: '# Cases Picked', value: 'casesPicked', sortable: false },
    { text: '# Cases', value: 'numberOfCases', sortable: false },
    { text: '', value: 'actions', sortable: false }
  ];
  options: {
    itemsPerPage?: number;
    page?: number;
    sortBy?: string[];
    sortDesc?: boolean[];
  } = {};
  orders: OrderListOutput[] = [];
  orderCount = 0;
  loading = false;
  client = new PickersClient();
  ordersClient = new AmsOrdersClient();

  @Prop()
  readonly picker!: PickerListOutput;

  @Watch('picker')
  pickerChanges(newPicker: PickerListOutput, oldPicker: PickerListOutput): void {
    if (newPicker.pickerId === oldPicker.pickerId) {
      return;
    }
    this.options = {
      ...this.options,
      itemsPerPage: 10,
      page: 1
    };
  }

  @Watch('options')
  private async optionsChanges({
    itemsPerPage,
    page
  }: {
    itemsPerPage?: number;
    page?: number;
    sortBy?: string[];
    sortDesc?: boolean[];
  }) {
    try {
      this.loading = true;
      const res = await this.client.getPickerOrders(
        this.picker.userId,
        itemsPerPage !== -1 ? itemsPerPage : this.orderCount,
        (page ?? 0) - 1,
        new Date(new Date().getTime() - 2 * (24 * 60 * 60 * 1000)), // Current time minus two days
        null,
        ['completed|asc', 'priority|asc', 'DateCompleted|desc']
      );

      // patch to disable the move up button for the first item
      if (res.results && res.results.length > 0) {
        res.results[0].canMoveUp = false;

        // patch to disable the move down button for the last item
        // we traverse backwards to find the first item that is a candidate for moving down
        for (let i = res.results.length - 1; i >= 0; i--) {
          if (
            res.results[i].orderStatusCode === AmsOrderStatusEnum.NotStarted ||
            res.results[i].orderStatusCode === AmsOrderStatusEnum.Paused
          ) {
            res.results[i].canMoveDown = false;
            break;
          }
        }
      }

      this.orders = res.results ?? [];
      this.orderCount = res.resultCount;
    } finally {
      this.loading = false;
    }
  }

  created(): void {
    eventBus.$on('orderAssigned', this.reloadOrders);
    eventBus.$on('reloadPickerOrders', this.forceReloadOrders);
  }

  async priorityUp(order: IOrderListOutput & { loadingUp?: boolean }): Promise<void> {
    try {
      order.loadingUp = true;
      await this.ordersClient.priorityOrderUp(order.orderId);
      await this.optionsChanges(this.options);
    } finally {
      order.loadingUp = false;
    }
  }

  async priorityDown(order: IOrderListOutput & { loadingDown?: boolean }): Promise<void> {
    try {
      order.loadingDown = true;
      await this.ordersClient.priorityOrderDown(order.orderId);
      await this.optionsChanges(this.options);
    } finally {
      order.loadingDown = false;
    }
  }

  getStatusClass(item: IOrderListOutput): string {
    if (item.orderStatusCode === AmsOrderStatusEnum.NotStarted) {
      return 'not-start-status';
    } else if (item.orderStatusCode === AmsOrderStatusEnum.InProgress) {
      return 'in-progress-status';
    } else if (item.orderStatusCode === AmsOrderStatusEnum.Paused) {
      return 'paused-status';
    } else if (item.orderStatusCode === AmsOrderStatusEnum.Completed) {
      return 'complete-status';
    } else {
      return 'returned-status';
    }
  }

  public itemRowBackground(item: OrderListOutput): string {
    switch (item.orderStatusCode) {
      case AmsOrderStatusEnum.InProgress:
        return 'in-progress-class';
      case AmsOrderStatusEnum.Paused:
        return 'bar-paused-status';
      case AmsOrderStatusEnum.Completed:
        return 'bar-completed-status';
      default:
        return '';
    }
  }

  public itemRowProgressBar(item: OrderListOutput): string {
    if (item.orderStatusCode === AmsOrderStatusEnum.InProgress) {
      const progressPercent = (item.casesPicked / item.numberOfCases) * 100;
      const colorPrimary = 'var(--color-progress-bar-in-progress-primary)';
      const colorSecondary = 'var(--color-progress-bar-in-progress-secondary)';
      return `background-image: linear-gradient(to right,${colorPrimary} ${progressPercent}%, ${colorSecondary} ${progressPercent}%)`;
    }
    return '';
  }

  getProgressStatusText(item: IOrderListOutput): string {
    item;
    return `${STATUS_PROGRESS_ORDER_STRING[item.orderStatusCode]} ${
      item.orderStatusCode != AmsOrderStatusEnum.NotStarted
        ? `(${getFormatStringHour(item.progressDate!, moment.tz.guess())})`
        : ''
    }`;
  }

  isSortingDisabled(item: IOrderListOutput): boolean {
    return item.orderStatusCode === AmsOrderStatusEnum.Completed;
  }

  navigateToDetails(item: OrderListOutput): void {
    const location = NwdLocations.clerk.orderDetail.detail(item.orderId);
    this.$router.push(location);
  }

  private isElementInViewport(el: Element): boolean {
    const rect = el.getBoundingClientRect();
    return (
      rect.bottom >= 0 &&
      rect.right >= 0 &&
      rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.left <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }

  private async forceReloadOrders(): Promise<void> {
    const isVisible = this.isElementInViewport(this.$el);
    if (!isVisible) {
      console.log(`Component not visible. Picker ID: ${this.picker.pickerId}. FORCE RELOAD`);
    }
    await this.optionsChanges(this.options);
  }

  private async reloadOrders({ pickerId }: { pickerId: number }): Promise<void> {
    if (pickerId !== this.picker.userId) {
      return;
    }

    const isVisible = this.isElementInViewport(this.$el);
    if (!isVisible) {
      console.log(`Component not visible. Picker ID: ${this.picker.pickerId}. RELOAD`);
    }

    await this.optionsChanges(this.options);
  }

  destroyed(): void {
    eventBus.$off('orderAssigned', this.reloadOrders);
  }
}

import {
  AmsOrderLineItemModel,
  AuthResultViewModel,
  OrderItemViewModel,
  OrderListOutput,
  AmsOrderStatusEnum
} from '@/api/api';
import NwdLocations from '@/router/nwd-router';
import { AuthGetter, AuthNamespace } from '@/store/auth/auth.module.types';
import { dispatchOrderAction } from '@/store/order/order-dispatch';
import { OrderAction, OrderNamespace, OrderState, PickerOrdersGet } from '@/store/order/order-module-types';
import { getFormatStringDate, getFormatStringHour } from '@/utilities/datetime-converter';
import moment from 'moment-timezone';
import Vue from 'vue';
import Component from 'vue-class-component';
import { getTopPriorityOrder } from '../picker-utils';
import eventBus from '@/eventBus';
import { Watch } from 'vue-property-decorator';
import { STATUS_PROGRESS_ORDER_STRING } from '@/shared/constants/common';

@Component({
  name: 'PickerOrderList'
})
export default class PickerOrderList extends Vue {
  public readonly AmsOrderStatusEnum = AmsOrderStatusEnum;

  get headers() {
    const tHeaders: any[] = [
      { text: 'AMS Order #', value: 'amsOrderId' },
      { text: 'Storage Customer', value: 'wineryName' },
      { text: '# Cases', value: 'numberOfCases' },
      { text: '# Line Items', value: 'lineItemCount' }
    ];

    if (this.$vuetify.breakpoint.smAndUp) {
      tHeaders.push({ value: 'action' });
    }

    return tHeaders;
  }

  localtz = '';
  timerDelayId = 0;

  @AuthNamespace.Getter(AuthGetter.credential)
  readonly credential!: AuthResultViewModel;

  @OrderNamespace.State(OrderState.ordersPicker)
  readonly ordersPicker!: OrderListOutput[];

  @OrderNamespace.State(OrderState.ordersPickerCount)
  readonly ordersPickerCount!: number[];

  @OrderNamespace.State(OrderState.loading)
  readonly loading!: boolean;

  @OrderNamespace.State(OrderState.pickerOrdersGet)
  readonly pickerOrdersGet!: PickerOrdersGet;

  isPauseDialogVisible = false;
  orderSelected = {} as OrderListOutput;
  footerProps = {
    itemsPerPageOptions: [5, 10, 20, 30, -1],
    itemsPerPageText: 'Per page:'
  };

  // get ordersPickerFiltered(): PickerOrderViewModel[] {
  //   return this.ordersPicker.filter(
  //     (o) =>
  //       o.status === AmsOrderStatusEnum.InProgress ||
  //       o.status === AmsOrderStatusEnum.NotStarted ||
  //       o.status === AmsOrderStatusEnum.Paused
  //   );
  // }

  public async mounted(): Promise<void> {
    this.localtz = moment.tz.guess();
    dispatchOrderAction(OrderAction.setPickerId, this.credential?.userId || 0);
    dispatchOrderAction(OrderAction.loadOrdersPicker);
  }

  async updateFilter(options: Partial<PickerOrdersGet>): Promise<void> {
    if (
      options.itemsPerPage === this.pickerOrdersGet.itemsPerPage &&
      options.page === this.pickerOrdersGet.page &&
      options.sortBy === this.pickerOrdersGet.sortBy &&
      options.sortDesc === this.pickerOrdersGet.sortDesc
    ) {
      return;
    }
    await dispatchOrderAction(OrderAction.updatePickerOrdersGet, {
      updates: options
    });
  }
  public getStatusString(statusCode: number): string {
    return STATUS_PROGRESS_ORDER_STRING[statusCode];
  }

  public getDateTimeString(datetime: string): string {
    if (!datetime) return '- -';
    const dateString = getFormatStringDate(new Date(datetime), this.localtz);
    const timeString = getFormatStringHour(new Date(datetime), this.localtz);

    return `${timeString} ${dateString}`;
  }

  public getIconByStatus(statusCode: number): string {
    switch (statusCode) {
      case AmsOrderStatusEnum.AwaitingQc:
        return ' mdi-timer-sand';
      case AmsOrderStatusEnum.InProgress:
        return 'mdi-check-circle-outline';
      case AmsOrderStatusEnum.Returned:
        return 'mdi-close-circle-outline';
      case AmsOrderStatusEnum.Completed:
      default:
        return ' mdi-check';
    }
  }

  public getClassByStatus(statusCode: number): string {
    switch (statusCode) {
      case AmsOrderStatusEnum.Completed:
        return 'complete-status';
      case AmsOrderStatusEnum.InProgress:
        return 'in-progress-status';
      case AmsOrderStatusEnum.Returned:
        return 'returned-status';
      case AmsOrderStatusEnum.AwaitingQc:
      default:
        return '';
    }
  }

  public getItemClasses(item: OrderListOutput) {
    return [this.getClassByStatus(item.orderStatusCode), this.getClassByPickerPriority(item)];
  }

  public isTopPriorityOrder(order: OrderListOutput): boolean {
    const topPriorityOrder = getTopPriorityOrder(this.ordersPicker);
    return !!topPriorityOrder && order.orderId === topPriorityOrder.orderId;
  }

  public getClassByPickerPriority(order: OrderListOutput): string {
    if (this.isTopPriorityOrder(order)) {
      return 'in-progress-class';
    } else {
      return '';
    }
  }

  public getColorClassByStatus(statusCode: number): string {
    let raw = '';
    switch (statusCode) {
      case AmsOrderStatusEnum.Completed:
        raw = 'var(--color-progress-status-complete)';
        break;
      case AmsOrderStatusEnum.InProgress:
        raw = 'var(--color-progress-status-in-progress)';
        break;
      case AmsOrderStatusEnum.Returned:
        raw = 'var(--color-progress-status-returned)';
        break;
      case AmsOrderStatusEnum.AwaitingQc:
      default:
        return '';
    }

    raw = `color: ${raw};`;
    return raw;
  }

  public navigateToPickOrderDetail(item: OrderListOutput): void {
    if (item.orderStatusCode === AmsOrderStatusEnum.Paused) {
      this.orderSelected = item;
      this.isPauseDialogVisible = true;
      return;
    }
    const location = NwdLocations.picker.orderDetail.orderLineItems(item.orderId);
    this.$router.push(location);
  }
}

import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import {
  ApplicationUserViewModel,
  AuthResultViewModel,
  UserLoginInput
} from '@/api/api';
import { NwdRootState } from '../nwd-web-root-state';
import { TypedModule } from '../utility/module-utility-types';

export const enum AuthState {
  credential = 'credential',
  userInfo = 'userInfo'
}

export interface AuthStateType {
  [AuthState.credential]: AuthResultViewModel | null;
  [AuthState.userInfo]: ApplicationUserViewModel | null;
}

export const enum AuthMutation {
  saveCredential = 'saveCredential',
  setUserInfo = 'setUserInfo'
}

interface AuthMutationTypes {
  [AuthMutation.saveCredential]: AuthResultViewModel | null;
  [AuthMutation.setUserInfo]: ApplicationUserViewModel | null;
}

export const enum AuthAction {
  logout = 'logout',
  loginCredential = 'loginCredential',
  logoutCredential = 'logoutCredential',
  getCurrentUserInfo = 'getCurrentUserInfo'
}

export interface AuthActions {
  [AuthAction.loginCredential]: (body: UserLoginInput) => Promise<void>;
  [AuthAction.logoutCredential]: () => Promise<void>;
  [AuthAction.getCurrentUserInfo]: () => Promise<ApplicationUserViewModel | null>;
  [AuthAction.logout]: () => Promise<void>;
}

export const enum AuthGetter {
  credential = 'credential',
  userInfo = 'userInfo'
}

export interface AuthGetterType {
  [AuthGetter.credential]: AuthResultViewModel | null;
  [AuthGetter.userInfo]: ApplicationUserViewModel | null;
}

export const AUTH_STATE_NAMESPACE = 'AuthState';
export const AuthNamespace = namespace(AUTH_STATE_NAMESPACE);

export type AuthModule = Override<
  Module<AuthStateType, NwdRootState>,
  TypedModule<
    AuthStateType,
    AuthMutationTypes,
    AuthActions,
    AuthGetterType,
    NwdRootState
  >
>;

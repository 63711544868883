import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Error404 from '@/views/Error404.vue';
import store from '@/store/index';
import {
  AuthGetter,
  AUTH_STATE_NAMESPACE
} from '@/store/auth/auth.module.types';
import { AuthResultViewModel, SystemPermission } from '@/api/api';
import { CLERK_ROUTES } from '@/router/ClerkRoutes';
import { QC_ROUTES } from '@/router/QcRoutes';
import { PICKER_ROUTES } from '@/router/PickerRoutes';
import { AUTH_ROUTES } from '@/router/AuthRoutes';
import NwdLocations, { GetUserHome } from './nwd-router';
import { ScanToPickPermissions, hasAtLeastOnePermission, testPermission } from '@/utilities/auth/auth.permissions';
import App from '@/App.vue';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';
import { ADMIN_ROUTES } from '@/router/AdminRoutes';

Vue.use(VueRouter);

export type AppRouteConfig = RouteConfig & {
  children?: Array<AppRouteConfig>;
  meta?: {
    requiresAuth?: boolean;
    allowedPermissions?: SystemPermission[];
    hideNavbar?: boolean;
  };
};

const routes: Array<RouteConfig> = [
  ...QC_ROUTES,
  ...CLERK_ROUTES,
  ...PICKER_ROUTES,
  ...AUTH_ROUTES,
  ...ADMIN_ROUTES,
  // we redirect them to the clerk dashboard, if they don't have permission, they will be redirected to their home page
  {
    path: '/',
    redirect: {
      name: NwdLocations.clerk.dashboard.name,
    },
  },
  {
    path: '/home',
    redirect: {
      name: NwdLocations.clerk.dashboard.name,
    },
  },
  {
    path: '*',
    name: 'Page Not Found',
    component: Error404,
    meta: { requiresAuth: true }
  }
];

const router = new VueRouter({
  routes,
  mode: 'history',
  base: '/',
});


router.beforeEach((to, from, next) => {
  if (to.name) {
    document.title = to.name + ' | ScanToPick';
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const autGetter = `${AUTH_STATE_NAMESPACE}/${AuthGetter.credential}`;
    const credential = store.getters[autGetter] as AuthResultViewModel;
    // finding the most specific permissions for the route, we keep looking up the route tree until we find a route with permissions defined
    const routePermissions = [...to.matched].reverse().find(m => m.meta?.allowedPermissions)?.meta?.allowedPermissions || [];
    const userPermissions = credential?.permissions;

    // first we check to see if the user has the permissions to access this app

    if (!userPermissions || !hasAtLeastOnePermission(userPermissions, ScanToPickPermissions)) {
      return next('/login');
    }

    // check if user has permission to access the route, we allow if the route has no permissions defined
    let allowed = routePermissions.length === 0;
    if (!allowed) {
      allowed = hasAtLeastOnePermission(userPermissions, routePermissions);
    }

    // direct them back to their home page if they are trying to access a page they don't have permission to
    if (!allowed) {
      console.log('user does not have permission to access this route')
      return next({
        name: GetUserHome(userPermissions),
        replace: true
      });
    }

    next();
  } else {
    next();
  }
});

router.onError(error => {
  // only want to trigger a reload if the browser is connected to the internet, otherwise all will be lost
  if (navigator.onLine) {
    console.log('router error detected', error)
    // the error that happens if the chunk is an HTML doc instead of JS.
      // note: chunkLoadError is the one that works

    const cssError = (error + '').startsWith('Error: Loading CSS chunk');

    if (/loading chunk \d* failed./i.test(error.message.toLowerCase()) ||
      (error.name.startsWith("ChunkLoadError") && error.message.startsWith('Loading chunk')) || cssError) {

      dispatchAlertAction(AlertAction.showInfo, 'A new version of the app is available, please refresh the page to get the latest version.');
    }

  } else {
    dispatchAlertAction(AlertAction.showError, 'You are offline, please connect to the internet and try again.');
  }
});

export default router;

import { UsersClient, UserLoginInput, ClockedTimeClient, ScanToPickUserStatusEnum } from '@/api/api';
import { LOCAL_STORAGE_CREDENTIAL } from '@/shared/constants/common';
import { dispatchAlertAction } from '../alert/alert.dispatch';
import { AlertAction } from '../alert/alert.module-types';
import { clearLocalCredential, getLocalCredential } from '../utility/getLocalCredential';
import {
  UserAction,
  // UserMutation,
  UserState,
  UserModule,
  UserMutation
  // UserGetter
} from './user.module.types';
import { dispatchUserAction } from './user.dispatch';

export const userModule: UserModule = {
  namespaced: true,
  state: {
    [UserState.currentStatus]: null,
    [UserState.lastClockedTime]: null
  },
  mutations: {
    [UserMutation.setCurrentStatus]: (state, payload) => {
      state[UserState.currentStatus] = payload;
    },
    [UserMutation.setLastClockedTime]: (state, payload) => {
      state[UserState.lastClockedTime] = payload;
    }
  },
  actions: {
    [UserAction.refreshClockedTime]: async ({ commit }) => {
      const client = new ClockedTimeClient();
      const result = await client.getLastClockedTime();

      let currStatus = ScanToPickUserStatusEnum.Online;
      if (!result || result.endTime) currStatus = ScanToPickUserStatusEnum.Offline;
      else if (result.pauseIntervals.find((x) => !x.endTime)) currStatus = ScanToPickUserStatusEnum.Paused;

      commit(UserMutation.setLastClockedTime, result);
      commit(UserMutation.setCurrentStatus, currStatus);

      return result;
    },
    /**
     * A quick way to update the user's status. Call's either startClock, pauseClock, or stopClock based on the status.
     */
    [UserAction.setStatus]: async ({ commit }, status) => {
      const clockedTimeClient = new ClockedTimeClient();

      try {
        if (status === ScanToPickUserStatusEnum.Online) {
          await clockedTimeClient.startClock();
        } else if (status === ScanToPickUserStatusEnum.Offline) {
          await clockedTimeClient.stopClock();
        } else if (status === ScanToPickUserStatusEnum.Paused) {
          await clockedTimeClient.pauseClock();
        }
      } catch (e) {
        dispatchAlertAction(AlertAction.showError, 'Error occurred while updating status! See console for details.');
        console.error(e);
      }

      return await dispatchUserAction(UserAction.refreshClockedTime);
    },
    [UserAction.startClock]: async ({ commit }) => {
      const client = new ClockedTimeClient();
      await client.startClock();
      return await dispatchUserAction(UserAction.refreshClockedTime);
    },
    [UserAction.pauseClock]: async ({ commit }) => {
      const client = new ClockedTimeClient();
      await client.pauseClock();
      return await dispatchUserAction(UserAction.refreshClockedTime);
    },
    [UserAction.stopClock]: async ({ commit }) => {
      const client = new ClockedTimeClient();
      await client.stopClock();
      return await dispatchUserAction(UserAction.refreshClockedTime);
    }
  },
  getters: {
    [UserState.currentStatus]: (state) => state[UserState.currentStatus],
    [UserState.lastClockedTime]: (state) => state[UserState.lastClockedTime]
  }
};

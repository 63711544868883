import { OrderListOutput, AmsOrderStatusEnum } from '@/api/api';

/**
 * Returns the top priority order from the list of orders. Criteria for top priority order is:
 * 1. Order status is InProgress or NotStarted
 * 2. Order 'priority' is the lowest integer (meaning the highest priority)
 * @param orders
 * @returns
 */
export function getTopPriorityOrder(orders: OrderListOutput[]): OrderListOutput | undefined {
  if (orders.length == 0) return;

  orders = orders
    .filter(
      (order) =>
        order.orderStatusCode == AmsOrderStatusEnum.InProgress || order.orderStatusCode == AmsOrderStatusEnum.NotStarted
    )
    .sort((a, b) => {
      // Prioritize orders that are "In Progress"
      if (a.orderStatusCode == AmsOrderStatusEnum.InProgress && b.orderStatusCode != AmsOrderStatusEnum.InProgress) {
        return -1;
      }
      if (b.orderStatusCode == AmsOrderStatusEnum.InProgress && a.orderStatusCode != AmsOrderStatusEnum.InProgress) {
        return 1;
      }

      // If both have the same status, then sort by priority
      return a.priority - b.priority;
    });

  return orders[0];
}

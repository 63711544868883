import {
  AmsOrderLineItemsClient,
  AmsOrdersClient,
  UpdateNumberOfPalletsOrderInput
} from '@/api/api';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';
import { ISection } from '../_components/PickerOrderItemSection';
import {
  PickerAction,
  PickerGetter,
  PickerModuleType,
  PickerMutation,
  PickerState
} from './picker-module-type';
import { isIosSafari } from '@/utilities/utils';

const initialSections: ISection[] = [
  {
    title: 'Lane/s',
    icon: 'mdi-map-marker',
    key: 'laneReservations',
    isConfirm: undefined
  },
  {
    title: 'Storage Customer',
    icon: 'mdi-store',
    key: 'wineryName',
    isConfirm: undefined
  },
  {
    title: 'Varietal',
    icon: 'mdi-bottle-wine',
    key: 'name',
    isConfirm: undefined
  },
  {
    title: 'Vintage',
    icon: 'mdi-calendar-today',
    key: 'yearCode',
    isConfirm: undefined
  },
  {
    title: 'Description',
    icon: 'mdi-reorder-horizontal',
    key: 'description',
    isConfirm: undefined
  },
  {
    title: 'Bottle Size',
    icon: 'mdi-soy-sauce',
    key: 'sizeDescription',
    isConfirm: undefined
  },
  {
    title: 'Configuration',
    icon: 'mdi-tally-mark-5',
    key: 'unitsPerCase',
    isConfirm: undefined
  },
  {
    title: 'Special Instructions',
    icon: 'mdi-exclamation-thick',
    key: 'specialInstructions',
    isConfirm: undefined
  },
  {
    title: 'Cases / Bottles Quantity',
    icon: 'mdi-package-variant-closed',
    key: 'quantity',
    isConfirm: undefined
  }
];

export const pickerModule: PickerModuleType = {
  namespaced: true,
  state: {
    [PickerState.orders]: [],
    [PickerState.order]: undefined,
    [PickerState.orderInvalidAccess]: true,
    [PickerState.orderLineItem]: undefined,
    [PickerState.nextOrderLineItem]: undefined,
    [PickerState.confirmCompleteOrderDialog]: false,
    [PickerState.orderLineItemSections]: initialSections.map((s) => ({ ...s }))
  },
  mutations: {
    [PickerMutation.setOrders]: (state, payload) => {
      state.orders = payload;
    },
    [PickerMutation.setOrder]: (state, payload) => {
      state.order = payload;
    },
    [PickerMutation.setOrderInvalidAccess]: (state, payload) => {
      state.orderInvalidAccess = payload;
    },
    [PickerMutation.setOrderLineItem]: (state, payload) => {
      state.orderLineItem = payload;
    },
    [PickerMutation.setNextOrderLineItem]: (state, payload) => {
      state.nextOrderLineItem = payload;
    },
    [PickerMutation.setConfirmCompleteOrderDialog]: (state, payload) => {
      state.confirmCompleteOrderDialog = payload;
    },
    [PickerMutation.setOrderLineItemSections]: (state, payload) => {
      state.orderLineItemSections = payload;
    }
  },
  actions: {
    [PickerAction.loadOrders]: ({ commit }) => {
      commit(PickerMutation.setOrders, []);
    },
    [PickerAction.loadOrder]: async ({ commit }, payload) => {
      const client = new AmsOrdersClient();
      const order = await client.getOrderByIdAndUserId(payload.orderId);
      if (order == null || order?.id === undefined) {
        commit(PickerMutation.setOrderInvalidAccess, true);
        return order;
      }

      commit(PickerMutation.setOrderInvalidAccess, false);
      commit(PickerMutation.setOrder, order);
      return order;
    },
    [PickerAction.getOrderLineItem]: async ({ commit }, payload) => {
      const client = new AmsOrderLineItemsClient();
      const orderLineItem = await client.getOrderLineItem(payload.orderLineItemId);
      commit(PickerMutation.setOrderLineItem, orderLineItem);

      const sections = [...initialSections];
      // these object references are being used verbatim in the Sections, very important to not mutate them
      sections.forEach((s) => {
        s.isConfirm = undefined;
      });

      // duplicate laneReservation sections and input the appropriate values
      const laneReservationsIndex = sections.findIndex((s) => s.key === 'laneReservations');
      const dupedSections =
        orderLineItem.laneReservations?.map(
          (lr, i) =>
            ({
              key: 'laneReservations',
              title: 'Lane #' + (i + 1),
              icon: 'mdi-map-marker',
              laneIndex: i,
              isConfirm: undefined
            } as ISection)
        ) ?? [];

      if (dupedSections.length === 0) {
        dupedSections.push({
          key: 'laneReservations',
          title: 'Lane #1',
          icon: 'mdi-map-marker',
          laneIndex: 0,
          isConfirm: undefined
        });
      }

      // remove the laneReservations section and replace it with the duplicated sections
      sections.splice(laneReservationsIndex, 1, ...dupedSections.flat());
      commit(PickerMutation.setOrderLineItemSections, sections);

      if (process.env.NODE_ENV === 'development') {
        sections.forEach((s) => {
          s.isConfirm = true;
        });
      }

      return orderLineItem;
    },
    [PickerAction.getNextOrderLineItem]: async ({ commit }, payload) => {
      const client = new AmsOrderLineItemsClient();
      const res = await client.getNextOrderLineItem(
        payload.orderId,
        payload.seq
      );
      commit(PickerMutation.setNextOrderLineItem, res);
      return res;
    },
    [PickerAction.showConfirmCompleteOrderDialog]: ({ commit }) => {
      commit(PickerMutation.setConfirmCompleteOrderDialog, true);
    },
    [PickerAction.hideConfirmCompleteOrderDialog]: ({ commit }) => {
      commit(PickerMutation.setConfirmCompleteOrderDialog, false);
    },
    [PickerAction.pickOrderLineItem]: async ({ dispatch, state }, payload) => {
      const client = new AmsOrderLineItemsClient();
      const res = await client.pick(payload.orderLineItemId);
      await dispatch(PickerAction.loadOrder, {
        orderId: state.order!.id!
      });
      dispatchAlertAction(AlertAction.showSuccess, 'Item picked.');
      return res;
    },
    // eslint-disable-next-line no-empty-pattern
    [PickerAction.pickOrderLineItemAndFinalize]: async ({}, payload) => {
      const client = new AmsOrderLineItemsClient();
      const res = await client.pickAndFinalizeOrder(payload.orderLineItemId);
      dispatchAlertAction(AlertAction.showSuccess, 'Item picked.');
      return res;
    },
    // eslint-disable-next-line no-empty-pattern
    [PickerAction.updateOrderNumberOfPallets]: async ({}, payload) => {
      const client = new AmsOrdersClient();
      const input = new UpdateNumberOfPalletsOrderInput({
        numberOfPallets: payload.numberOfPallets
      });
      await client.updateNumberOfPalletsOrder(payload.orderId, input);
    },

    // eslint-disable-next-line no-empty-pattern
    [PickerAction.printPalletTag]: async ({ }, { orderId }) => {

      const baseUrl = `${process.env.VUE_APP_API_BASE_URL}`;
      const url = `${baseUrl}/api/Reports/PalletTagReport/pdf?orderId=${orderId}`;

      // check if mobile safari, if so replace the window with the url
      if (isIosSafari()) {
        window.location.href = url;
      } else {
        window.open(url, '_blank');
      }
    }
  },
  getters: {
    [PickerGetter.order]: (state) => state.order,
    [PickerGetter.orderInvalidAccess]: (state) => state.orderInvalidAccess,
    [PickerGetter.orderLineItem]: (state) => state.orderLineItem,
    [PickerGetter.nextOrderLineItem]: (state) => state.nextOrderLineItem,
    [PickerGetter.confirmCompleteOrderDialog]: (state) =>
      state.confirmCompleteOrderDialog
  }
};

import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';

import QuaggaWrapper from './QuaggaWrapper.vue';
import BarcodeScanner from './BarcodeScanner.vue';
import {
  AmsOrderModel,
  AmsOrderLineItemModel,
  AuthResultViewModel,
  AmsOrderStatusEnum,
  PickerOrderViewModel,
  PickersClient,
  SkuScanReportViewModel,
  SkuScanStatusEnum,
  OrderScanReportViewModel,
  OrderScanStatusEnum,
  AmsOrdersClient
} from '@/api/api';
import { AuthGetter, AuthNamespace } from '@/store/auth/auth.module.types';
import { orderStatusString } from '@/filters/order-status-filter';
import NwdLocations from '@/router/nwd-router';

import XExpandContainer from '@/components/_generics/x-expand-container.vue';
import SkuScanReportInfoCard from './SkuScanReportInfoCard.vue';
import OrderScanReportInfoCard from './OrderScanReportInfoCard.vue';

@Component({
  components: {
    QuaggaWrapper,
    BarcodeScanner,
    XExpandContainer,
    SkuScanReportInfoCard,
    OrderScanReportInfoCard
  },
  filters: {
    orderStatusString
  }
})
export default class SkuScannerDialog extends Vue {
  public readonly AmsOrderStatusEnum = AmsOrderStatusEnum;
  public readonly SkuScanStatusEnum = SkuScanStatusEnum;
  public readonly OrderScanStatusEnum = OrderScanStatusEnum;
  public readonly Math = Math;

  @Prop({ default: false }) readonly isQC!: boolean;
  @Prop({ default: false }) readonly value!: boolean;

  @Watch('value', { immediate: true })
  onValueChanged(): void {
    this.visible = this.value;
  }

  @AuthNamespace.Getter(AuthGetter.credential)
  readonly credential!: AuthResultViewModel;

  @Emit('input')
  emitInput(newValue: boolean) {
    return newValue;
  }

  isFormValid = false;
  loadingNextLineItems = false;
  visible = false;
  activelyScanning = false;
  loadingSkuScanReport = false;
  showScanResultOverlay = false;
  scannerError: any = null;

  assignedOrderLineItems: AmsOrderLineItemModel[] = [];
  currentSkuScanReport: SkuScanReportViewModel | null = null;
  currentOrderScanReport: OrderScanReportViewModel | null = null;

  headers = this.isQC
    ? [
        { text: 'AMS Order #', value: 'amsOrderId' },
        { text: 'Order Status', value: 'status' },
        { text: '# Cases', value: 'quantity' }
      ]
    : [
        { text: 'AMS Order #', value: 'amsOrderId' },
        { text: 'Order Status', value: 'status' },
        { text: 'SKU', value: 'partNo' },
        { text: '# Cases', value: 'quantity' }
      ];

  get isNativeScannerSupported(): boolean {
    return 'BarcodeDetector' in window;
  }

  @Watch('visible', { immediate: true })
  onVisibleChanged(): void {
    if (this.visible) {
      this.activelyScanning = true;
      this.loadingNextLineItems = true;

      if (!this.isQC) {
        const pickersClient = new PickersClient();
        pickersClient
          .getNextOrderLineItemsOfPicker(3)
          .then((res) => {
            this.assignedOrderLineItems = res;
          })
          .finally(() => {
            this.loadingNextLineItems = false;
          });
      }
    } else {
      setTimeout(() => {
        this.currentSkuScanReport = null;
        this.activelyScanning = false;
        this.showScanResultOverlay = false;
        this.scannerError = null;
      }, 200);
    }

    this.emitInput(this.visible);
  }

  async onPartNoDetected(partNo: string) {
    if (this.isQC) {
      return;
    }

    const pickersClient = new PickersClient();

    this.showScanResultOverlay = true;
    this.activelyScanning = false;

    this.loadingSkuScanReport = true;
    try {
      const scanReport = await pickersClient.skuScanRequest(partNo.trim());
      this.currentSkuScanReport = scanReport;
    } catch (error) {
      console.error('error', error);
      // add a blank one to trigger the generic error message
      this.currentSkuScanReport = new SkuScanReportViewModel();
    } finally {
      this.loadingSkuScanReport = false;
    }
  }

  async onAmsOrderNoDetected(orderNo: string) {
    if (!this.isQC) {
      return;
    }

    const orderClient = new AmsOrdersClient();

    this.showScanResultOverlay = true;
    this.activelyScanning = false;

    this.loadingSkuScanReport = true;
    try {
      const scanReport = await orderClient.orderScanRequest(orderNo.trim());
      this.currentOrderScanReport = scanReport;
    } catch (error) {
      console.error('error', error);
      // add a blank one to trigger the generic error message
      this.currentOrderScanReport = new OrderScanReportViewModel();
    } finally {
      this.loadingSkuScanReport = false;
    }
  }

  onScannerError(error: any) {
    console.log('Scanner error', error);
    this.scannerError = error;
    this.showScanResultOverlay = true;
  }

  scanAgain() {
    this.showScanResultOverlay = false;
    this.activelyScanning = true;
  }

  goToScannedItem() {
    let location = null;
    if (this.isQC) {
      if (!this.currentOrderScanReport || !this.currentOrderScanReport.orderId) return;
      location = NwdLocations.qc.orderDetail.detail(this.currentOrderScanReport.orderId);
      this.visible = false;
      // check if we are already on the page
      if (this.$route.name === location.name) {
        if (this.$route.params.id === `${this.currentOrderScanReport.orderId}`) {
          return;
        } else {
          if (location != null) {
            this.$router.push(location);
          }
          this.$router.go(0);
        }
      } else if (location != null) {
        this.$router.push(location);
      }
    } else {
      if (
        !this.currentSkuScanReport ||
        !this.currentSkuScanReport.orderId ||
        !this.currentSkuScanReport.orderLineItemId
      ) {
        return;
      }
      location = NwdLocations.picker.orderDetail.orderLineItem({
        orderId: this.currentSkuScanReport.orderId,
        orderItemId: this.currentSkuScanReport.orderLineItemId,
        partNo: this.currentSkuScanReport.partNo
      });
      // check if we are already on the page
      if (
        this.$route.name === location.name &&
        this.$route.params.id === `${this.currentSkuScanReport.orderId}` &&
        this.$route.params.orderItemId === `${this.currentSkuScanReport.orderLineItemId}` &&
        this.$route.query.partNo === this.currentSkuScanReport.partNo
      ) {
        this.visible = false;
        return;
      }
      this.visible = false;
      if (location != null) {
        this.$router.push(location);
      }
    }
  }

  close() {
    this.visible = false;
  }
}

import {
  ConfigurationViewModel,
  IOrderListOutput,
  OrderItemViewModel,
  AmsOrderStatusEnum,
  OrderViewModel,
  PauseOrderInput,
  PickersClient,
  PrinterLocationDTO,
  WarehousePrinterType
} from '@/api/api';
import eventBus from '@/eventBus';
import {
  STATUS_ORDER_ASSIGNMENT,
  STATUS_PICKED,
  STATUS_PROGRESS_ORDER_STRING,
  STATUS_UNPICKED
} from '@/shared/constants/common';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';
import { ConfigurationGetter } from '@/store/configuration/configuration.module.types';
import { dispatchOrderAction } from '@/store/order/order-dispatch';
import { OrderAction, OrderGetter, OrderNamespace } from '@/store/order/order-module-types';
import { getFormatStringDate, getFormatStringHour } from '@/utilities/datetime-converter';
import { VForm } from '@/utilities/form';
import moment from 'moment-timezone';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Inject, Ref, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { OrderInterruptionViewModel } from '../../../api/api';
import { ConfigurationNamespace } from '../../../store/configuration/configuration.module.types';

import ClerkOrderLineItems from './_components/OrderLineItems.vue';
import PauseOrderDialog from './_components/PauseOrderDialog.vue';
import ClerkAddOrderNoteDialog from './_components/ClerkAddOrderNoteDialog.vue';
import CancelOrderDialog from './_components/CancelOrderDialog.vue';
import PrintDialog from '@/components/PrintDialog.vue';
import { ReportTypeEnum } from '@/components/report-type-enum';

@Component({
  name: 'OrderDetail',
  components: {
    ClerkOrderLineItems,
    PauseOrderDialog,
    ClerkAddOrderNoteDialog,
    CancelOrderDialog,
    PrintDialog
  }
})
export default class OrderDetail extends Vue {
  public readonly WarehousePrinterType = WarehousePrinterType;
  public readonly ReportTypeEnum = ReportTypeEnum;
  public readonly AmsOrderStatusEnum = AmsOrderStatusEnum;

  order = {} as OrderViewModel;
  orderItems = [] as OrderItemViewModel[] | undefined;
  lastPausedDetail!: OrderInterruptionViewModel | undefined;
  lastReturnedDetail!: OrderInterruptionViewModel | undefined;
  lastCancelledDetail!: OrderInterruptionViewModel | undefined;
  isInit = false as boolean;
  hasReturnedHistory = false as boolean;
  hasPausedHistory = false as boolean;
  hasCancelledHistory = false as boolean;

  @ConfigurationNamespace.Getter(ConfigurationGetter.configurations)
  readonly configuration!: ConfigurationViewModel;
  @OrderNamespace.Getter(OrderGetter.orderDetail)
  readonly orderDetails!: OrderViewModel;

  localtz = '';
  isClerkNoteDialogVisible = false;
  isPauseDialogVisible = false;
  pauseReasonField = '';

  isCancelDialogVisible = false;

  isPrintDialogVisible = false;
  reportType = ReportTypeEnum.PickTicket;
  printerType = WarehousePrinterType.None;

  @Ref('pauseForm') readonly pauseForm!: VForm;

  headers = [
    { text: 'Varietal', value: 'varietal', width: '20%' },
    { text: 'Status', value: 'status' },
    { text: 'Vintage', value: 'vintage' },
    { text: 'SKU', value: 'partNo' },
    { text: 'Bottle Size / Configuration', value: 'size' },
    { text: '# Case', value: 'case' },
    { text: 'Location', value: 'location' }
  ];
  STATUS_ORDER_ASSIGNMENT = STATUS_ORDER_ASSIGNMENT;
  STATUS_UNPICKED = STATUS_UNPICKED;
  STATUS_PICKED = STATUS_PICKED;

  get canPrint(): boolean {
    return (
      this.order.orderStatusCode >= AmsOrderStatusEnum.AwaitingQc &&
      this.order.orderStatusCode != AmsOrderStatusEnum.Cancelled
    );
  }

  get lastPauseUserFullName(): string {
    return this.lastPausedDetail && this.lastPausedDetail.interrupterUser
      ? `${this.lastPausedDetail.interrupterUser.firstName} ${this.lastPausedDetail.interrupterUser.lastName}`
      : '';
  }

  get lastReturnUserFullName(): string {
    return this.lastReturnedDetail?.interrupter?.name || '';
  }

  get lastCancelledUserFullName(): string {
    return this.lastCancelledDetail?.interrupter?.name || '';
  }

  public async mounted(): Promise<void> {
    await this.loadOrder();
    console.log('order', this.order.orderItems);
    this.orderItems = this.order.orderItems || [];
    this.isInit = true;
    this.localtz = moment.tz.guess();
  }

  created(): void {
    eventBus.$on('orderAssigned', this.loadOrder);
  }

  @Inject('assignOrderFunc')
  assignOrderFunc!: (order: IOrderListOutput) => void;

  readonly requireRule = (value: string | number): boolean | string => {
    return !!value || 'Required';
  };

  public async goBackHistory(): Promise<void> {
    const { $router } = this;
    const homePath: RawLocation = '/';
    this.hasHistory() ? $router.back() : await $router.push(homePath);
  }

  public hasHistory(): boolean {
    return window.history.length > 2;
  }

  public headerProgressBar(): string {
    if (!this.isInit) return '';
    const { orderStatusCode } = this.order;
    let progressPercent = 100;
    let colorPrimary = 'var(--color-progress-bar-in-progress-primary)';
    let colorSecondary = 'var(--color-progress-bar-in-progress-secondary)';

    if (orderStatusCode === AmsOrderStatusEnum.InProgress) {
      const totalCase = this.order.numberOfCases;
      const casePicked = this.order.casesPicked;

      progressPercent = (casePicked / totalCase) * 100;
    } else if (orderStatusCode === AmsOrderStatusEnum.Paused) {
      colorPrimary = 'var(--color-progress-bar-paused)';
      colorSecondary = 'var(--color-progress-bar-paused)';
    } else if (orderStatusCode === AmsOrderStatusEnum.Returned) {
      colorPrimary = 'var(--color-progress-bar-returned)';
      colorSecondary = 'var(--color-progress-bar-returned)';
    } else if (orderStatusCode === AmsOrderStatusEnum.Completed) {
      colorPrimary = 'var(--color-progress-bar-completed)';
      colorSecondary = 'var(--color-progress-bar-completed)';
    } else {
      return '';
    }

    return `background-image: linear-gradient(to right,${colorPrimary} ${progressPercent}% ,${colorSecondary} ${progressPercent}%)`;
  }
  public getBackgroundColor(): string {
    const { orderStatusCode } = this.order;

    if (orderStatusCode === AmsOrderStatusEnum.Paused) {
      return 'var(--color-progress-bar-paused)';
    } else if (orderStatusCode === AmsOrderStatusEnum.Returned) {
      return 'var(--color-progress-bar-returned)';
    } else {
      return '';
    }
  }

  public stylingRow(item: OrderItemViewModel): string {
    const { statusCode } = item;

    if (statusCode === STATUS_PICKED) return 'color-checked';
    return '';
  }

  public async loadOrder(): Promise<OrderViewModel> {
    const id: number = parseInt(this.$route.params.id);
    const order = await dispatchOrderAction(OrderAction.loadOrderDetail, id);
    const lastPauseInterruption =
      order?.orderInterruptionsPaused && order?.orderInterruptionsPaused.length > 0
        ? order?.orderInterruptionsPaused[0]
        : undefined;
    const lastReturnInterruption =
      order?.orderInterruptionsReturned && order?.orderInterruptionsReturned.length > 0
        ? order?.orderInterruptionsReturned[0]
        : undefined;
    const lastCancelInterruption =
      order?.orderInterruptionsCancelled && order?.orderInterruptionsCancelled.length > 0
        ? order?.orderInterruptionsCancelled[0]
        : undefined;
    this.lastPausedDetail = lastPauseInterruption;
    this.lastReturnedDetail = lastReturnInterruption;
    this.lastCancelledDetail = lastCancelInterruption;

    this.order = order;

    this.hasReturnedHistory =
      order.orderInterruptionsReturned != null &&
      order.orderInterruptionsReturned != undefined &&
      order.orderInterruptionsReturned.length > 0;

    this.hasPausedHistory =
      order.orderInterruptionsPaused != null &&
      order.orderInterruptionsPaused != undefined &&
      order.orderInterruptionsPaused.length > 0;

    this.hasCancelledHistory =
      order.orderInterruptionsCancelled != null &&
      order.orderInterruptionsCancelled != undefined &&
      order.orderInterruptionsCancelled.length > 0;
    return order;
  }

  public async assignOrder(): Promise<void> {
    const input: IOrderListOutput = {
      ...this.order,
      pickerId: this.order.picker?.pickerId,
      pickerName: this.order.picker?.name,
      orderId: this.order.id!,
      amsOrderId: this.order.amsOrderId!,
      canMoveUp: false,
      canMoveDown: false,
      interrupter: this.order.interrupter?.name
    };
    this.assignOrderFunc(input);
  }

  public getStatusClass(): string {
    if (this.order.orderStatusCode === AmsOrderStatusEnum.NotStarted) {
      return 'not-start-status';
    } else if (this.order.orderStatusCode === AmsOrderStatusEnum.InProgress) {
      return 'in-progress-status';
    } else if (this.order.orderStatusCode === AmsOrderStatusEnum.Paused) {
      return 'paused-status';
    } else if (this.order.orderStatusCode === AmsOrderStatusEnum.Completed) {
      return 'complete-status';
    } else {
      return 'returned-status';
    }
  }

  public getProcessStatusDetail(): string {
    const time = this.getHour(this.order.progressDate as Date);

    let interrupterName = '';
    if (this.order.orderStatusCode === AmsOrderStatusEnum.Paused) {
      interrupterName = this.lastPauseUserFullName;
    } else if (this.order.orderStatusCode === AmsOrderStatusEnum.Returned) {
      interrupterName = this.lastReturnUserFullName;
    } else if (this.order.orderStatusCode === AmsOrderStatusEnum.Cancelled) {
      interrupterName = this.lastCancelledUserFullName;
    }
    const status = STATUS_PROGRESS_ORDER_STRING[this.order.orderStatusCode]
      ? STATUS_PROGRESS_ORDER_STRING[this.order.orderStatusCode]
      : '';

    if (this.order.orderStatusCode !== AmsOrderStatusEnum.NotStarted) {
      return `${status} (${interrupterName ? `${interrupterName} ` : ''}${time})`;
    }

    return status;
  }

  get orderStatusIcon(): string {
    if (this.order.orderStatusCode === AmsOrderStatusEnum.Paused) {
      return 'mdi-briefcase-upload-outline';
    } else if (this.order.orderStatusCode === AmsOrderStatusEnum.Returned) {
      return 'mdi-undo';
    } else if (this.order.orderStatusCode === AmsOrderStatusEnum.Completed) {
      return 'mdi-briefcase-check';
    } else if (this.order.orderStatusCode === AmsOrderStatusEnum.InProgress) {
      return 'mdi-check-circle-outline';
    } else if (this.order.orderStatusCode === AmsOrderStatusEnum.NotStarted) {
      return 'mdi-briefcase-outline';
    } else if (this.order.orderStatusCode === AmsOrderStatusEnum.Cancelled) {
      return 'mdi-cancel';
    }

    return '';
  }

  public getProcessDate(date: Date): string {
    if (!date) {
      return '';
    }
    return `${getFormatStringDate(date, this.localtz)} ${this.getHour(date)}`;
  }

  public getOrderStatusText(): string {
    if (this.order.status === STATUS_ORDER_ASSIGNMENT.assigned && this.order.picker) {
      return `Assigned to ${this.order.picker.name}`;
    }

    return this.order.status as string;
  }

  getHour(date: Date): string {
    return getFormatStringHour(date, this.localtz);
  }

  async onUnpause(): Promise<void> {
    this.isPauseDialogVisible = false;
    const id: number = parseInt(this.$route.params.id);
    try {
      const orderId = await dispatchOrderAction(OrderAction.unpauseOrder, id);
      if (orderId) {
        this.order = await this.loadOrder();
        dispatchAlertAction(AlertAction.showSuccess, `AMS Order #${orderId} unpaused successfully.`);
      }
    } catch (e) {
      dispatchAlertAction(AlertAction.showError, `Unpause Order failed.`);
    }
  }

  getReturnReasonText(reasonCode: string | undefined): string {
    const reasonDescription = this.configuration?.lookupAndReasonTypes?.find((x) => x.code == reasonCode)?.name;

    return reasonDescription ? reasonDescription : '';
  }

  editClerkNote(): void {
    this.isClerkNoteDialogVisible = true;
  }
}

import Component from 'vue-class-component';
import { Vue, Prop, Watch } from 'vue-property-decorator';

type ThemeType = 'primary' | 'warning' | 'error' | 'info' | 'success';

type Theme = {
  icon: string | null;
  color: string;
  background: string;
  border: string;
}

@Component({
  name: 'x-expand-alert'
})
export default class XExpandAlert extends Vue {
  $refs!: Vue['$refs'] & {
    alertText: Element;
    slotContent: Element | null;
  };

  @Prop({ default: "0px", type: String }) readonly marginTop!: string;
  @Prop({ default: "32px" }) readonly marginBottom!: string;
  @Prop({ default: '' }) readonly maxWidth!: string;
  @Prop({ default: false }) readonly dismissible!: boolean;
  @Prop({ default: 0 }) readonly timeoutMs!: number;
  @Prop({ default: null }) readonly customIcon!: string;
  @Prop({ default: 'primary' }) readonly type!: string;
  @Prop({ default: '0' }) readonly elevation!: string;
  @Prop({ default: 8 }) readonly yPadding!: number;
  @Prop({ default: 'var(--v-primary-base)' }) readonly customBackground!: string;
  @Prop({ default: 'white' }) readonly customColor!: string;
  @Prop({ default: '1px solid black' }) readonly customBorder!: string;
  @Prop({ default: false }) readonly dense!: boolean;

  public DISMISSIBLE_ANIMATION_LAG_MS: number = 100;
  private TIMEOUT_UPDATE_RATE_MS: number = 100;
  private MAX_HEIGHT_PIXELS: number = 500;

  private _heightObserver: any = null;
  private _lastTextHeight: number = -1;

  private themes = {
    primary: {
      icon: null,
      color: 'white',
      background: 'var(--v-primary-base)',
      border: ''
    },
    warning: {
      icon: 'mdi-exclamation',
      color: 'white',
      background: 'var(--v-warning-darken1)',
      border: '1px solid var(--v-warning-darken1)'
    },
    error: {
      icon: 'mdi-alert',
      color: 'white',
      background: 'var(--v-error-base)',
      border: '1px solid var(--v-error-darken1)'
    },
    info: {
      icon: 'mdi-information',
      color: 'white',
      background: 'var(--v-info-base)',
      border: ''
    },
    success: {
      icon: 'mdi-check',
      color: 'white',
      background: 'var(--v-success-base)',
      border: ''
    }
  } as { [key: string]: Theme };

  hidden: boolean = true;
  alertMessage: string | null = null;
  minHeight: number | null = null;
  height: number = 0;
  background: string = '';
  border: string = '';
  color: string = '';
  icon: string = '';
  fontSize: number = 0;
  lastMessageDisplayed: Date | null = null;
  currentTimeoutPercent: number = 0;
  timeoutInterval: any = null;

  get theme() {
    const vuetify = (this as any).$vuetify;
    return (vuetify?.theme?.dark) ? 'dark' : 'light';
  }

  @Watch('hidden')
  onHiddenChanged(newHidden: boolean) {
    if (newHidden) {
      this.currentTimeoutPercent = 0;
    }
  }

  beforeMount() {
    if(this.dense){
      this.minHeight = 48;
      this.fontSize = 14;
    } else {
      this.minHeight = 64;
      this.fontSize = 16;
    }

    this.setTheme(this.type);
  }

  mounted() {
    this._setupHeightObserver();
    let message = '';

    const slotContent = this.$refs?.slotContent;
    if (slotContent) {
      // need to trim in case you add a couple spaces inside by accident
      message = slotContent.innerHTML?.trim();
    }

    if(message && message !== ''){
      this.setMessage(message)
    }
  }

  beforeDestroy() {
    this._heightObserver.disconnect();
    clearInterval(this.timeoutInterval);
  }

  private _setupHeightObserver() {
    const heightObs = new ResizeObserver(() => {
      this._recalculateAlertHeight();
    });

    const alertTextEl = this.$refs.alertText;
    heightObs.observe(alertTextEl);

    this._heightObserver = heightObs;
  }

  private _recalculateAlertHeight() {
    const alertTextEl = this.$refs.alertText;
    const textHeight = alertTextEl.getBoundingClientRect().height;

      if(textHeight != this._lastTextHeight) {
        this._lastTextHeight = textHeight;

        const yPadding = isNaN(this.yPadding) ? 0 : this.yPadding;
        const minHeight = this.minHeight || 0;

        let newHeight = minHeight;
        if(yPadding * 2 + textHeight > yPadding * 2 + minHeight) {
          newHeight = yPadding * 2 + textHeight + 8;
        }

        if(newHeight > this.MAX_HEIGHT_PIXELS) newHeight = this.MAX_HEIGHT_PIXELS;

        this.height = newHeight;
      }
  }

  private _awaitTimeout() {
    if (this.timeoutInterval) clearInterval(this.timeoutInterval);
      const scopedStart = this.lastMessageDisplayed = new Date();
      this.currentTimeoutPercent = 0;
      // if the timeout isn't valid we skip, since the alert will close instantly otherwise.
      if (!(this.timeoutMs > 0)) return;

      const scopedLoop = this.timeoutInterval = setInterval(() => {
        // cancel early if another instance of this has been started
        if (this.lastMessageDisplayed != scopedStart) {
          clearInterval(scopedLoop); return;
        }

        const elapsedMs = ((new Date()).getTime() - this.lastMessageDisplayed.getTime());
        let newTimeoutPercent = (elapsedMs / this.timeoutMs) * 100; // out of 100 not 1
        // console.log('newTimeoutPercent, elapsedMs, this.timeoutMs', newTimeoutPercent, elapsedMs, this.timeoutMs)

        if (elapsedMs >= this.timeoutMs) {
          newTimeoutPercent = 100;
          clearInterval(this.timeoutInterval);
          setTimeout(() =>  {
            this.hidden = true;
            setTimeout(() => {
              this.currentTimeoutPercent = 0;
            }, 200);
          }, 200)
        }
        this.currentTimeoutPercent = newTimeoutPercent;

      }, this.TIMEOUT_UPDATE_RATE_MS);
  }

  setTheme(themeType: string) {
    const theme = (this.themes as any)[themeType];
    if(theme) {
      this.icon = theme.icon;
      this.color = theme.color;

      this.background = theme.background;
      this.border = theme.border;
    }
  }

  hideAlert() {
    this.hidden = true;
  }

  setMessage(message: string, theme: ThemeType | null = null) {
    if(theme != null){
      this.setTheme(theme);
    }

    this._awaitTimeout();
    this.hidden = false;
    this.alertMessage = message;
  }
}

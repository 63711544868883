import { SystemPermission } from '@/api/api';

/**
 * The user must have at least one of these permissions to access the app.
 */
export const ScanToPickPermissions = [
  SystemPermission.ScanToPickClerk,
  SystemPermission.ScanToPickPicker,
  SystemPermission.ScanToPickQC,
  SystemPermission.ScanToPickAdmin
];

/**
 * Check if the user has the permission assigned.
 * @param userPermissions The user's permissions
 * @param permission The permission to check
 * @returns
 */
export function testPermission(userPermissions: SystemPermission[], permission: SystemPermission): boolean {
  return userPermissions.includes(permission);
}

export function hasAtLeastOnePermission(userPermissions: SystemPermission[], permissions: SystemPermission[]): boolean {
  if (!userPermissions) return false;

  for (const p of permissions) {
    if (testPermission(userPermissions, p)) {
      return true;
    }
  }

  return false;
}

export function hasAllPermissions(userPermissions: SystemPermission[], permissions: SystemPermission[]): boolean {
  if (!userPermissions) return false;

  for (const p of permissions) {
    if (!testPermission(userPermissions, p)) {
      return false;
    }
  }

  return true;
}

export function canUseApp(userPermissions: SystemPermission[]): boolean {
  return hasAtLeastOnePermission(userPermissions, ScanToPickPermissions);
}

/** Checks if a user has ONLY the given ScanToPickPermission out of all the ScanToPickPermissions*/
export function hasOneScanToPickPermission(
  userPermissions: SystemPermission[],
  scanToPickPermission: SystemPermission
): boolean {
  const hasTargetPerm = testPermission(userPermissions, scanToPickPermission);
  if (!hasTargetPerm) return false;

  for (let i = 0; i < ScanToPickPermissions.length; i++) {
    if (ScanToPickPermissions[i] === scanToPickPermission) continue;

    if (testPermission(userPermissions, ScanToPickPermissions[i])) {
      return false;
    }
  }

  return true;
}

import Vue from 'vue';
import Component from 'vue-class-component';
import PickerOrderInvalidAccess from '../_components/PickerOrderInvalidAccess.vue';
import {
  ConfigurationGetter,
  ConfigurationNamespace
} from '@/store/configuration/configuration.module.types';
import { ConfigurationViewModel, OrderViewModel } from '@/api/api';
import { PickerGetter, pickerNamespace } from '../_state/picker-module-type';

@Component({
  name: 'OrderPickerDetail',
  components: {
    PickerOrderInvalidAccess
  }
})
export default class OrderPickerDetail extends Vue {
  @ConfigurationNamespace.Getter(ConfigurationGetter.configurations)
  readonly configuration!: ConfigurationViewModel;
  @pickerNamespace.Getter(PickerGetter.order)
  readonly order!: OrderViewModel;
  @pickerNamespace.Getter(PickerGetter.orderInvalidAccess)
  readonly orderInvalidAccess!: boolean;
}

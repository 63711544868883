
import { AdminClient, PagedResultsOfWarehousePrintJobLogModel, WarehousePrintJobLogModel, PrintJobStatus, ScanToPickReportTypeEnum } from '@/api/api';
import NwdLocations from '@/router/nwd-router';
import { Component, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component({})
export default class AdminPrintJobLogs extends Vue {
  public readonly ScanToPickReportTypeEnum = ScanToPickReportTypeEnum;
  public readonly PrintJobStatus = PrintJobStatus;
  public readonly NwdLocations = NwdLocations;
  public readonly moment = moment;

  public isLoading = false;
  public printLogs: WarehousePrintJobLogModel[] = [];
  public totalCount = 0;
  public expanded: any[] = [];

  public headers = [
    { text: 'Status', value: 'status' },
    // { text: 'Report Type', value: 'reportType' },
    { text: 'Printer Name', value: 'warehousePrinterId' },
    { text: 'Document Name', value: 'documentName' },
    { text: 'Run Time', value: 'startTime' },
    { text: 'Created', value: 'dateAdded' },
  ];

  public options: any = {
    itemsPerPage: 10,
    page: 1,
    sortBy: ['dateAdded'],
    sortDesc: [true],
  }

  @Watch('options', { deep: true })
  public onOptionsChange(): void {
    this.getPrinterJobLogs().then((printLogs) => {
      this.printLogs = printLogs.results || [];
      this.totalCount = printLogs.resultCount;
    });
  }

  // async mounted(): Promise<void> {
  // }

  public async getPrinterJobLogs(): Promise<PagedResultsOfWarehousePrintJobLogModel> {
    const adminClient = new AdminClient();

    const formattedSorts = this.options.sortBy.map((sort: any, i: number) => {
      return `${sort}|${this.options.sortDesc[i] ? 'desc' : 'asc'}`;
    });

    const result = await adminClient.getPrintJobLogs(
      this.options.itemsPerPage,
      this.options.page - 1,
      formattedSorts,
    );

    return result;
  }

}

import { OrderLineItemViewModel, OrderViewModel } from '@/api/api';
import { Component, Prop, Vue } from 'vue-property-decorator';
import {
  dispatchQcAction,
  QcAction,
  qcNamespace,
  QcState
} from '../_state/qc-module-type';

@Component({})
export default class QcCheckItemDialog extends Vue {
  @qcNamespace.State(QcState.orderLineItem)
  readonly item!: OrderLineItemViewModel;
  @qcNamespace.State(QcState.checkItemDialog)
  readonly dialog!: boolean;

  @Prop({
    type: Function,
    required: true
  })
  readonly onSubmit!: (complete: boolean, itemId: number) => Promise<void>;
  @Prop({
    type: Boolean,
    required: true
  })
  readonly canComplete!: boolean;
  @Prop({
    type: Number,
    required: true
  })
  readonly nextItemId!: number;

  loading = false;

  close(): void {
    dispatchQcAction(QcAction.hideCheckItemDialog);
  }

  async submit(complete: boolean): Promise<void> {
    try {
      this.loading = true;
      await this.onSubmit(complete, this.nextItemId);
    } finally {
      this.loading = false;
    }
  }
}


export class BaseClient {
  protected transformOptions(options: RequestInit): Promise<RequestInit> {
    options.credentials = 'include';
    options.mode = 'cors';
    options.headers = {
      'Allow-Access-Control-Origin': '*',
      ...options.headers,
    };
    return Promise.resolve(options);
  }

  getBaseUrl(junk?: string, junk2?: string): string {
    return process.env.VUE_APP_API_BASE_URL || '';
  }
}

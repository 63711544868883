import { IPickerListOutput, ScanToPickUserStatusEnum } from '@/api/api';
import moment from 'moment';
import { Component, Prop, Vue } from 'vue-property-decorator';

// Components
import PickerOrders from './PickerOrders.vue';

@Component({
  components: {
    PickerOrders
  }
})
export default class PickerCard extends Vue {
  public readonly ScanToPickUserStatusEnum = ScanToPickUserStatusEnum;

  @Prop()
  picker!: IPickerListOutput & {
    showOrders: boolean;
    timeFilter: string;
  };
  statusColor(statusCode: number): string {
    if (statusCode === ScanToPickUserStatusEnum.Online) {
      return 'online-class';
    } else if (statusCode === ScanToPickUserStatusEnum.Paused) {
      return 'paused-class';
    } else if (statusCode === ScanToPickUserStatusEnum.Offline) {
      return 'offline-class';
    } else {
      return 'offline-class';
    }
  }

  getLastClockedDesc(date: Date | undefined): string {
    return moment(date).fromNow();
  }

  getDateFormat(date: Date | undefined): string {
    return moment(date).format('M/D/YYYY');
  }

  getCardClass(picker: IPickerListOutput): string {
    if (picker.ordersCount !== 0 && picker.statusCode === ScanToPickUserStatusEnum.Offline) {
      return 'state-unavailable';
    }
    return '';
  }
  getQueueClass(picker: IPickerListOutput): string {
    if (picker.ordersCount !== 0 && picker.statusCode === ScanToPickUserStatusEnum.Offline) {
      return 'unavailable-queue';
    }
    return '';
  }
}

import NwdLocations from '@/router/nwd-router';
import { RouteConfig } from 'vue-router';

import Login from '@/views/Login/Login.vue';
import { AppRouteConfig } from './AppRoutes';

export const AUTH_ROUTES: AppRouteConfig[] = [
  {
    path: '/login',
    name: NwdLocations.auth.login.name,
    component: Login,
    meta: { requiresAuth: false, hideNavbar: true }
  }
];

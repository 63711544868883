
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import Quagga, { QuaggaJSResultObject } from '@ericblade/quagga2';

@Component
export default class QuaggaWrapper extends Vue {

  $refs!: Vue['$refs'] & {
    quaggaWrapper: HTMLDivElement;
  };

  /** Whether or not the Quagga instance is actively reading. */
  @Prop({ default: false }) readonly enabled!: boolean;


  @Watch('enabled')
  onEnabledChange() {
    if (this.enabled) {
      this.initQuagga();
      if (this.debugRendering)
        this.clearDebugRects();

    } else {
      this.lastCode = '';
      Quagga.CameraAccess.disableTorch();
      Quagga.offDetected(this.onDetected);
      Quagga.stop();
    }
  }

  @Emit('partNoDetected')
  emitDetected(partNo: string) {
    return partNo;
  }

  @Emit('amsOrderNoDetected')
  emitAMSOrderDetected(amsOrderNo: string) {
    return amsOrderNo;
  }

  @Emit('failedToLoad')
  emitFailedToLoad(error: any) {
    return error;
  }

  debugRendering = true;
  viewportWidth = 640;
  viewportHeight = 420; //nice
  lastCode = '';
  isInitialized = false;

  mounted() {
    console.log('Quagga scanner is active, meaning the native scanner is not supported.');
    this.viewportHeight = window.innerHeight * 0.45;
    this.initQuagga();
  }

  beforeDestroy() {
    this.lastCode = '';
    Quagga.CameraAccess.disableTorch();
    Quagga.offDetected(this.onDetected);
    Quagga.stop();
  }

  clearDebugRects() {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;
    drawingCtx.clearRect(0, 0,
      parseInt(drawingCanvas.getAttribute('width') || this.viewportWidth + '', 10),
      parseInt(drawingCanvas.getAttribute('height') || this.viewportHeight + '', 10)
    );
  }

  initQuagga() {
    Quagga.init(
      {
        locate: true,
        debug: process.env.NODE_ENV === 'development',
        inputStream: {
          type: 'LiveStream',
          constraints: {
            height: {
              ideal: this.viewportHeight,
            },
            facingMode: 'environment' // or user
          },
          area: {
            // defines rectangle of the detection/localization area
            top: '0%', // top offset
            right: '0%', // right offset
            left: '0%', // left offset
            bottom: '0%' // bottom offset
          }
        },
        decoder: {
          readers: ['code_128_reader'],
          debug: {
            drawBoundingBox: this.debugRendering,
            showFrequency: this.debugRendering,
            drawScanline: this.debugRendering,
            showPattern: this.debugRendering
          }
        },
        locator: {
          willReadFrequently: false,
          patchSize: 'medium',
          halfSample: true,
          debug: {
            showPatches: this.debugRendering,
            showFoundPatches: this.debugRendering,
            showCanvas: this.debugRendering,
            showLabels: this.debugRendering,
            showPatchLabels: this.debugRendering,
            boxFromPatches: {
              showTransformed: this.debugRendering,
              showTransformedBox: this.debugRendering,
              showBB: this.debugRendering
            }
          }
        },
        numOfWorkers: navigator.hardwareConcurrency ? navigator.hardwareConcurrency : 4
      },
      (error) => {
        if (error) {
          console.log(error);
          this.emitFailedToLoad(error);
          return;
        }

        // Start the camera and the barcode scanner
        Quagga.start();
        // NOTE: The torch is more harmful than helpful to scan barcodes in the warehouse
        // Often times, the barcode will be too reflective and the torch will wash it out
        // Quagga.CameraAccess.enableTorch();
        this.isInitialized = true;
      }
    );

    Quagga.onDetected(this.onDetected);

    if (this.debugRendering)
      Quagga.onProcessed(this.onProcessed);
  }

  onDetected(result: QuaggaJSResultObject) {
    const code = result.codeResult.code;
    if (this.lastCode !== code) {
      this.lastCode = code || '';
      // ensure our code is at least 80% accurate and only contains valid characters (alphanumeric, only caps, space, dash, period)
      if (this.lastCode.toUpperCase().match(/^[0-9A-Z\s\-./]+$/)) {
        // First check to see if code mathes ams order convention
        if (this.lastCode.toUpperCase().match(/^[0-9]+.[0-9]+$/)) {
        // If it contains only numbers and a decimal, it's an AMS order number
          this.emitAMSOrderDetected(this.lastCode);
        } 
        else if (this.lastCode.match(/[A-Z]/) && this.lastCode.match(/[0-9]/)) {
          // it's probably a item number
          this.emitDetected(this.lastCode);
        }
      }
    }
  }

  onProcessed(result: QuaggaJSResultObject): any {
    const drawingCtx = Quagga.canvas.ctx.overlay;
    const drawingCanvas = Quagga.canvas.dom.overlay;

    if (result) {
      // lower accuracy boxes are orange, matched boxes are green
      if (result.boxes) {
        this.clearDebugRects();
        result.boxes
          .filter((box: any) => {
            return box !== result.box;
          })
          .forEach((box: any) => {
            Quagga.ImageDebug.drawPath(
              box,
              { x: 0, y: 1 },
              drawingCtx,
              { color: 'orange', lineWidth: 2 }
            );
          });
      }

      if (result.box) {
        Quagga.ImageDebug.drawPath(
          result.box,
          { x: 0, y: 1 },
          drawingCtx,
          { color: 'green', lineWidth: 2 }
        );
      }

      if (result.line) {
        Quagga.ImageDebug.drawPath(
          result.line,
          { x: 'x', y: 'y' },
          drawingCtx,
          { color: 'red', lineWidth: 1 }
        );
      }
    }
  }
}


import Vue from 'vue';
import Component from 'vue-class-component';
import SkuScannerDialog from '../_components/scanner/SkuScannerDialog.vue';
import { dispatchOrderAction } from '@/store/order/order-dispatch';
import { OrderAction } from '@/store/order/order-module-types';
import { Watch } from 'vue-property-decorator';
import { AuthGetter, AuthNamespace } from '@/store/auth/auth.module.types';
import { AuthResultViewModel } from '@/api/api';

@Component({
  components: {
    SkuScannerDialog
  }
})
export default class PickerIndex extends Vue {
  @AuthNamespace.Getter(AuthGetter.credential)
  readonly credential!: AuthResultViewModel;
  scannerDialogVisible: boolean = false;

  mounted() {
    dispatchOrderAction(
      OrderAction.setPickerId,
      this.credential?.userId || 0
    );
    dispatchOrderAction(OrderAction.loadOrdersPicker);
  }

  @Watch('$route')
  onRouteChange(newVal: any, oldVal: any) {
    // console.log(newVal, oldVal);
    // dispatchOrderAction(OrderAction.loadOrdersPicker);
  }

  toggleScannerDialog() {
    this.scannerDialogVisible = !this.scannerDialogVisible;
  }
}

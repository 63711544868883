// For some reason, eslint HATES this file. Turned off for now.

import { dispatchAuthAction } from '@/store/auth/auth.dispatch';
import { AuthAction } from '@/store/auth/auth.module.types';
import { getLocalCredential } from '@/store/utility/getLocalCredential';
import fetchIntercept, {
  FetchInterceptor,
  FetchInterceptorResponse
} from 'fetch-intercept';
import router from '@/router/AppRoutes';
import NwdLocations from '@/router/nwd-router';

const fetchInterceptorConfiguration: FetchInterceptor = {
  async request(url: string, config: any): Promise<any> {
    const credential = getLocalCredential();
    if (credential) {
      config.headers['Authorization'] = 'bearer ' + credential.token!;
    }
    return [url, config];
  },
  response(response: FetchInterceptorResponse): FetchInterceptorResponse {
    if (response.status === 401) {
      logoutUser();
    }
    return response;
  }
};

fetchIntercept.register(fetchInterceptorConfiguration);

export async function logoutUser(): Promise<void> {
  await dispatchAuthAction(AuthAction.logoutCredential);

  // avoid redundant navigation
  if (router.currentRoute.name === NwdLocations.auth.login.name) {
    return;
  }

  router.push({
    name: NwdLocations.auth.login.name
  });
}

import { AmsOrdersClient, OrderViewModel } from '@/api/api';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';
import { Component, Vue, Prop, Model, Watch } from 'vue-property-decorator';

@Component
export default class ClerkAddOrderNoteDialog extends Vue {
  @Model('update', { type: Boolean }) readonly value!: boolean;
  @Prop({ default: null, required: true }) readonly order!: OrderViewModel;

  dialogVisible = false;
  clerkNote: string | null = '';

  isDeletingClerkNote = false;
  isSavingClerkNote = false;

  @Watch('value', { immediate: true })
  onValueChange(newValue: boolean): void {
    this.dialogVisible = newValue;
  }

  @Watch('dialogVisible')
  onVisibilityChange(newValue: boolean): void {
    if (newValue) {
      this.clerkNote = this.order?.clerkNote || '';
    }
    this.$emit('update', newValue);
  }

  closeDialog(): void {
    this.dialogVisible = false;
    this.$emit('update', false);
  }

  async deleteNote() {
    if (!this.order?.id) return;

    this.isDeletingClerkNote = true;

    try {
      const amsOrdersClient = new AmsOrdersClient();
      await amsOrdersClient.deleteClerkNote(this.order.id);

      dispatchAlertAction(AlertAction.showSuccess, 'Clerk Note deleted successfully.');
      this.$emit('onNoteChange');
      this.closeDialog();

    } catch (error) {
      dispatchAlertAction(AlertAction.showError, 'Failed to delete Clerk Note.');
    } finally {
      this.isDeletingClerkNote = false;
    }


    this.clerkNote = null;
  }

  async save(): Promise<void> {
    if (!this.order?.id) return;

    this.isSavingClerkNote = true;

    try {
      // Perform the API call to save the clerk note
      const amsOrdersClient = new AmsOrdersClient();
      await amsOrdersClient.updateClerkNote(this.order.id, this.clerkNote || "");
      dispatchAlertAction(AlertAction.showSuccess, 'Clerk Note saved successfully.');

      this.$emit('onNoteChange');
      this.closeDialog();

    } catch (error) {
      dispatchAlertAction(AlertAction.showError, 'Failed to save Clerk Note.');
    } finally {
      this.isSavingClerkNote = false;
    }
  }
}

import NwdLocations, { DISCARD_NUMERIC_ID } from '@/router/nwd-router';
import {
  NavigationGuardNext,
  RawLocation,
  Route,
  RouteConfig
} from 'vue-router';
import {
  dispatchPickerAction,
  PickerAction
} from '../views/Picker/_state/picker-module-type';

import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';

import PickerOrderList from '@/views/Picker/Orders/PickerOrderList.vue';
import OrderDetail from '@/views/Picker/Order/OrderDetail.vue';
import OrderItem from '@/views/Picker/OrderItems/OrderItem.vue';
import OrderLineItems from '@/views/Picker/Order/OrderLineItems/OrderLineItems.vue';
import { AppRouteConfig } from './AppRoutes';
import { AmsOrderStatusEnum, SystemPermission } from '@/api/api';

import PickerIndex from '@/views/Picker/PickerIndex.vue';

export const PICKER_ROUTES: AppRouteConfig[] = [
  {
    path: '/pick',
    component: PickerIndex,
    meta: {
      requiresAuth: true,
      allowedPermissions: [SystemPermission.ScanToPickPicker, SystemPermission.ScanToPickAdmin]
    },
    children: [
      {
        path: '',
        name: NwdLocations.picker.orders.name,
        component: PickerOrderList
      },
      {
        path: `orders/:${NwdLocations.picker.orderDetail.idParam}`,
        component: OrderDetail,
        beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
          const { id } = to.params;
          const order = await dispatchPickerAction(PickerAction.loadOrder, {
            orderId: parseInt(id)
          });
          if (order.orderStatusCode === AmsOrderStatusEnum.Paused) {
            dispatchAlertAction(
              AlertAction.showWarning,
              'This order has been paused by the Warehouse Clerk. Please drop off your pallet with any and all items still on it to the purgatory zone in the back.'
            );
            next({
              name: NwdLocations.picker.orders.name
            });
            return;
          }
          next();
        },
        children: [
          {
            path: `items`,
            name: NwdLocations.picker.orderDetail.orderLineItems(DISCARD_NUMERIC_ID).name,
            component: OrderLineItems
          },
          {
            path: `items/:${NwdLocations.picker.orderDetail.orderLineItemIdParam}`,
            name: NwdLocations.picker.orderDetail.orderLineItem({
              orderId: DISCARD_NUMERIC_ID,
              orderItemId: DISCARD_NUMERIC_ID
            }).name,
            component: OrderItem,
            beforeEnter: async (to: Route, _from: Route, next: NavigationGuardNext): Promise<void> => {
              const { orderItemId } = to.params;
              const li = await dispatchPickerAction(PickerAction.getOrderLineItem, {
                orderLineItemId: parseInt(orderItemId)
              });
              await dispatchPickerAction(PickerAction.getNextOrderLineItem, {
                orderId: li.orderId,
                seq: li.itemNo
              });
              next();
            }
          },
          {
            path: '',
            redirect: (to: Route): RawLocation => {
              return {
                path: 'items',
                params: {
                  id: to.params.id
                }
              };
            }
          }
        ]
      }
    ]
  }
];

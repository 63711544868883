import {
  OrderItemViewModel,
  OrderLineItemViewModel,
  OrderQCViewModel,
  OrderViewModel
} from '@/api/api';
import { getDispatch } from '@/store/get-dispatch';
import { NwdRootState } from '@/store/nwd-web-root-state';
import { DispatchAction } from '@/store/utility/dispatch-action';
import { TypedModule } from '@/store/utility/module-utility-types';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { ISection } from '../_components/QcOrderItemSection';

export const enum QcState {
  orders = 'orders',
  order = 'order',
  orderLineItem = 'orderLineItem',
  previousOrderLineItem = 'previousOrderLineItem',
  nextOrderLineItem = 'nextOrderLineItem',
  orderLineItemSections = 'orderLineItemSections',
  completeOrderDialog = 'completeOrderDialog',
  checkItemDialog = 'checkItemDialog',
  lastPrintOrderId = 'lastPrintOrderId',
  orderQcInput = 'orderQcInput'
}

interface QcStateTypes {
  [QcState.orders]: OrderQCViewModel[];
  [QcState.order]: OrderViewModel | undefined;
  [QcState.orderLineItem]: OrderLineItemViewModel | undefined;
  [QcState.previousOrderLineItem]: OrderItemViewModel | undefined;
  [QcState.nextOrderLineItem]: OrderItemViewModel | undefined;
  [QcState.orderLineItemSections]: ISection[];
  [QcState.completeOrderDialog]: boolean;
  [QcState.checkItemDialog]: boolean;
  [QcState.lastPrintOrderId]: number | undefined;
  [QcState.orderQcInput]: { searchText: string; timeframe: number };
}

export const enum QcMutation {
  setOrders = 'setOrders',
  setOrder = 'setOrder',
  setOrderLineItem = 'setOrderLineItem',
  setPreviousOrderLineItem = 'setPreviousOrderLineItem',
  setNextOrderLineItem = 'setNextOrderLineItem',
  setOrderLineItemSections = 'setOrderLineItemSections',
  setCompleteOrderDialog = 'setCompleteOrderDialog',
  setCheckItemDialog = 'setCheckItemDialog',
  setLastPrintOrderId = 'setLastPrintOrderId',
  setOrderQcInput = 'setOrderQcInput'
}

interface QcMutationPayloads {
  [QcMutation.setOrders]: OrderQCViewModel[];
  [QcMutation.setOrder]: OrderViewModel | undefined;
  [QcMutation.setOrderLineItem]: OrderLineItemViewModel | undefined;
  [QcMutation.setPreviousOrderLineItem]: OrderItemViewModel | undefined;
  [QcMutation.setNextOrderLineItem]: OrderItemViewModel | undefined;
  [QcMutation.setOrderLineItemSections]: ISection[];
  [QcMutation.setCompleteOrderDialog]: boolean;
  [QcMutation.setCheckItemDialog]: boolean;
  [QcMutation.setLastPrintOrderId]: number | undefined;
  [QcMutation.setOrderQcInput]: { searchText: string; timeframe: number };
}

export const enum QcAction {
  getOrders = 'getOrders',
  getOrder = 'getOrder',
  getOrderLineItem = 'getOrderLineItem',
  goNextOrderLineItem = 'goNextOrderLineItem',
  goBackOrderLineItem = 'goBackOrderLineItem',
  goToOrderLineItem = 'goToOrderLineItem',
  updateOrderLineItem = 'updateOrderLineItem',
  showCompleteOrderDialog = 'showCompleteOrderDialog',
  hideCompleteOrderDialog = 'hideCompleteOrderDialog',
  showCheckItemDialog = 'showCheckItemDialog',
  hideCheckItemDialog = 'hideCheckItemDialog',
  printPickTicket = 'printPickTicket',
  updateOrderQcInput = 'updateOrderQcInput'
}

interface QcActions {
  [QcAction.getOrders]: () => void;
  [QcAction.getOrder]: (payload: {
    orderId: number;
  }) => Promise<OrderViewModel>;
  [QcAction.getOrderLineItem]: (payload: {
    orderLineItemId: number;
  }) => OrderLineItemViewModel;
  [QcAction.goNextOrderLineItem]: () => void;
  [QcAction.goBackOrderLineItem]: () => void;
  [QcAction.goToOrderLineItem]: (payload: {
    orderLineItemId: number;
  }) => void;
  [QcAction.updateOrderLineItem]: (payload: {
    orderLineItem: OrderItemViewModel;
  }) => void;
  [QcAction.showCompleteOrderDialog]: () => void;
  [QcAction.hideCompleteOrderDialog]: () => void;
  [QcAction.showCheckItemDialog]: () => void;
  [QcAction.hideCheckItemDialog]: () => void;
  [QcAction.printPickTicket]: (payload: {
    orderId: number;
  }) => Promise<void>;
  [QcAction.updateOrderQcInput]: ({
    searchText,
    timeframe
  }: {
    searchText: string;
    timeframe: number;
  }) => Promise<void>;
}

export const enum QcGetter {
  orders = 'orders',
  order = 'order',
  orderLineItem = 'orderLineItem',
  previousOrderLineItem = 'previousOrderLineItem',
  nextOrderLineItem = 'nextOrderLineItem',
  orderLineItemSections = 'orderLineItemSections'
}

interface QcGetterTypes {
  [QcGetter.orders]: OrderQCViewModel[];
  [QcGetter.order]: OrderViewModel | undefined;
  [QcGetter.orderLineItem]: OrderLineItemViewModel | undefined;
  [QcGetter.previousOrderLineItem]: OrderItemViewModel | undefined;
  [QcGetter.nextOrderLineItem]: OrderItemViewModel | undefined;
  [QcGetter.orderLineItemSections]: ISection[];
}

export const QC_NAMESPACE = 'QcNameSpace';
export const qcNamespace = namespace(QC_NAMESPACE);
export const dispatchQcAction: DispatchAction<QcActions> =
  getDispatch(QC_NAMESPACE);

export type QcModuleType = Override<
  Module<QcStateTypes, NwdRootState>,
  TypedModule<
    QcStateTypes,
    QcMutationPayloads,
    QcActions,
    QcGetterTypes,
    NwdRootState
  >
>;

import { OrderViewModel, PickersClient, PrinterLocationDTO, WarehousePrinterType } from '@/api/api';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { dispatchQcAction, QcAction, qcNamespace, QcState } from '../_state/qc-module-type';
import { Validators } from '@/utilities/validators';

@Component({})
export default class QcCompleteOrderDialog extends Vue {
  @qcNamespace.State(QcState.order)
  readonly order!: OrderViewModel;
  @qcNamespace.State(QcState.completeOrderDialog)
  readonly dialog!: boolean;

  readonly printerSelectRules = Validators.for('Printer', Validators.required);

  @Prop({
    type: Function,
    required: true
  })
  readonly onSubmit!: (targetLocationCode: string) => Promise<void>;

  get pickerName(): string | undefined {
    return this.order && this.order.picker ? this.order.picker.name : '';
  }

  loading = false;

  async mounted(): Promise<void> {
    await this.loadPrinters();
  }

  close(): void {
    dispatchQcAction(QcAction.hideCompleteOrderDialog);
  }

  async submit(): Promise<void> {
    try {
      this.loading = true;
      await this.onSubmit(this.targetLocationCode);
    } finally {
      this.close();
      this.loading = false;
    }
  }

  public isLoadingPrinters = false;
  public warehousePrinters: PrinterLocationDTO[] = [];
  public targetLocationCode = '';

  async loadPrinters(): Promise<void> {
    this.isLoadingPrinters = true;
    try {
      const pickersClient = new PickersClient();
      const newPrinters = await pickersClient.getPrintLocations(this.order.id, WarehousePrinterType.Standard);

      newPrinters.unshift(
        new PrinterLocationDTO({
          locationCode: undefined,
          printerStatus: 'Do not print',
          isDefault: false,
          isOnline: true
        })
      );

      const candidates = newPrinters.filter((p) => p.isOnline && p.locationCode == this.order.locationCode);
      this.targetLocationCode = candidates.length > 0 ? candidates[0].locationCode ?? '' : '';

      this.warehousePrinters = newPrinters;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoadingPrinters = false;
    }
  }
}


import { OrderViewModel } from '@/api/api';
import NwdLocations from '@/router/nwd-router';
import Vue from 'vue';

export default Vue.extend({
  name: 'OrderDetailHeader',
  props: {
    handleSubmit: Function,
    reasons: Array,
    required: { default: false },
    order: OrderViewModel,
    disabled: Boolean
  },
  data: () => ({
    isShow: false,
    reason: '',
    note: '',
    isDisable: false
  }),
  created() {
    this.$data.isDisable = this.required;
  },
  methods: {
    onToggleDialog() {
      this.isShow = !this.isShow;
    },
    async submit() {
      await this.handleSubmit(this.reason, this.note);
    },
    requiredLabel(label: string) {
      return `${label} ${this.$data.isDisable ? '*' : ''}`;
    }
  },
  computed: {
    seqOrderLine: function () {
      const order = this.order as OrderViewModel;
      const orderLinePicked = order?.orderItems?.filter(
        (s) => s.statusCode === 1
      ).length;
      return orderLinePicked;
    },
    totalOrderLine: function () {
      const order = this.order as OrderViewModel;
      return order?.lineItemCount;
    },
    orderId: function () {
      const order = this.order as OrderViewModel;
      return order?.amsOrderId;
    },
    customerName: function () {
      const order = this.order as OrderViewModel;
      return order?.wineryName;
    },
    isDisableSubmit: function () {
      if (!this.isDisable) return this.isDisable;
      return !(this.reason.length > 0 && this.note.length > 0);
    },
    backToOrder: function () {
      const location = NwdLocations.picker.orders
      return location;
    }
  }
});

import { AdminClient } from '@/api/api';
import { AdminAction, AdminGetter, AdminModuleType, AdminMutation, AdminState } from './admin-module-type';

export const adminModule: AdminModuleType = {
  namespaced: true,
  state: {
    [AdminState.results]: []
  },
  mutations: {
    [AdminMutation.setResults]: (state, payload) => {
      state.results = payload;
    }
  },
  actions: {
    [AdminAction.loadResults]: async ({ commit }) => {
      //const { searchText, timeframe } = state.orderQcInput;
      //console.log('searchText', searchText);
      const res = await new AdminClient().getSyncResults();
      commit(AdminMutation.setResults, res);
    }
  },
  getters: {
    [AdminGetter.results]: (state) => state.results
  }
};



import { Component, Prop, Vue, Watch, Model } from 'vue-property-decorator';
import { OrderViewModel, PauseOrderInput } from '@/api/api';
import { AmsOrderStatusEnum } from '@/api/api';
import { dispatchOrderAction } from '@/store/order/order-dispatch';
import { OrderAction } from '@/store/order/order-module-types';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';

@Component
export default class PauseOrderDialog extends Vue {
  public readonly AmsOrderStatusEnum = AmsOrderStatusEnum;
  public readonly requireRule = (value: string | number): boolean | string => {
    return !!value || 'Required';
  }

  @Model('update:isVisible', { type: Boolean, default: false }) localIsVisible!: boolean;
  @Prop() order!: OrderViewModel;

  @Watch('localIsVisible')
  onIsVisibleChanged(value: boolean) {
    this.$emit('update:isVisible', value);
  }

  onPause() {
    this.localIsVisible = true;
  }

  async onPauseOrder() {
    if (this.$refs.pauseForm && (this.$refs.pauseForm as any).validate()) {
      //To do on paused API call
      const body = new PauseOrderInput({
        orderId: this.order.id as number,
        pauseNote: this.order.returnNote
      });

      try {
        const orderId = await dispatchOrderAction(OrderAction.pauseOrder, body);
        if (orderId) {
          // emit onPause event
          this.$emit('onPause', orderId);

          dispatchAlertAction(
            AlertAction.showSuccess,
            `AMS Order #${orderId} Paused.`
          );
        }
      } catch (e) {
        dispatchAlertAction(AlertAction.showError, `Pause Order failed.`);
      }
    }
  }

}



import { Component, Vue } from 'vue-property-decorator';

// Components
import ClerkOrderList from '../_components/order-list/ClerkOrderList.vue';
import PickerList from '../_components/picker-list/PickerList.vue';
import { ClerkAction } from '../_state/clerk-module-type';
import { dispatchClerkAction } from '../_state/clerk-module-type';
import { OrderAction } from '@/store/order/order-module-types';
import { dispatchOrderAction } from '@/store/order/order-dispatch';
import eventBus from '@/eventBus';

@Component({
  components: {
    ClerkOrderList,
    PickerList
  }
})
export default class Dashboard extends Vue {
  refreshIntervalId: number | null = null;
  mounted() {
    // set up a interval to refresh the orders and pickers every 30 seconds
    this.refreshIntervalId = setInterval(this.refreshData, 30000);
  }
  beforeDestroy() {
    // clear the interval when the component is destroyed
    if (this.refreshIntervalId) {
      clearInterval(this.refreshIntervalId);
    }
  }

  async refreshData() {
    // get last pickersInput from the store
    const pickersInput = await dispatchClerkAction(ClerkAction.getPickersInput);
    await Promise.all([
      dispatchClerkAction(ClerkAction.getOrders),
      dispatchClerkAction(ClerkAction.getPickers, { input: pickersInput })
    ]);

    eventBus.$emit('reloadPickerOrders');
  }
}

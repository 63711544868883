import { UsersClient, UserLoginInput, ClockedTimeClient } from '@/api/api';
import { LOCAL_STORAGE_CREDENTIAL } from '@/shared/constants/common';
import { dispatchAlertAction } from '../alert/alert.dispatch';
import { AlertAction } from '../alert/alert.module-types';
import {
  clearLocalCredential,
  getLocalCredential
} from '../utility/getLocalCredential';
import {
  AuthAction,
  AuthMutation,
  AuthState,
  AuthModule,
  AuthGetter
} from './auth.module.types';

export const authModule: AuthModule = {
  namespaced: true,
  state: {
    [AuthState.credential]: getLocalCredential(),
    [AuthState.userInfo]: null
  },
  mutations: {
    [AuthMutation.saveCredential]: (state, payload) => {
      state[AuthState.credential] = payload;
    },
    [AuthMutation.setUserInfo]: (state, payload) => {
      state.userInfo = payload;
    }
  },
  actions: {
    [AuthAction.logoutCredential]: async ({ commit }) => {
      clearLocalCredential();
      commit(AuthMutation.saveCredential, null);
    },

    [AuthAction.loginCredential]: async ({ commit }, body: UserLoginInput) => {
      const client = new UsersClient();
      const credential = await client.login(body);
      if (credential) {
        localStorage.setItem(
          LOCAL_STORAGE_CREDENTIAL,
          JSON.stringify(credential)
        );
      }
      commit(AuthMutation.saveCredential, credential);
      dispatchAlertAction(AlertAction.showSuccess, 'Logged in successfully as ' + credential?.name);
    },

    [AuthAction.getCurrentUserInfo]: async ({ commit, state }) => {
      if (!state.credential) {
        return null;
      }

      const client = new UsersClient();
      const userInfo = await client.getCurrentInternalUser();
      commit(AuthMutation.setUserInfo, userInfo);
      return userInfo;
    },

    [AuthAction.logout]: async ({ commit }) => {
      clearLocalCredential();
      commit(AuthMutation.saveCredential, null);

      // stop the clock if it's running
      const clockedTimeClient = new ClockedTimeClient();
      await clockedTimeClient.stopClock();

      // now we can logout
      const client = new UsersClient();
      await client.logout();
    }
  },
  getters: {
    [AuthGetter.credential]: (state) => state.credential,
    [AuthGetter.userInfo]: (state) => state.userInfo
  }
};

import { render, staticRenderFns } from "./AdminPrinters.vue?vue&type=template&id=fac2e4a6&scoped=true&"
import script from "./AdminPrinters.vue?vue&type=script&lang=ts&"
export * from "./AdminPrinters.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fac2e4a6",
  null
  
)

export default component.exports
import { DataTableOptions } from '@/@types/data-table-options';
import {
  PickerListOutput,
  PickersClient,
  ScanToPickUserStatusEnum,
  AmsOrdersClient,
  UpdateOrderForPickerInput,
  OrderListOutput,
  AmsOrderStatusEnum
} from '@/api/api';
import eventBus from '@/eventBus';
import { STATUS_PICKER_STRING } from '@/shared/constants/common';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class AssignOrder extends Vue {
  @Prop({
    default: false
  })
  assignOrderDialog!: boolean;
  @Prop({
    default: {}
  })
  order!: OrderListOutput;

  STATUS_PICKER_STRING = STATUS_PICKER_STRING;
  ORDER_STATUS_RETURNED = AmsOrderStatusEnum.Returned;
  ScanToPickUserStatusEnum = ScanToPickUserStatusEnum;
  statusFilter: ScanToPickUserStatusEnum | null = null;
  selected: PickerListOutput[] = [];
  pickers: PickerListOutput[] = [];
  pickerCount = 0;
  options: DataTableOptions & {
    search?: string;
    pickerStatus: ScanToPickUserStatusEnum;
  } = {
    itemsPerPage: 5,
    page: 1,
    sortBy: [],
    sortDesc: [],
    pickerStatus: ScanToPickUserStatusEnum.Online
  };
  headers = [
    { text: 'Picker', value: 'name' },
    { text: 'Status', value: 'statusCode' },
    { text: 'Orders in Queue', value: 'ordersCount' },
    { text: 'Cases in Queue', value: 'totalCases' }
  ];
  loading = false;
  onAssigning = false;
  unAssignable = false;
  reAssignable = false;

  filterButtonStyles = {
    margin: '20px 10px 0 10px',
    'box-shadow': '0px 2px 4px #ccc',
    float: 'right'
  };
  iconStyles = {
    color: 'inherit'
  };

  @Watch('assignOrderDialog')
  onDialogChanges(val: boolean): void {
    if (!val) {
      return;
    }
    this.options = {
      ...this.options,
      page: 1,
      pickerStatus: ScanToPickUserStatusEnum.Online
    };
    this.loadPickers();
  }

  @Watch('selected')
  onSelectedChanges(): void {
    this.unAssignable = this.selected?.filter((x) => x.pickerId == this.order.pickerId)?.length > 0;
    this.reAssignable = this.order.orderStatusCode === AmsOrderStatusEnum.Returned && this.unAssignable == true;
  }

  async onAssign(): Promise<void> {
    if (!this.selected || this.selected.length === 0) {
      return;
    }

    const client = new AmsOrdersClient();
    const { pickerId, name } = this.selected[0];
    const { orderId, orderStatusCode, amsOrderId } = this.order;
    const body = new UpdateOrderForPickerInput();
    const successString: string =
      `Order ${amsOrderId?.toFixed(1)} ` +
      (this.unAssignable == true ? 'removed from' : 'added to') +
      ` the queue for ${name}.`;
    body.pickerId = this.unAssignable == true && this.reAssignable == false ? undefined : pickerId;
    body.orderStatusCode =
      orderStatusCode === AmsOrderStatusEnum.Returned ? AmsOrderStatusEnum.NotStarted : orderStatusCode;
    try {
      this.onAssigning = true;
      await client.assignPickerToOrder(body, orderId);
      dispatchAlertAction(AlertAction.showSuccess, successString);

      console.log('orderAssigned', {
        pickerId,
        orderId
      });

      eventBus.$emit('orderAssigned', {
        pickerId,
        orderId
      });

      this.close();
    } finally {
      this.onAssigning = false;
      this.selected = [];
    }
  }

  updateStatusFilter(val: ScanToPickUserStatusEnum): void {
    this.options = {
      ...this.options,
      pickerStatus: val
    };
    this.loadPickers();
  }

  async updateOptions(options: DataTableOptions): Promise<void> {
    if (
      options.itemsPerPage === this.options.itemsPerPage &&
      options.page === this.options.page &&
      options.sortBy === this.options.sortBy &&
      options.sortDesc === this.options.sortDesc
    ) {
      return;
    }
    this.options = {
      ...this.options,
      ...options
    };
    await this.loadPickers();
  }

  close(): void {
    this.selected = [];
    this.$emit('close');
  }

  private searchDebounceTimeout: number | null = null;
  onSearchDebounce(val: string): void {
    if (this.searchDebounceTimeout) {
      clearTimeout(this.searchDebounceTimeout);
    }
    this.searchDebounceTimeout = setTimeout(() => {
      this.options = {
        ...this.options,
        search: val
      };
      this.loadPickers();
    }, 200);
  }

  private async loadPickers(): Promise<void> {
    this.loading = true;
    try {
      const { itemsPerPage, page, pickerStatus, search } = this.options;
      const client = new PickersClient();
      const res = await client.getClerkPickers(itemsPerPage, page - 1, pickerStatus, search, ['statusCode|asc']);
      this.pickers = res.results ?? [];
      this.pickerCount = res.resultCount;
      if (this.order.pickerId) {
        this.selected = this.pickers.filter((x) => x.pickerId == this.order.pickerId);
      }
    } catch (error) {
      dispatchAlertAction(AlertAction.showError, 'Failed to load pickers.');
      console.error('Failed to load pickers:', error);
    } finally {
      this.loading = false;
    }
  }
}

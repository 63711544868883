import { Component, Inject, Prop, Vue } from 'vue-property-decorator';
import { PickersGet } from '../../_state/clerk-module-type';
import debounce from 'lodash.debounce';
import { ScanToPickUserStatusEnum } from '@/api/api';
import { noop } from 'vue-class-component/lib/util';
import eventBus from '@/eventBus';

@Component
export default class PickerListFilter extends Vue {
  @Prop({
    default: false
  })
  isFilterDisplaying!: boolean;

  @Prop({
    default: () => ({})
  })
  filters!: PickersGet;

  showSearch = false;
  iconStyles = {
    color: 'inherit'
  };
  headingStyles = {
    'font-size': '16px'
  };

  ScanToPickUserStatusEnum = ScanToPickUserStatusEnum;

  pickerStatusItems = [
    {
      text: 'All',
      value: ScanToPickUserStatusEnum.All,
      icon: 'mdi-account-group'
    },
    {
      text: 'Online',
      value: ScanToPickUserStatusEnum.Online,
      icon: 'mdi-timer-outline'
    },
    {
      text: 'Paused',
      value: ScanToPickUserStatusEnum.Paused,
      icon: 'mdi-clock-alert-outline'
    },
    {
      text: 'Offline',
      value: ScanToPickUserStatusEnum.Offline,
      icon: 'mdi-timer-off-outline'
    }
  ];

  @Inject({
    from: 'updatePickersGet',
    default: noop
  })
  updatePickersGet!: (updates: Partial<PickersGet>) => void;

  async onSelectStatusCode(statusCode: number): Promise<void> {
    console.log('onSelectStatusCode', statusCode);
    (this.updatePickersGet || noop)({
      pickerStatus: statusCode
    });
  }

  public onSearchDebounce = debounce(this.onSearch, 250);

  private async onSearch(text: string): Promise<void> {
    (this.updatePickersGet || noop)({
      searchText: text
    });
  }

  public async refreshPickerList(): Promise<void> {
    (this.updatePickersGet || noop)({
      searchText: ''
    });

    eventBus.$emit('reloadPickerOrders');
  }
}

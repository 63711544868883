import moment from 'moment';
import {
  OrderLineItemEditNoteInput,
  AmsOrderLineItemsClient,
  AmsOrdersClient,
  AmsOrderStatusEnum,
  ReportsClient,
  PickersClient,
  FileResponse
} from '../../api/api';
import {
  OrderAction,
  OrderGetter,
  OrderModule,
  OrderMutation,
  OrderState
} from './order-module-types';

export const orderModule: OrderModule = {
  namespaced: true,
  state: {
    [OrderState.orders]: [],
    [OrderState.filters]: {},
    [OrderState.assignOrder]: undefined,
    [OrderState.customers]: [],
    [OrderState.ordersQC]: [],
    [OrderState.ordersPicker]: [],
    [OrderState.orderQCFilters]: { searchText: '', timeframe: 2 },
    [OrderState.lastOrderIdCompleted]: null,
    [OrderState.pickerId]: null,
    [OrderState.orderDetail]: null,
    [OrderState.orderDetailAndSubjectId]: null,
    [OrderState.orderInvalidAccess]: null,
    [OrderState.orderLineItem]: null,
    [OrderState.orderLineItemNoteDialogVisible]: false,
    [OrderState.loading]: false,
    [OrderState.pickerOrdersGet]: {
      itemsPerPage: 20,
      page: 1,
      sortBy: [],
      sortDesc: [],
      sortCriteriaDefault: []
    },
    [OrderState.ordersPickerCount]: 0
  },
  mutations: {
    [OrderMutation.setOrders]: (state, payload) => {
      state[OrderState.orders] = payload;
    },
    [OrderMutation.setFilters]: (state, payload) => {
      state[OrderState.filters] = payload;
    },
    [OrderMutation.setAssignOrder]: (state, payload) => {
      state[OrderState.assignOrder] = payload;
    },
    [OrderMutation.setCustomers]: (state, payload) => {
      state[OrderState.customers] = payload;
    },
    [OrderMutation.loadOrders]: (state, payload) => {
      state[OrderState.orders] = payload;
    },
    [OrderMutation.loadOrdersPicker]: (state, payload) => {
      state[OrderState.ordersPicker] = payload;
    },
    [OrderMutation.setOrdersQC]: (state, payload) => {
      state[OrderState.ordersQC] = payload;
    },
    [OrderMutation.setOrdersPicker]: (state, payload) => {
      state[OrderState.ordersPicker] = payload;
    },
    [OrderMutation.setOrdersPickerCount]: (state, payload) => {
      state[OrderState.ordersPickerCount] = payload;
    },
    [OrderMutation.setOrderQCFilters]: (state, payload) => {
      state[OrderState.orderQCFilters] = payload;
    },
    [OrderMutation.setLastOrderIdCompleted]: (state, payload) => {
      state[OrderState.lastOrderIdCompleted] = payload;
    },
    [OrderMutation.setPickerId]: (state, payload) => {
      state[OrderState.pickerId] = payload;
    },
    [OrderMutation.setOrderDetail]: (state, payload) => {
      state[OrderState.orderDetail] = payload;
    },
    [OrderMutation.setOrderDetailAndSubjectId]: (state, payload) => {
      state[OrderState.orderDetailAndSubjectId] = payload;
    },
    [OrderMutation.setOrderInvalidAccess]: (state, payload) => {
      state[OrderState.orderInvalidAccess] = payload;
    },
    [OrderMutation.setOrderLineItem]: (state, payload) => {
      state[OrderState.orderLineItem] = payload;
    },
    [OrderMutation.setOrderLineItemNoteDialogVisible]: (state, payload) => {
      state[OrderState.orderLineItemNoteDialogVisible] = payload;
    },
    [OrderMutation.setLoading]: (state, payload) => {
      state[OrderState.loading] = payload;
    },
    [OrderMutation.setPickerOrdersGet]: (state, payload) => {
      state.pickerOrdersGet = payload;
    }
  },


  actions: {
    [OrderAction.setFilters]: async ({ commit }, filters) => {
      commit(OrderMutation.setFilters, { ...filters });
    },

    [OrderAction.setAssignOrder]: async ({ commit }, assignOrder) => {
      commit(OrderMutation.setAssignOrder, assignOrder);
    },

    [OrderAction.loadCustomers]: async ({ commit }) => {
      commit(OrderMutation.setCustomers, []);
    },

    [OrderAction.loadOrders]: async ({ commit }) => {
      try {
        commit(OrderMutation.setLoading, true);
        commit(OrderMutation.loadOrders, []);
        const orderClient = new AmsOrdersClient();
        const orders = await orderClient.getOrders();
        commit(OrderMutation.loadOrders, [...orders]);
      } catch (e) {
        console.log(e);
      } finally {
        commit(OrderMutation.setLoading, false);
      }
    },

    [OrderAction.loadOrdersPicker]: async ({ commit, state }) => {
      const client = new PickersClient();
      try {
        const { itemsPerPage, page, sortBy, sortDesc, sortCriteriaDefault } =
          state.pickerOrdersGet;
          const sortCriteria = [
            "priority|asc", "DateCompleted|desc"
          ];
        commit(OrderMutation.setLoading, true);
        const res = await client.getPickerOrders(
          state?.pickerId || 0,
          itemsPerPage,
          page - 1,
          null,
          [AmsOrderStatusEnum.InProgress, AmsOrderStatusEnum.NotStarted, AmsOrderStatusEnum.Paused],
          sortCriteria && sortCriteria.length > 0 ? sortCriteria : sortCriteriaDefault
        );
        commit(OrderMutation.setOrdersPicker, res.results!);
        commit(OrderMutation.setOrdersPickerCount, res.resultCount);
      } finally {
        commit(OrderMutation.setLoading, false);
      }
    },

    async [OrderAction.updatePickerOrdersGet](
      { commit, dispatch, state },
      { updates }
    ) {
      commit(OrderMutation.setPickerOrdersGet, {
        ...state.pickerOrdersGet,
        ...updates
      });
      await dispatch(OrderAction.loadOrdersPicker);
    },

    // eslint-disable-next-line no-empty-pattern
    [OrderAction.pauseOrder]: async ({}, body) => {
      const orderId = await new AmsOrdersClient().pauseOrder(body, body.orderId);
      return orderId;
    },

    [OrderAction.loadOrderDetail]: async ({ commit }, id) => {
      const order = await new AmsOrdersClient().getOrderById(id);
      commit(OrderMutation.setOrderDetail, order);
      return order;
    },

    // eslint-disable-next-line no-empty-pattern
    [OrderAction.cancelOrder]: async ({}, body) => {
      const orderId = await new AmsOrdersClient().cancelOrder(body, body.orderId);
      return orderId;
    },

    [OrderAction.loadOrderDetailAndSubjectId]: async ({ commit }, id) => {
      const order = await new AmsOrdersClient().getOrderByIdAndUserId(id);
      if (order == null || order?.id === undefined) {
        commit(OrderMutation.setOrderInvalidAccess, true);
        return order;
      }
      commit(OrderMutation.setOrderInvalidAccess, null);
      commit(OrderMutation.setOrderDetail, order);
      return order;
    },

    [OrderAction.unpauseOrder]: async (_, id) => {
      const orderId = await new AmsOrdersClient().unpauseOrder(id);
      return orderId;
    },

    [OrderAction.priorityOrderUp]: async (_, id) => {
      return await new AmsOrdersClient().priorityOrderUp(id);
    },

    [OrderAction.priorityOrderDown]: async (_, id) => {
      return await new AmsOrdersClient().priorityOrderDown(id);
    },

    [OrderAction.loadOrdersQc]: async ({ commit, state }) => {
      const { searchText, timeframe } = state.orderQCFilters!;
      const ordersQC = await new AmsOrdersClient().getOrdersQC(
        searchText,
        timeframe
      );
      commit(OrderMutation.setOrdersQC, ordersQC);
    },

    [OrderAction.setOrderQCFilters]: async ({ commit }, filters) => {
      const { searchText, timeframe } = filters.orderQCFilters!;
      const ordersQC = await new AmsOrdersClient().getOrdersQC(
        searchText,
        timeframe
      );
      commit(OrderMutation.setOrdersQC, ordersQC);

      commit(OrderMutation.setOrderQCFilters, filters);
    },

    [OrderAction.setPickerId]: ({ commit }, id) => {
      commit(OrderMutation.setPickerId, id);
    },

    [OrderAction.printOrder]: (_, id) => {
      const baseUrl = `${process.env.VUE_APP_API_BASE_URL}`;
      const url = `${baseUrl}/api/Reports/PickTicketReport/pdf?orderId=${id}`;
      window.open(url, '_blank');
    },

    [OrderAction.setLastOrderIdCompleted]: ({ commit }, id) => {
      commit(OrderMutation.setLastOrderIdCompleted, id);
    },

    [OrderAction.showOrderLineItemNoteDialog]({ commit }, lineItem) {
      commit(OrderMutation.setOrderLineItem, lineItem);
      commit(OrderMutation.setOrderLineItemNoteDialogVisible, !!lineItem);
    },
    async [OrderAction.editOrderLineItemNote]({ dispatch }, payload) {
      const client = new AmsOrderLineItemsClient();
      const input = new OrderLineItemEditNoteInput({
        note: payload.note
      });
      await client.editLineItemNote(payload.id, input);
      dispatch(OrderAction.loadOrders);
    },
    [OrderAction.reloadOrderFromOrders]: async function (
      { commit, state },
      payload
    ) {
      const { id } = payload;
      const client = new AmsOrdersClient();
      const order = await client.getOrderById(id);
      const orders = [...(state.orders || [])];
      const orderIndex = state.orders?.findIndex((o) => o.id === id);
      if (orderIndex !== undefined && orderIndex !== -1) {
        orders[orderIndex] = order;
        commit(OrderMutation.loadOrders, orders);
      }
    },
    [OrderAction.setLoading]: async ({ commit }, payload) => {
      commit(OrderMutation.setLoading, payload);
    }
  },


  getters: {
    [OrderGetter.orders]: (state) => {
      return state[OrderState.orders];
    },
    [OrderGetter.filters]: (state) => {
      return state[OrderState.filters];
    },
    [OrderGetter.assignOrder]: (state) => {
      return state[OrderState.assignOrder];
    },
    [OrderGetter.customers]: (state) => {
      return state[OrderState.customers];
    },
    [OrderGetter.ordersQC]: (state) => {
      return state[OrderState.ordersQC];
    },
    // [OrderGetter.ordersPicker]: (state) => {
    //   return state[OrderState.ordersPicker];
    // },
    [OrderGetter.orderQCFilters]: (state) => {
      return state[OrderState.orderQCFilters];
    },
    [OrderGetter.lastOrderIdCompleted]: (state) => {
      return state[OrderState.lastOrderIdCompleted];
    },
    [OrderGetter.pickerId]: (state) => {
      return state[OrderState.pickerId];
    },
    [OrderGetter.orderDetail]: (state) => {
      return state[OrderState.orderDetail];
    },
    [OrderGetter.orderDetailAndSubjectId]: (state) => {
      return state[OrderState.orderDetailAndSubjectId];
    },
    [OrderGetter.orderInvalidAccess]: (state) => {
      return state[OrderState.orderInvalidAccess];
    },
    [OrderGetter.orderLineItem]: (state) => {
      return state.orderLineItem;
    },
    [OrderGetter.orderLineItemNoteDialogVisible]: (state) => {
      return state.orderLineItemNoteDialogVisible;
    },
    [OrderGetter.ordersSearched]: (state) => {
      let orders = state.orders || [];
      const { searchText, status, statusAssignment } = state.filters;
      if (status) {
        orders = orders.filter((o) => o.orderStatusCode === status);
      }
      if (statusAssignment) {
        orders = orders.filter((o) => o.status === statusAssignment);
      }
      if (searchText) {
        orders = orders.filter((p) =>
          `${p.id}-${p.picker?.name}-${p.status}-${p.customer}-${moment(p.shipDate).format('MM/DD/YYYY')}`
            .toLowerCase()
            .includes(searchText.trim().toLowerCase())
        );
      }
      return orders;
    },
    [OrderGetter.loading]: (state) => {
      return state.loading;
    }
  }
};

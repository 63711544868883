import { render, staticRenderFns } from "./AssignOrder.vue?vue&type=template&id=a189f2cc&scoped=true&"
import script from "./AssignOrder.ts?vue&type=script&lang=ts&"
export * from "./AssignOrder.ts?vue&type=script&lang=ts&"
import style0 from "./AssignOrder.vue?vue&type=style&index=0&id=a189f2cc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a189f2cc",
  null
  
)

export default component.exports
import { TypedModule } from '@/store/utility/module-utility-types';
import { namespace } from 'vuex-class';
import { Module } from 'vuex';
import { NwdRootState } from '../nwd-web-root-state';

export type SnackbarColor =
  | 'info'
  | 'success'
  | 'error'
  | 'grey'
  | 'warning'
  | null
  | undefined;

export interface SnackbarMessage {
  message: string | null | undefined;
  timeout?: number | null | undefined;
  canBeClosed?: boolean | null | undefined;
  color?: SnackbarColor;
}
export const enum AlertState {
  snackbarMessage = 'snackbarMessage',
  snackbarHideTimeout = 'snackbarHideTimeout',
  spinnerStack = 'spinnerStack'
}
export interface AlertStateTypes {
  [AlertState.snackbarMessage]: SnackbarMessage | null;
  spinnerStack: number;
  snackbarHideTimeout: number | null;
}

export const enum AlertMutation {
  showSnackbar = 'showSnackbar',
  closeSnackbar = 'closeSnackbar',
  addToSpinnerStack = 'addToSpinnerStack'
}

interface AlertMutationPayloadTypes {
  [AlertMutation.showSnackbar]: SnackbarMessage;
  [AlertMutation.closeSnackbar]: never;
  [AlertMutation.addToSpinnerStack]: number;
}

export const enum AlertAction {
  showSnackbar = 'showSnackbar',
  closeSnackbar = 'closeSnackbar',
  pushSpinnerStack = 'pushSpinnerStack',
  popSpinnerStack = 'popSpinnerStack',
  showSuccess = 'showSuccess',
  showError = 'showError',
  showWarning = 'showWarning',
  showInfo = 'showInfo'
}

export interface AlertActions {
  showSnackbar: (payload: SnackbarMessage) => void;
  closeSnackbar: () => void;
  [AlertAction.pushSpinnerStack]: () => void;
  [AlertAction.popSpinnerStack]: () => void;
  [AlertAction.showSuccess]: (message: string) => void;
  [AlertAction.showError]: (message: string) => void;
  [AlertAction.showWarning]: (message: string) => void;
  [AlertAction.showInfo]: (message: string) => void;
}

export const enum AlertGetter {
  message = 'message',
  timeout = 'timeout',
  canBeClosed = 'canBeClosed',
  color = 'color',
  showSpinner = 'showSpinner'
}

export interface AlertGetterTypes {
  [AlertGetter.message]: string | null | undefined;
  [AlertGetter.timeout]: number | null | undefined;
  [AlertGetter.color]: SnackbarColor;
  [AlertGetter.canBeClosed]: boolean | null | undefined;
  [AlertGetter.showSpinner]: boolean;
}

export const ALERT_STATE_NAMESPACE = 'AlertState';
export const AlertNamespace = namespace(ALERT_STATE_NAMESPACE);

export type AlertModule = Override<
  Module<AlertStateTypes, NwdRootState>,
  TypedModule<
    AlertStateTypes,
    AlertMutationPayloadTypes,
    AlertActions,
    AlertGetterTypes,
    NwdRootState
  >
>;

import { UserViewModel } from '@/api/api';
import { orderStatusString } from '@/filters/order-status-filter';
import {
  ConfigurationAction,
  ConfigurationGetter,
  CONFIGURATION_STATE_NAMESPACE
} from '@/store/configuration/configuration.module.types';
import {
  OrderAction,
  ORDER_STATE_NAMESPACE
} from '@/store/order/order-module-types';
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  dispatchQcAction,
  QcAction,
  QcState,
  QC_NAMESPACE
} from './_state/qc-module-type';
import SkuScannerDialog from '../_components/scanner/SkuScannerDialog.vue';
import Component from 'vue-class-component';

const pagesHiddenHeader = [''];
const pagesIsNonPadding = ['OrdersDetailQC'];

interface QcDataType {
  headerStyle: { background: string };
  activeTab: string;
  user: any;
  isDisable: boolean;
  status?: number;
  scannerDialogVisible: boolean;

}
export default Vue.extend({
  name: 'QC',
  components: {
    SkuScannerDialog
  },
  data: (): QcDataType => ({
    headerStyle: {
      background: '#e0e0e0'
    },
    activeTab: `/`,
    user: undefined,
    isDisable: true,
    status: undefined,
    scannerDialogVisible: false
  }),

  filters: {
    orderStatusString
  },

  mounted: async function (): Promise<void> {
    if (this.configurations === undefined || this.configurations === null) await this.loadConfigurations();
  },

  computed: {
    isHiddenHeader: function (): boolean {
      return !pagesHiddenHeader.includes(this.$route.name as string);
    },
    isPaddingContent: function (): boolean {
      return !pagesIsNonPadding.includes(this.$route.name as string);
    },
    getContainerStyle: function (): any {
      if (!this.isPaddingContent) {
        return {
          padding: '0px'
        };
      }
      return {
        padding: '25px 20px'
      };
    },
    modalTitle: function (): string {
      const user = this.user as UserViewModel | undefined;
      return user === undefined ? '' : (user.name as string);
    },
    ...mapGetters(CONFIGURATION_STATE_NAMESPACE, [ConfigurationGetter.configurations]),
    ...mapState(QC_NAMESPACE, [QcState.lastPrintOrderId])
  },

  methods: {
    ...mapActions(CONFIGURATION_STATE_NAMESPACE, [ConfigurationAction.loadConfigurations]),
    ...mapActions(ORDER_STATE_NAMESPACE, [OrderAction.printOrder]),
    onChangeMenu(): void {
      const user = new UserViewModel({ name: 'Forest', userId: 123 });
      this.user = user as any;
    },
    onOnline(): void {
      this.onVisible();
      console.log('Online');
    },
    onPause(): void {
      this.onVisible();
      console.log('Pause');
    },
    onOffline(): void {
      this.onVisible();
      console.log('Offline');
    },
    onVisible(): void {
      this.isDisable = false;
    },
    setStatus(value: number): void {
      this.status = value;
    },
    toggleScannerDialog() {
      this.scannerDialogVisible = !this.scannerDialogVisible;
    }
  }
});

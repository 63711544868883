import {
  OrderItemViewModel,
  OrderLineItemViewModel,
  OrderViewModel
} from '@/api/api';
import { getDispatch } from '@/store/get-dispatch';
import { NwdRootState } from '@/store/nwd-web-root-state';
import { DispatchAction } from '@/store/utility/dispatch-action';
import { TypedModule } from '@/store/utility/module-utility-types';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { ISection } from '../_components/PickerOrderItemSection';

export const enum PickerState {
  orders = 'orders',
  order = 'order',
  orderInvalidAccess = 'orderInvalidAccess',
  orderLineItem = 'orderLineItem',
  nextOrderLineItem = 'nextOrderLineItem',
  confirmCompleteOrderDialog = 'confirmCompleteOrderDialog',
  orderLineItemSections = 'orderLineItemSections'
}

interface PickerStateTypes {
  [PickerState.orders]: OrderViewModel[];
  [PickerState.order]: OrderViewModel | undefined;
  [PickerState.orderInvalidAccess]: boolean;
  [PickerState.orderLineItem]: OrderLineItemViewModel | undefined;
  [PickerState.nextOrderLineItem]: OrderLineItemViewModel | undefined;
  [PickerState.confirmCompleteOrderDialog]: boolean;
  [PickerState.orderLineItemSections]: ISection[];
}

export const enum PickerMutation {
  setOrders = 'setOrders',
  setOrder = 'setOrder',
  setOrderInvalidAccess = 'setOrderInvalidAccess',
  setOrderLineItem = 'setOrderLineItem',
  setNextOrderLineItem = 'setNextOrderLineItem',
  setConfirmCompleteOrderDialog = 'setConfirmCompleteOrderDialog',
  setOrderLineItemSections = 'setOrderLineItemSections'
}

interface PickerMutationPayloads {
  [PickerMutation.setOrders]: OrderViewModel[];
  [PickerMutation.setOrder]: OrderViewModel | undefined;
  [PickerMutation.setOrderInvalidAccess]: boolean;
  [PickerMutation.setOrderLineItem]: OrderLineItemViewModel | undefined;
  [PickerMutation.setNextOrderLineItem]: OrderLineItemViewModel | undefined;
  [PickerMutation.setConfirmCompleteOrderDialog]: boolean;
  [PickerMutation.setOrderLineItemSections]: ISection[];
}

export const enum PickerAction {
  loadOrders = 'loadOrders',
  loadOrder = 'loadOrder',
  getOrderLineItem = 'getOrderLineItem',
  getNextOrderLineItem = 'getNextOrderLineItem',
  showConfirmCompleteOrderDialog = 'showConfirmCompleteOrderDialog',
  hideConfirmCompleteOrderDialog = 'hideConfirmCompleteOrderDialog',
  pickOrderLineItem = 'pickOrderLineItem',
  pickOrderLineItemAndFinalize = 'pickerOrderLineItemAndFinalize',
  updateOrderNumberOfPallets = 'updateOrderNumberOfPallets',
  printPalletTag = 'printPalletTag'
}

interface PickerActions {
  [PickerAction.loadOrders]: (payload: { userId: number }) => void;
  [PickerAction.loadOrder]: (payload: { orderId: number }) => OrderViewModel;
  [PickerAction.getOrderLineItem]: (payload: { orderLineItemId: number }) => OrderLineItemViewModel;
  [PickerAction.getNextOrderLineItem]: (payload: { orderId: number; seq: number }) => OrderLineItemViewModel;
  [PickerAction.showConfirmCompleteOrderDialog]: () => void;
  [PickerAction.hideConfirmCompleteOrderDialog]: () => void;
  [PickerAction.pickOrderLineItem]: (payload: { orderLineItemId: number }) => Promise<OrderItemViewModel>;
  [PickerAction.pickOrderLineItemAndFinalize]: (payload: { orderLineItemId: number }) => Promise<OrderItemViewModel>;
  [PickerAction.updateOrderNumberOfPallets]: (payload: { orderId: number; numberOfPallets: number }) => Promise<void>;
  [PickerAction.printPalletTag]: (payload: { orderId: number }) => void;
}

export const enum PickerGetter {
  order = 'order',
  orderInvalidAccess = 'orderInvalidAccess',
  orderLineItem = 'orderLineItem',
  nextOrderLineItem = 'nextOrderLineItem',
  confirmCompleteOrderDialog = 'confirmCompleteOrderDialog'
}

interface PickerGetterTypes {
  [PickerGetter.order]: OrderViewModel | undefined;
  [PickerGetter.orderInvalidAccess]: boolean;
  [PickerGetter.orderLineItem]: OrderLineItemViewModel | undefined;
  [PickerGetter.nextOrderLineItem]: OrderLineItemViewModel | undefined;
  [PickerGetter.confirmCompleteOrderDialog]: boolean;
}

export const PICKER_NAMESPACE = 'PickerState';
export const pickerNamespace = namespace(PICKER_NAMESPACE);
export const dispatchPickerAction: DispatchAction<PickerActions> =
  getDispatch(PICKER_NAMESPACE);

export type PickerModuleType = Override<
  Module<PickerStateTypes, NwdRootState>,
  TypedModule<
    PickerStateTypes,
    PickerMutationPayloads,
    PickerActions,
    PickerGetterTypes,
    NwdRootState
  >
>;

import '@/plugins/class-component-hooks';
import '@/utilities/auth/auth.fetch-intercept';
import Vue from 'vue';
import App from './App.vue';
import router from './router/AppRoutes';
import store from './store';
import vuetify from './plugins/vuetify';
import './design/_color.css';
import { dispatchAlertAction } from './store/alert/alert.dispatch';
import { AlertAction } from './store/alert/alert.module-types';

import '@/styles/vuetify-fixes.scss'
import '@/styles/contrast-improvements.scss'
import '@/styles/global.scss';

import Fragment from 'vue-fragment'
Vue.use(Fragment.Plugin)

console.log(
  '%c     _/                      _/         /            _/        _/             \n' +
    '    _/                  _/  _/        _/            _/        _/           \n' +
    '   _/_/_/    _/    _/      _/    _/_/_/    _/_/_/  _/_/_/    _/    _/_/    \n' +
    '  _/    _/  _/    _/  _/  _/  _/    _/  _/    _/  _/    _/  _/  _/_/_/_/   \n' +
    ' _/    _/  _/    _/  _/  _/  _/    _/  _/    _/  _/    _/  _/  _/          \n' +
    '_/_/_/      _/_/_/  _/  _/    _/_/_/    _/_/_/  _/_/_/    _/    _/_/_/     \n' +
    '                                                                           \n' +
    '                      C U S T O M   S O F T W A R E',
  'background: transparent; color: lime; padding: 0px; font-family: monospace; font-weight: bold;'
);

function onError(err: Error) {
  const res: {
    isSwaggerException?: boolean;
    message?: string;
    response?: string;
  } = { ...err };

  if (!res) {
    dispatchAlertAction(AlertAction.showError, 'An error has occurred');
    return;
  }

  if (res.isSwaggerException) {
    const response = JSON.parse(res.response!);
    if (response.message) {
      dispatchAlertAction(AlertAction.showError, response.message);
    }
  }
}

Vue.config.errorHandler = (err: Error) => {
  onError(err);
  throw err;
};
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');

import { ScanToPickUserStatusEnum, SystemPermission, ClockedTimeModel } from '@/api/api';

import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import PickerOrderList from '@/views/Picker/Orders/PickerOrderList.vue';
import { AlertAction, AlertNamespace, SnackbarMessage } from '@/store/alert/alert.module-types';

import { UserGetter, UserNamespace } from '@/store/user/user.module.types';

const MAX_SESSION_SEC = 60 * 60 * 99;

@Component({
  name: 'TimeStatsWidget'
})
export default class TimeStatsWidget extends Vue {
  readonly ScanToPickUserStatusEnum = ScanToPickUserStatusEnum;
  readonly SystemPermission = SystemPermission;

  @UserNamespace.Getter(UserGetter.currentStatus)
  readonly currentStatus!: ScanToPickUserStatusEnum;

  @UserNamespace.Getter(UserGetter.lastClockedTime)
  readonly lastClockedTime!: ClockedTimeModel;

  @AlertNamespace.Action(AlertAction.showSnackbar)
  readonly showSnackbar!: (payload: SnackbarMessage) => void;

  @Prop({ default: true })
  /** If enabled, this will update the displayed time on an interval. */
  readonly enabled!: boolean;

  @Watch('enabled', { immediate: true })
  onEnabledChanged(): void {
    clearInterval(this.updateTimeInterval);
    if (this.enabled) {
      this.updateTimes();
      this.updateTimeInterval = setInterval(() => this.updateTimes(), 1000);
    }
  }

  status: ScanToPickUserStatusEnum = ScanToPickUserStatusEnum.Offline;
  updateTimeInterval: any = null;

  totalTimeHours = 0;
  totalTimeMinutes = 0;
  totalTimeSeconds = 0;
  totalBreakTimeHours = 0;
  totalBreakTimeMinutes = 0;
  totalBreakTimeSeconds = 0;
  thisSessionTimeHours = 0;
  thisSessionTimeMinutes = 0;
  thisSessionTimeSeconds = 0;

  created(): void {
    if (!this.enabled) return;
    this.updateTimes();
    this.updateTimeInterval = setInterval(() => this.updateTimes(), 1000);
  }

  updateTimes() {
    if (!this.lastClockedTime || !this.lastClockedTime.startTime) return;

    const currTime = new Date().getTime();
    let totalSec = (currTime - this.lastClockedTime.startTime.getTime()) / 1000;
    let thisSessionStart = this.lastClockedTime.startTime.getTime();
    let totalBreak = 0;

    // sort the pause intervals by start time, just in case
    const pauseIntervals = this.lastClockedTime.pauseIntervals?.sort((a, b) => {
      if (a.startTime < b.startTime) return -1;
      if (a.startTime > b.startTime) return 1;
      return 0;
    });

    if (pauseIntervals?.length > 0) {
      for (const interval of pauseIntervals) {
        if (!interval.endTime) {
          // if a pause interval is running, that is the current session
          // an interval without an endtime must always be the last interval
          // otherwise the whole system is broken
          thisSessionStart = interval.startTime.getTime();
          totalBreak += (currTime - interval.startTime.getTime()) / 1000;
        } else {
          thisSessionStart = interval.endTime.getTime();
          totalBreak += (interval.endTime.getTime() - interval.startTime.getTime()) / 1000;
        }
      }

      totalSec -= totalBreak;
    }

    this.totalBreakTimeHours = Math.floor(totalBreak / 3600);
    this.totalBreakTimeMinutes = Math.floor((totalBreak % 3600) / 60);
    this.totalBreakTimeSeconds = Math.floor(totalBreak % 60);

    const thisSessionSec = Math.min((currTime - thisSessionStart) / 1000, MAX_SESSION_SEC);
    this.thisSessionTimeHours = Math.floor(thisSessionSec / 3600);
    this.thisSessionTimeMinutes = Math.floor((thisSessionSec % 3600) / 60);
    this.thisSessionTimeSeconds = Math.floor(thisSessionSec % 60);

    this.totalTimeHours = Math.floor(totalSec / 3600);
    this.totalTimeMinutes = Math.floor((totalSec % 3600) / 60);
    this.totalTimeSeconds = Math.floor(totalSec % 60);
  }
}

import moment from 'moment';

export function date(value: Date | string): string {
  if (!value) {
    return '';
  }
  return moment(value).format('MM/DD/yyyy');
}

export function timeFilter(value: Date | undefined): string {
  if (!value) {
    return '';
  }
  return moment(value).format('hh:mm A');
}

export function dateTimeFilter(value: Date | undefined): string {
  if (!value) {
    return '';
  }
  return moment(value).format('MM/DD/yyyy hh:mm A');
}

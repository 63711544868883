import { AmsOrderStatusEnum, ScanToPickUserStatusEnum } from '@/api/api';

export const STATUS_PICKED = 1;
export const STATUS_UNPICKED = 0;

type Status = {
  [key: number]: string;
};

export const STATUS_PROGRESS_ORDER_STRING: Status = {
  [AmsOrderStatusEnum.NotStarted]: 'Not Started',
  [AmsOrderStatusEnum.InProgress]: 'In Progress',
  [AmsOrderStatusEnum.Paused]: 'Paused',
  [AmsOrderStatusEnum.Completed]: 'Completed',
  [AmsOrderStatusEnum.Returned]: 'Returned',
  [AmsOrderStatusEnum.Cancelled]: 'Cancelled',
  [AmsOrderStatusEnum.AwaitingQc]: 'Awaiting QC',
  [AmsOrderStatusEnum.QcInProgress]: 'QC In Progress'
};

export const STATUS_PICKER_STRING: Status = {
  [ScanToPickUserStatusEnum.Online]: 'Online',
  [ScanToPickUserStatusEnum.Paused]: 'Paused',
  [ScanToPickUserStatusEnum.Offline]: 'Offline'
};

export const STATUS_ORDER_ASSIGNMENT = {
  assigned: 'Assigned',
  unassigned: 'Unassigned'
};

export const LOCAL_STORAGE_CREDENTIAL = 'credential';

import {
  OrderItemViewModel,
  OrderLineItemAndReasonInput,
  AmsOrderLineItemsClient,
  OrderLineItemViewModel,
  OrderViewModel,
  AmsOrderStatusEnum,
  WarehousePrinterType,
  PickersClient,
  PrinterLocationDTO
} from '@/api/api';
import NwdLocations from '@/router/nwd-router';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';
import { DiscardItemDialogType } from '@/views/_components/DiscardItemDialog';
import DiscardItemDialog from '@/views/_components/DiscardItemDialog.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import QcCompleteOrderDialog from '../_components/QcCompleteOrderDialog.vue';
import QcCheckItemDialog from '../_components/QcCheckItemDialog.vue';
import { ISection } from '../_components/QcOrderItemSection';
import QcOrderItemSection from '../_components/QcOrderItemSection.vue';
import { ConvertLineItemToSections } from '../_state/qc-module';
import { dispatchQcAction, QcAction, QcGetter, qcNamespace } from '../_state/qc-module-type';
import { PickerAction, dispatchPickerAction } from '@/views/Picker/_state/picker-module-type';
import PrintDialog from '@/components/PrintDialog.vue';
import { ReportTypeEnum } from '@/components/report-type-enum';
import { GetCaseBottleText, GetProductionDateText } from '@/utilities/lane-utils';

@Component({
  components: {
    QcOrderItemSection,
    DiscardItemDialog,
    QcCompleteOrderDialog,
    QcCheckItemDialog,
    PrintDialog
  }
})
export default class QcOrderDetail extends Vue {
  public readonly WarehousePrinterType = WarehousePrinterType;
  public readonly ReportTypeEnum = ReportTypeEnum;
  readonly GetCaseBottleText = GetCaseBottleText;
  readonly GetProductionDateText = GetProductionDateText;
  $refs!: Vue['$refs'] & {
    discardItemDialog: DiscardItemDialogType;
  };

  @qcNamespace.Getter(QcGetter.order)
  readonly order!: OrderViewModel;
  @qcNamespace.Getter(QcGetter.orderLineItem)
  readonly orderLineItem!: OrderLineItemViewModel;
  @qcNamespace.Getter(QcGetter.previousOrderLineItem)
  readonly previousOrderLineItem!: OrderItemViewModel;
  @qcNamespace.Getter(QcGetter.nextOrderLineItem)
  readonly nextOrderLineItem!: OrderItemViewModel;
  @qcNamespace.Getter(QcGetter.orderLineItemSections)
  readonly sections!: ISection[];

  checkDiscardChanges = true;
  goToLineItemId = 0;

  printerType = WarehousePrinterType.None;
  reportType = ReportTypeEnum.PickTicket;
  isPrintDialogVisible = false;

  /**
   * The line items sorted by id. This is an index of essentially the order of the line items.
   * This should be the order in which the picker picked the items. We don't use the 'itemNo' because in rare cases it seems there can be duplicates.
   */
  get sortedLineItems(): OrderItemViewModel[] {
    const newArrayRef = [...(this.order.orderItems ?? [])];
    newArrayRef.sort((a, b) => a.id - b.id);
    return newArrayRef;
  }

  get sortedLineItemsForDropdown(): OrderItemViewModel[] {
    const newArrayRef = [...(this.order.orderItems ?? [])];
    // first sort by valid, then by id. Valid items go to the bottom
    newArrayRef.sort((a, b) => {
      if (a.isValid && !b.isValid) {
        return 1;
      }
      if (!a.isValid && b.isValid) {
        return -1;
      }
      return a.id - b.id;
    });

    return newArrayRef;
  }

  get renderedSections(): ISection[] {
    if (this.orderLineItem) {
      return this.sections.filter(
        (s) => s.key != null && this.orderLineItem[s.key] != null && this.orderLineItem[s.key] != ''
      );
    }

    return [];
  }

  get progress(): number {
    if (!this.order) {
      return 0;
    }
    const done = this.order.orderItems?.filter((li) => li.isValid)?.length ?? 0;
    return (done / this.order.lineItemCount) * 100;
  }

  get lineItemIndex(): number {
    const idx = this.sortedLineItems?.findIndex((li) => li.id === this.orderLineItem.id);
    return idx !== undefined ? idx + 1 : this.orderLineItem.itemNo;
  }

  get backToQC(): RawLocation {
    return {
      name: NwdLocations.qc.orders.name
    };
  }

  get isReadyToComplete(): boolean {
    const allConfirmed = this.renderedSections.every((s) => s.isConfirm === true);
    const anyDenied = this.renderedSections.findIndex((s) => s.isConfirm === false && !!s.reasonForFailed) !== -1;

    return allConfirmed || anyDenied;
  }

  get isUnchanged(): boolean {
    return this.renderedSections.every((s) => s.isConfirm === undefined);
  }

  get isAnyDenySection(): boolean {
    return !!this.renderedSections.find((s) => s.isConfirm === false);
  }

  get isOrderAwaitingOrInProgress(): boolean {
    return (
      this.order.orderStatusCode === AmsOrderStatusEnum.AwaitingQc ||
      this.order.orderStatusCode === AmsOrderStatusEnum.QcInProgress
    );
  }

  get isAnyChanges(): boolean {
    const lineItemSections = ConvertLineItemToSections(this.orderLineItem);
    const initial = lineItemSections.map((s) => `${s.key}${s.isConfirm}`).join('');
    const values = this.renderedSections.map((s) => `${s.key}${s.isConfirm}`).join('');
    return initial !== values;
  }

  get isOrderCompleted(): boolean {
    return this.order.orderStatusCode === AmsOrderStatusEnum.Completed;
  }

  // async beforeRouteLeave(
  //   _to: Route,
  //   _from: Route,
  //   next: NavigationGuardNext
  // ): Promise<void> {
  //   if (this.checkDiscardChanges && this.isAnyChanges) {
  //     this.$refs.discardItemDialog.show(next);
  //     return;
  //   }

  //   this.checkDiscardChanges = true;
  //   next();
  // }

  // the user wants to be at the top of the page when the order line item changes
  // they need to enter the SKU number into the box up there before they can do anything
  @Watch('orderLineItem', { immediate: true })
  async onOrderLineItemChanged(): Promise<void> {
    window.scrollTo(0, 0);
  }

  public async mounted(): Promise<void> {
    // console.log('OrderDetail mounted', this.order, this.orderLineItem);
  }

  public async complete(goToItemId?: number): Promise<void> {
    if (!this.isReadyToComplete) {
      return;
    }

    if (this.isAnyDenySection) {
      await this.sendBackToPicker();
      return;
    }

    if (!this.nextOrderLineItem && !goToItemId) {
      dispatchQcAction(QcAction.showCompleteOrderDialog);
      return;
    }

    if (!this.orderLineItem.isValid) {
      await this.completeItem();
    }

    if (goToItemId) {
      await this.goToItem(goToItemId);
      return;
    }

    if (this.nextOrderLineItem) {
      await this.goToNextItem();
      return;
    }

    // prevent check discard changes
    this.checkDiscardChanges = false;
    this.$router.push(this.backToQC);
  }

  public async goToNextItem(): Promise<void> {
    await dispatchQcAction(QcAction.goNextOrderLineItem);
  }

  public async goToBackItem(): Promise<void> {
    await dispatchQcAction(QcAction.goBackOrderLineItem);
  }

  public async goToItem(itemId: number, skipValidation = false): Promise<void> {
    this.goToLineItemId = itemId;

    if (this.orderLineItem.isValid || skipValidation || this.isUnchanged) {
      dispatchQcAction(QcAction.hideCheckItemDialog);
      await dispatchQcAction(QcAction.goToOrderLineItem, { orderLineItemId: this.goToLineItemId });
    } else {
      dispatchQcAction(QcAction.showCheckItemDialog);
    }
  }

  public printPickTicket(orderId: number): void {
    dispatchQcAction(QcAction.printPickTicket, {
      orderId: orderId
    });
  }

  public printPalletTag(orderId: number): void {
    dispatchPickerAction(PickerAction.printPalletTag, {
      orderId: orderId
    });
  }

  public async completeItemAndGoBackToQC(targetLocationCode: string): Promise<void> {
    await this.completeItem();

    // for whatever reason, this get's BROKEN. Idk why it's returning the full object...
    if ((targetLocationCode as any)?.printerStatus != null) {
      targetLocationCode = (targetLocationCode as unknown as PrinterLocationDTO).locationCode ?? '';
    }

    // this.printPickTicket(this.order.id);
    if (targetLocationCode != null && targetLocationCode !== '') {
      console.log('Printing pick ticket for order', this.order.id, 'to location', targetLocationCode);
      const pickersClient = new PickersClient();
      pickersClient.queuePickTicketPrintJob(this.order.id, targetLocationCode);
    }

    await dispatchQcAction(QcAction.getOrders);

    // prevent check discard changes
    this.checkDiscardChanges = false;
    this.$router.push(this.backToQC);
  }

  private async completeItem(): Promise<void> {
    const client = new AmsOrderLineItemsClient();
    const orderItem = await client.valid(this.orderLineItem.id);
    dispatchQcAction(QcAction.updateOrderLineItem, { orderLineItem: orderItem });
    await dispatchQcAction(QcAction.getOrderLineItem, { orderLineItemId: this.orderLineItem.id });
    dispatchAlertAction(AlertAction.showSuccess, 'Item completed.');
  }

  private async sendBackToPicker(): Promise<void> {
    const input = this.sections
      .filter((s) => s.isConfirm === false)
      .map(
        (s) =>
          ({
            orderLineItemId: this.orderLineItem.id,
            reasonCode: s.reasonCode,
            reasonNote: s.reasonForFailed
          } as OrderLineItemAndReasonInput)
      );

    const client = new AmsOrderLineItemsClient();
    try {
      await client.inValid(this.orderLineItem.id, input);
      dispatchAlertAction(AlertAction.showSuccess, 'Order sent back to clerk.');

      // prevent check discard changes
      this.checkDiscardChanges = false;
      this.$router.push(this.backToQC);
    } catch (error) {
      dispatchAlertAction(AlertAction.showError, 'Failed to send back to clerk. See console for more details.');
      console.error(error);
    }
  }
}

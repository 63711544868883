export function deepEqual(x: any, y: any): boolean {
  const ok = Object.keys,
    tx = typeof x,
    ty = typeof y;
  return x && y && tx === 'object' && tx === ty
    ? ok(x).length === ok(y).length &&
        ok(x).every((key) => deepEqual(x[key], y[key]))
    : x === y;
}

export function isIosSafari() {
  const userAgent = window.navigator.userAgent.toLowerCase();

  // Check for iOS
  const isIos = /iphone|ipad|ipod/.test(userAgent);
  // Check for Safari
  const isSafari = /safari/.test(userAgent) && !/chrome/.test(userAgent);

  return isIos && isSafari;
}

import {
  IPickerListOutput,
  PickerListOutput,
  ScanToPickUserStatusEnum
} from '@/api/api';
import { timeFilter } from '@/filters/date.filter';
import { Component, Provide, Vue, Watch } from 'vue-property-decorator';
import {
  ClerkAction,
  clerkNamespace,
  ClerkState,
  dispatchClerkAction,
  PickersGet
} from '../_state/clerk-module-type';

// Components
import PickerCard from '../_components/picker-list/PickerCard.vue';
import PickerListFilter from '../_components/picker-list/PickerListFilter.vue';

@Component({
  components: {
    PickerCard,
    PickerListFilter
  }
})
export default class Pickers extends Vue {
  @clerkNamespace.State(ClerkState.pickers)
  pickers!: PickerListOutput[];

  loading = false;
  options: PickersGet = {
    pickerStatus: ScanToPickUserStatusEnum.All,
    pageNumber: 0,
    pageSize: -1,
    sortCriteria: ['statusCode|asc']
  };

  async mounted(): Promise<void> {
    this.onOptionsChanges(this.options);
  }

  get npickers(): (IPickerListOutput & { showOrders: boolean; timeFilter: string })[] {
    return this.pickers.map((p) => ({
      ...p,
      showOrders: p.statusCode !== ScanToPickUserStatusEnum.Offline,
      // will show the start time if the picker is online/paused, otherwise will show the end time
      timeFilter: timeFilter(p.lastClockedTime?.endTime ? p.lastClockedTime?.endTime : p.lastClockedTime?.startTime),
      isLazy: false
    }));
  }

  @Watch('options')
  async onOptionsChanges(input: PickersGet): Promise<void> {
    try {
      this.loading = true;
      await dispatchClerkAction(ClerkAction.getPickers, {
        input: input
      });
    } finally {
      this.loading = false;
    }
  }

  @Provide('updatePickersGet')
  private updatePickersGet(updates: Partial<PickersGet>) {
    this.options = {
      ...this.options,
      ...updates
    };
  }
}



import { Component, Prop, Vue, Watch, Model } from 'vue-property-decorator';
import { OrderViewModel, CancelOrderInput } from '@/api/api';
import { AmsOrderStatusEnum } from '@/api/api';
import { dispatchOrderAction } from '@/store/order/order-dispatch';
import { OrderAction } from '@/store/order/order-module-types';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';

@Component
export default class CancelOrderDialog extends Vue {
  public readonly AmsOrderStatusEnum = AmsOrderStatusEnum;
  public readonly requireRule = (value: string | number): boolean | string => {
    return !!value || 'Please enter a reason for cancellation.';
  }

  @Model('update:isVisible', { type: Boolean, default: false }) localIsVisible!: boolean;
  @Prop() order!: OrderViewModel;

  @Watch('localIsVisible')
  onIsVisibleChanged(value: boolean) {
    this.$emit('update:isVisible', value);
  }

  public isLoading = false;

  async onCancelOrder() {
    // TODO: validate cancel form
    if (this.$refs.cancelForm && (this.$refs.cancelForm as any).validate()) {
      this.isLoading = true;

      const body = new CancelOrderInput( {
        orderId: this.order.id,
        reason: this.order.returnNote,
      });
      try {
        const orderId = await dispatchOrderAction(OrderAction.cancelOrder, body);
        if (orderId) {
          this.localIsVisible = false;
          // emit onCancel event
          this.$emit('onCancel', orderId);

          dispatchAlertAction(
            AlertAction.showSuccess,
            `AMS Order #${orderId} Cancelled.`
          );
        }
      } catch (e) {
        dispatchAlertAction(AlertAction.showError, `Cancel Order failed.`);
      } finally {
        this.isLoading = false;
      }
    }
  }

}



import moment from 'moment-timezone';

export const getFormatStringHour = (date: Date, timezone: string): string => {
  if (!date) return '';
  const momentDate = moment(date).tz(timezone);
  return momentDate?.format('h:mmA');
};

export const getFormatStringDate = (date: Date, timezone: string): string => {
  if (!date) return '';
  const momentDate = moment(date).tz(timezone);
  return momentDate?.format('M/D/YYYY');
};


import { AdminClient, WarehouseLocationModel, WarehousePrinterModel, WarehousePrinterType } from '@/api/api';
import NwdLocations from '@/router/nwd-router';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';
import { Component, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';

@Component({})
export default class AdminPrinters extends Vue {
  public readonly WarehousePrinterType = WarehousePrinterType;
  public readonly NwdLocations = NwdLocations;
  public readonly moment = moment;

  public isLoadingPrinters = false;
  public isLoadingWarehouseLocations = false;
  public isSavingWarehouseLocations = false;
  public printers: WarehousePrinterModel[] = [];
  public warehouseLocations: WarehouseLocationModel[] = [];
  public headers = [
    { text: 'Printer Name', value: 'name' },
    { text: 'Is Online', value: 'isOnline' },
    { text: 'Status', value: 'status' },
    { text: 'Printer Type', value: 'printerType'},
    { text: 'Location/s', value: 'locations' },
    { text: 'Host Computer', value: 'warehousePrinterClient.machineName' },
    { text: 'Last Updated', value: 'dateModified' },
    { text: '', value: 'actions'}
  ];

  public printerTypes = [
    { type: WarehousePrinterType.None, label: 'None' },
    { type: WarehousePrinterType.Label, label: 'Label' },
    { type: WarehousePrinterType.Standard, label: 'Standard' }
  ];

  public dialog = false;
  public editedItem: WarehousePrinterModel | null = null;
  public newWarehouseLocationId: number | null = null;

  async mounted(): Promise<void> {
    this.reloadPrinters();
  }

  // super inefficient
  public isLocationSelected(warehouseLocationId: number): boolean {
    const result = this.editedItem?.warehouseLocations?.some((location) => location.warehouseLocationId === warehouseLocationId) ?? false;
    return result;
  }

  clearEditedItemTimeout: any = null;
  @Watch('dialog')
  public onDialogChange() {
    if (!this.dialog) {
      this.clearEditedItemTimeout = setTimeout(() => {
        this.editedItem = null;
      }, 500);

    } else {
      clearTimeout(this.clearEditedItemTimeout);
      if (this.editedItem) {
        this.reloadWarehouseLocations(this.editedItem.printerType);
      }
    }
  }

  @Watch('editedItem.printerType')
  public onPrinterTypeChange() {
    if (this.editedItem) {
      if (this.editedItem.printerType === WarehousePrinterType.None) {
        this.editedItem.warehouseLocations = [];
      } else {
        this.reloadWarehouseLocations(this.editedItem.printerType);
      }
    }
  }

  public async reloadWarehouseLocations(printerType: WarehousePrinterType) {
    const adminClient = new AdminClient();
    try {
      this.isLoadingWarehouseLocations = true;
      this.warehouseLocations = await adminClient.getUnownedWarehouseLocations(printerType);
      this.warehouseLocations = [
        ...this.editedItem?.warehouseLocations ?? [],
        ...this.warehouseLocations,
      ];
    } catch (error) {
      console.error(error);
      dispatchAlertAction(AlertAction.showError, 'Failed to load warehouse locations');

    } finally {
      this.isLoadingWarehouseLocations = false;
    }
  }

  public async reloadPrinters() {
    const adminClient = new AdminClient();
    try {
      this.isLoadingPrinters = true;
      this.printers = await adminClient.getPrinters();
    } catch (error) {
      console.error(error);
      dispatchAlertAction(AlertAction.showError, 'Failed to load printers');

    } finally {
      this.isLoadingPrinters = false;
    }
  }

  public async updatePrinterLocation() {
    if (!this.editedItem) {
      return;
    }

    const adminClient = new AdminClient();
    try {
      this.isSavingWarehouseLocations = true;
      await adminClient.updateWarehousePrinter(this.editedItem);
      this.dialog = false;
      this.reloadPrinters();
      dispatchAlertAction(AlertAction.showSuccess, 'Printer locations updated');

    } catch (error) {
      console.error(error);
      dispatchAlertAction(AlertAction.showError, 'Failed to update printer locations');

    } finally {
      this.isSavingWarehouseLocations = false;
    }
  }
}

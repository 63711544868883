import {
  AmsSyncResultViewModel,
  Timeframe,
  AmsSyncResultSource
} from '@/api/api';
import Vue from 'vue';
import Component from 'vue-class-component';
import {
  AdminAction,
  AdminGetter,
  adminNamespace,
  dispatchAdminAction
} from './_state/admin-module-type';
import { dateTimeFilter } from '@/filters/date.filter';

@Component({
  name: 'AmsSyncResultsList',
  components: {},
  filters: {
    dateTimeFilter
  }
})
export default class AmsSyncResultsList extends Vue {
  headers = [
    // { text: 'AMS SyncResult Id', value: 'amsSyncResultId', sortable: false },
    { text: 'Source', value: 'syncSource', sortable: false },
    { text: 'Pass/Fail', value: 'passed', sortable: false },
    { text: 'Message', value: 'resultMessage', sortable: false, width: '60%' },
    { text: 'Created On', value: 'dateCreated', sortable: false }
  ];

  @adminNamespace.Getter(AdminGetter.results)
  results!: AmsSyncResultViewModel[];

  getSourceEnumString(id: number) {
    switch (id) {
      case AmsSyncResultSource.AmsApiSync:
        return 'Orders Sync';
      case AmsSyncResultSource.ProductsSync:
        return 'Product Sync';
      case AmsSyncResultSource.StorageCustomerSync:
        return 'Storage Customer Sync';
      default:
        return 'None';
    }
  }

  getPassFailString(passFail: boolean) {
    return passFail == true ? 'Passed' : 'Failed';
  }

  getPassFailColor(passFail: boolean) {
    return passFail == true ? '#47a44b' : '#d1392d';
  }

  isLoading = false;
  singleExpand = false;
  expanded: any[] = [];

  search = '';
  footerProps = {
    itemsPerPageOptions: [10, 25, 50, 100, -1]
  };

  options: {
    page: number;
    itemsPerPage: number;
    sortBy: string[];
    sortDesc: boolean[];
    groupBy: string[];
    groupDesc: boolean[];
    multiSort: boolean;
    mustSort: boolean;
  } = {
    page: 1,
    itemsPerPage: 10,
    sortBy: [],
    sortDesc: [],
    groupBy: [],
    groupDesc: [],
    multiSort: true,
    mustSort: false
  };

  async mounted(): Promise<void> {
    await this.loadSyncResults();
  }

  async loadSyncResults(): Promise<void> {
    this.isLoading = true;
    try {
      await dispatchAdminAction(AdminAction.loadResults);
      this.results = this.results.reverse();
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }
}

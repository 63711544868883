
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { SkuScanReportViewModel, SkuScanStatusEnum } from '@/api/api';

@Component
export default class QuaggaWrapper extends Vue {
  public readonly SkuScanStatusEnum = SkuScanStatusEnum;

  /** Whether or not the Quagga instance is actively reading. */
  @Prop({ default: null }) readonly scanReport!: SkuScanReportViewModel | null;
}

import { Component, Vue } from 'vue-property-decorator';
import { NavigationGuardNext } from 'vue-router';

export type DiscardItemDialogType = Vue & {
  show: (next: NavigationGuardNext) => Promise<void> | void;
};

@Component({})
export default class DiscardItemDialog extends Vue {
  dialog = false;
  next!: NavigationGuardNext;

  show(next: NavigationGuardNext): void {
    this.next = next;
    this.dialog = true;
  }

  async confirm(): Promise<void> {
    await Promise.resolve(this.next());
    this.dialog = false;
  }
}

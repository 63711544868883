import { AuthResultViewModel } from '@/api/api';
import { LOCAL_STORAGE_CREDENTIAL } from '@/shared/constants/common';
import moment from 'moment';

const cutoffDate = moment('2024-08-21');
const resetCredsOnceKey = 'resetCredsOnce';

export const getLocalCredential = (): AuthResultViewModel | null => {
  try {
    // there is a bug with the old creds from before the Excellyr migration.
    // this clears out the old creds once until the cutoff date is hit
    if (moment().isBefore(cutoffDate)) {
      const resetCredsOnce = localStorage.getItem(resetCredsOnceKey);
      if (!resetCredsOnce || resetCredsOnce !== 'true') {
        localStorage.removeItem(LOCAL_STORAGE_CREDENTIAL);
        localStorage.setItem(resetCredsOnceKey, 'true');
      }
    }

    const credentialString = localStorage.getItem(LOCAL_STORAGE_CREDENTIAL);
    if (!credentialString) return null;
    const localCredential = JSON.parse(credentialString);

    return localCredential;
  } catch (e) {
    return null;
  }
};

export const clearLocalCredential = () => {
  localStorage.removeItem(LOCAL_STORAGE_CREDENTIAL);
};

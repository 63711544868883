import Vue from 'vue';
import Vuex from 'vuex';
import { ORDER_STATE_NAMESPACE } from './order/order-module-types';
import { orderModule } from './order/order.module';
import { PICKER_CARD_STATE_NAMESPACE } from '@/store/pickerCard/pickerCard-module-types';
import { pickerCardModule } from './pickerCard/pickerCard.module';
import { ALERT_STATE_NAMESPACE } from './alert/alert.module-types';
import { CONFIGURATION_STATE_NAMESPACE } from './configuration/configuration.module.types';
import { configurationModule } from './configuration/configuration.module';
import { alertModule } from './alert/alert.module';
import { AUTH_STATE_NAMESPACE } from './auth/auth.module.types';
import { authModule } from './auth/auth.module';
import { PICKER_NAMESPACE } from '@/views/Picker/_state/picker-module-type';
import { pickerModule } from '@/views/Picker/_state/picker-module';
import { QC_NAMESPACE } from '@/views/QC/_state/qc-module-type';
import { qcModule } from '@/views/QC/_state/qc-module';
import { CLERK_NAMESPACE } from '@/views/Clerk/_state/clerk-module-type';
import clerkModule from '@/views/Clerk/_state/clerk-module';

import { USER_STATE_NAMESPACE } from './user/user.module.types';
import { userModule } from './user/user.module';
import { ADMIN_NAMESPACE } from '@/views/Admin/_state/admin-module-type';
import { adminModule } from '@/views/Admin/_state/admin-module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    agreedToPrivacy: false
  },
  mutations: {},
  actions: {},
  modules: {
    [ALERT_STATE_NAMESPACE]: alertModule,
    [CONFIGURATION_STATE_NAMESPACE]: configurationModule,
    [AUTH_STATE_NAMESPACE]: authModule,
    [USER_STATE_NAMESPACE]: userModule,

    [ORDER_STATE_NAMESPACE]: orderModule,
    [PICKER_CARD_STATE_NAMESPACE]: pickerCardModule,
    [PICKER_NAMESPACE]: pickerModule,
    [QC_NAMESPACE]: qcModule,
    [CLERK_NAMESPACE]: clerkModule,
    [ADMIN_NAMESPACE]: adminModule,
  }
});


import { ScanToPickUserStatusEnum } from '@/api/api';

import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { AlertAction, AlertNamespace, SnackbarMessage } from '@/store/alert/alert.module-types';

import PermissionChecker from '@/views/_components/PermissionChecker.vue';
import { UserGetter, UserNamespace } from '@/store/user/user.module.types';

@Component({
  name: 'UserClockWidget',
  components: {
    PermissionChecker
  }
})
export default class UserClockWidget extends Vue {
  readonly ScanToPickUserStatusEnum = ScanToPickUserStatusEnum;

  @AlertNamespace.Action(AlertAction.showSnackbar)
  readonly showSnackbar!: (payload: SnackbarMessage) => void;

  @Prop({ default: null }) readonly value!: ScanToPickUserStatusEnum | null;

  @UserNamespace.Getter(UserGetter.currentStatus)
  readonly currentStatus!: ScanToPickUserStatusEnum;

  status: ScanToPickUserStatusEnum | null = null;
  isLoadingUserStatus = false;
  isSavingUserStatus = false;

  @Watch('value', { immediate: true })
  onValueChanged(): void {
    this.status = this.value;
  }

  @Emit('input')
  emitInput(newValue: ScanToPickUserStatusEnum) {
    return newValue;
  }

  setStatus(value: ScanToPickUserStatusEnum): void {
    this.status = value;
    this.emitInput(value);
  }
}



import { DataTableOptions } from '@/@types/data-table-options';
import { CancelOrderInput, OrderListOutput, OrderViewModel } from '@/api/api';
import { TypedModule } from '@/store/utility/module-utility-types';
import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { NwdRootState } from '../nwd-web-root-state';
import {
  FileResponse,
  OrderLineItemViewModel,
  OrderQCViewModel,
  AmsOrderStatusEnum,
  PauseOrderInput
} from './../../api/api';

export interface PickerOrdersGet extends DataTableOptions {
  searchText?: string;
  sortCriteriaDefault?: string[];
}

export const enum OrderState {
  orders = 'orders',
  filters = 'filters',
  assignOrder = 'assignOrder',
  customers = 'customers',
  ordersQC = 'ordersQC',
  ordersPicker = 'ordersPicker',
  ordersPickerCount = 'ordersPickerCount',
  orderQCFilters = 'orderQCFilters',
  lastOrderIdCompleted = 'lastOrderIdCompleted',
  pickerId = 'pickerId',
  orderDetail = 'orderDetail',
  orderDetailAndSubjectId = 'orderDetailAndSubjectId',
  orderInvalidAccess = 'orderInvalidAccess',
  orderLineItem = 'orderLineItem',
  orderLineItemNoteDialogVisible = 'orderLineItemNoteDialogVisible',
  loading = 'loading',
  pickerOrdersGet = 'pickerOrdersGet'
}

export interface OrderStateTypes {
  orders: OrderViewModel[] | null;
  filters: {
    status?: AmsOrderStatusEnum;
    statusAssignment?: string;
    searchText?: string;
  };
  assignOrder: any | null;
  customers: any[] | null;
  ordersQC: OrderQCViewModel[] | null;
  ordersPicker: OrderListOutput[];
  ordersPickerCount: number;
  orderQCFilters: any | null;
  lastOrderIdCompleted: number | null;
  pickerId: number | null;
  orderDetail: OrderViewModel | null;
  orderDetailAndSubjectId: OrderViewModel | null;
  orderInvalidAccess: boolean | null;
  orderLineItem: OrderLineItemViewModel | null;
  orderLineItemNoteDialogVisible: boolean;
  loading: boolean;
  pickerOrdersGet: PickerOrdersGet;
}

export const enum OrderMutation {
  setOrders = 'setOrders',
  setFilters = 'setFilters',
  setAssignOrder = 'setAssignOrder',
  setCustomers = 'setCustomers',
  loadOrders = 'loadOrders',
  loadOrdersPicker = 'loadOrdersPicker',
  setOrdersQC = 'setOrdersQC',
  setOrdersPicker = 'setOrdersPicker',
  setOrdersPickerCount = 'setOrdersPickerCount',
  setOrderQCFilters = 'setOrderQCFilters',
  setPickerId = 'setPickerId',
  setLastOrderIdCompleted = 'setLastOrderIdCompleted',
  setOrderDetail = 'setOrderDetail',
  setOrderDetailAndSubjectId = 'setOrderDetailAndSubjectId',
  setOrderInvalidAccess = 'setOrderInvalidAccess',
  setOrderLineItem = 'setOrderLineItem',
  setOrderLineItemNoteDialogVisible = 'setOrderLineItemNoteDialogVisible',
  setLoading = 'setLoading',
  setPickerOrdersGet = 'setPickerOrdersGet'
}

interface OrderMutationPayloadTypes {
  [OrderMutation.setOrders]: OrderViewModel[] | null;
  [OrderMutation.setFilters]: any | null;
  [OrderMutation.setAssignOrder]: any | null;
  [OrderMutation.setCustomers]: any[] | null;
  [OrderMutation.loadOrders]: any[] | null;
  [OrderMutation.loadOrdersPicker]: OrderListOutput[];
  [OrderMutation.setOrdersQC]: OrderQCViewModel[] | null;
  [OrderMutation.setOrdersPicker]: OrderListOutput[];
  [OrderMutation.setOrdersPickerCount]: number;
  [OrderMutation.setOrderQCFilters]: any | null;
  [OrderMutation.setPickerId]: number | null;
  [OrderMutation.setLastOrderIdCompleted]: number | null;
  [OrderMutation.setOrderDetail]: OrderViewModel | null;
  [OrderMutation.setOrderDetailAndSubjectId]: OrderViewModel | null;
  [OrderMutation.setOrderInvalidAccess]: boolean | null;
  [OrderMutation.setOrderLineItem]: OrderLineItemViewModel | null;
  [OrderMutation.setOrderLineItemNoteDialogVisible]: boolean;
  [OrderMutation.setLoading]: boolean;
  [OrderMutation.setPickerOrdersGet]: PickerOrdersGet;
}

export const enum OrderAction {
  setFilters = 'setFilters',
  setAssignOrder = 'setAssignOrder',
  loadCustomers = 'loadCustomers',
  loadOrders = 'loadOrders',
  setOrdersSearch = 'setOrdersSearch',
  pauseOrder = 'pauseOrder',
  unpauseOrder = 'unpauseOrder',
  cancelOrder = 'cancelOrder',
  loadOrderDetail = 'loadOrderDetail',
  loadOrderDetailAndSubjectId = 'loadOrderDetailAndSubjectId',
  priorityOrderUp = 'priorityOrderUp',
  priorityOrderDown = 'priorityOrderDown',
  loadOrdersQc = 'loadOrdersQC',
  loadOrdersPicker = 'loadOrdersPicker',
  setOrderQCFilters = 'setOrderQCFilters',
  setPickerId = 'setPickerId',
  setOrdersPicker = 'setOrdersPicker',
  printOrder = 'printOrder',
  setLastOrderIdCompleted = 'setLastOrderIdCompleted',
  downloadPalletTag = 'downloadPalletTag',
  showOrderLineItemNoteDialog = 'showOrderLineItemNoteDialog',
  editOrderLineItemNote = 'editOrderLineItemNote',
  reloadOrderFromOrders = 'reloadOrderFromOrders',
  setLoading = 'setLoading',
  updatePickerOrdersGet = 'updatePickerOrdersGet'
}

export interface OrderActions {
  [OrderAction.setFilters]: (filters: any) => Promise<void>;
  [OrderAction.setAssignOrder]: (order: any) => Promise<void>;
  [OrderAction.loadCustomers]: () => Promise<void>;
  [OrderAction.loadOrders]: () => Promise<void>;
  [OrderAction.pauseOrder]: (body: PauseOrderInput) => Promise<number>;
  [OrderAction.unpauseOrder]: (id: number) => Promise<number>;
  [OrderAction.cancelOrder]: (body: CancelOrderInput) => Promise<number>;
  [OrderAction.loadOrderDetail]: (id: number) => Promise<OrderViewModel>;
  [OrderAction.loadOrderDetailAndSubjectId]: (
    id: number
  ) => Promise<OrderViewModel>;
  [OrderAction.priorityOrderUp]: (id: number) => Promise<number>;
  [OrderAction.priorityOrderDown]: (id: number) => Promise<number>;
  [OrderAction.loadOrdersQc]: () => Promise<void>;
  [OrderAction.loadOrdersPicker]: () => Promise<void>;
  [OrderAction.setOrderQCFilters]: (filters: any) => Promise<void>;
  [OrderAction.setPickerId]: (id: number) => void;
  [OrderAction.printOrder]: (id: number) => void;
  [OrderAction.setLastOrderIdCompleted]: (id: number) => void;
  [OrderAction.showOrderLineItemNoteDialog]: (
    lineItem: OrderLineItemViewModel | null
  ) => void;
  [OrderAction.editOrderLineItemNote]: (payload: {
    id: number;
    note: string;
  }) => void;
  [OrderAction.reloadOrderFromOrders]: (payload: {
    id: number;
  }) => Promise<void>;
  [OrderAction.setLoading]: (payload: boolean) => void;
  [OrderAction.updatePickerOrdersGet]: ({
    updates
  }: {
    updates: Partial<PickerOrdersGet>;
  }) => Promise<void>;
}

export const enum OrderGetter {
  orders = 'orders',
  filters = 'filters',
  assignOrder = 'assignOrder',
  customers = 'customers',
  ordersSearched = 'ordersSearched',
  ordersQC = 'ordersQC',
  // ordersPicker = 'ordersPicker',
  orderQCFilters = 'orderQCFilters',
  lastOrderIdCompleted = 'lastOrderIdCompleted',
  orderDetail = 'orderDetail',
  orderDetailAndSubjectId = 'orderDetailAndSubjectId',
  orderInvalidAccess = 'orderInvalidAccess',
  pickerId = 'picker',
  orderLineItem = 'orderLineItem',
  orderLineItemNoteDialogVisible = 'orderLineItemNoteDialogVisible',
  loading = 'loading'
}

export interface OrderGetterTypes {
  [OrderGetter.orders]: OrderViewModel[] | null;
  [OrderGetter.filters]: any | null;
  [OrderGetter.assignOrder]: any | null;
  [OrderGetter.customers]: any[] | null;
  [OrderGetter.ordersSearched]: OrderViewModel[] | null;
  [OrderGetter.ordersQC]: OrderQCViewModel[] | null;
  // [OrderGetter.ordersPicker]: PickerOrderViewModel[] | null;
  [OrderGetter.orderQCFilters]: any | null;
  [OrderGetter.pickerId]: number | null;
  [OrderGetter.lastOrderIdCompleted]: number | null;
  [OrderGetter.orderDetail]: OrderViewModel | null;
  [OrderGetter.orderDetailAndSubjectId]: OrderViewModel | null;
  [OrderGetter.orderInvalidAccess]: boolean | null;
  [OrderGetter.orderLineItem]: OrderLineItemViewModel | null;
  [OrderGetter.orderLineItemNoteDialogVisible]: boolean;
  [OrderGetter.loading]: boolean;
}

export const ORDER_STATE_NAMESPACE = 'OrderState';
export const OrderNamespace = namespace(ORDER_STATE_NAMESPACE);

export type OrderModule = Override<
  Module<OrderStateTypes, NwdRootState>,
  TypedModule<
    OrderStateTypes,
    OrderMutationPayloadTypes,
    OrderActions,
    OrderGetterTypes,
    NwdRootState
  >
>;

import { SystemUserModel, AuthResultViewModel, SwaggerException, UserLoginInput } from '@/api/api';
import NwdLocations, { GetUserHome } from '@/router/nwd-router';
import { dispatchAuthAction } from '@/store/auth/auth.dispatch';
import { AuthAction, AuthNamespace, AuthGetter } from '@/store/auth/auth.module.types';
import { dispatchConfigurationAction } from '@/store/configuration/configuration.dispatch';
import { ConfigurationAction } from '@/store/configuration/configuration.module.types';
import { VForm } from '@/utilities/form';
import Vue from 'vue';
import Component from 'vue-class-component';
import XExpandAlert from '@/components/_generics/x-expand-alert.vue';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';
import { ScanToPickPermissions, testPermission } from '@/utilities/auth/auth.permissions';
import { Route } from 'vue-router';

@Component({
  name: 'Login',
  components: {
    XExpandAlert
  }
})
export default class Login extends Vue {
  $refs!: Vue['$refs'] & {
    form: VForm;
    alert: XExpandAlert;
  };

  isValid = false;
  isLoading = false;
  username = '';
  password = '';
  errorMessage = '';

  @AuthNamespace.Getter(AuthGetter.credential)
  readonly localCredential!: AuthResultViewModel;

  readonly requireRule = (value: number | string): boolean | string => {
    return !!value || 'Required';
  };

  get isAuthenticated(): boolean {
    return !!this.localCredential;
  }

  mounted(): void {
    if (this.localCredential == null || !this.canUseApp()) {
      return;
    }

    console.log('Login created', this.localCredential);
    if (this.isAuthenticated)
      this.hasHistory()
        ? this.$router.back()
        : this.$router.push({
            name: NwdLocations.picker.orders.name,
            replace: true
          });
  }

  canUseApp(): boolean {
    const userPermissions = this.localCredential?.permissions;
    console.log('userPermissions', userPermissions);
    // first we check to see if the user has the permissions to access this app
    let hasOne = false;
    for (const p of ScanToPickPermissions) {
      hasOne = testPermission(userPermissions ?? [], p);
      if (hasOne) break;
    }

    console.log('hasOne', hasOne);
    if (!hasOne) {
      this.$refs.alert.setMessage(
        `You do not have the required permissions to access this application. Please contact your administrator.`,
        'error'
      );
      return false;
    }

    return true;
  }

  public hasHistory(): boolean {
    return window.history.length > 2;
  }

  async onSubmit(): Promise<void> {
    try {
      this.errorMessage = '';
      if (!this.$refs.form.validate()) {
        return;
      }

      this.isLoading = true;
      const body = {
        email: this.username,
        password: this.password
      } as UserLoginInput;

      await dispatchAuthAction(AuthAction.loginCredential, body);

      if (this.localCredential == null || !this.canUseApp()) {
        return;
      }

      await this.navigateByRole();
      this.clearForm();
    } catch (e) {
      if (e instanceof SwaggerException) {
        const { message } = JSON.parse(e.response);
        this.$refs.alert.setMessage(message, 'error');
        this.errorMessage = message;
      } else {
        throw e;
      }
    } finally {
      this.isLoading = false;
    }
  }

  onFormChange(): void {
    this.isValid = this.username != '' && this.password != '';
  }

  clearForm(): void {
    this.username = '';
    this.password = '';
    this.$refs.form?.resetValidation();
  }

  private async navigateByRole(): Promise<Route | undefined> {
    const currentUserInfo = await dispatchAuthAction(AuthAction.getCurrentUserInfo);

    const userPermissions = currentUserInfo?.permissions;
    if (!userPermissions) {
      dispatchAlertAction(AlertAction.showError, `You do not have any permissions. Please contact your administrator.`);
      return;
    }

    return await this.$router.push({
      name: GetUserHome(userPermissions)
    });
  }
}

import {
  ConfigurationViewModel,
  LookupAndReasonTypesViewModel,
  OrderInterruptionViewModel,
  OrderLineItemViewModel,
  AmsOrdersClient,
  OrderViewModel,
  ReturnOrderInput,
  AmsOrderStatusEnum,
  OrderItemViewModel,
  AmsOrderLineItemModel,
  AmsOrderLineStatusEnum
} from '@/api/api';
import NwdLocations from '@/router/nwd-router';
import { STATUS_PICKED } from '@/shared/constants/common';
import { Component, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { PickerGetter, pickerNamespace } from '../../_state/picker-module-type';
import OrderDetailHeader from '../OrderDetailHeader.vue';
import { ConfigurationGetter, ConfigurationNamespace } from '@/store/configuration/configuration.module.types';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';
import { getFormatStringDate, getFormatStringHour } from '@/utilities/datetime-converter';
import moment from 'moment-timezone';

import {
  GetLaneReservationsCaseBottleText,
  GetWarehouseBadgeColor,
  GetLineItemWarehouse
} from '@/utilities/lane-utils';

const codes = ['NEOINV', 'INVCQTLOC', 'EOS', 'OREALER', 'REVC', 'CANC', 'ASIGTO'];

@Component({
  components: {
    OrderDetailHeader
  }
})
export default class OrderLineItems extends Vue {
  public readonly GetLaneReservationsCaseBottleText = GetLaneReservationsCaseBottleText;
  public readonly GetWarehouseBadgeColor = GetWarehouseBadgeColor;
  public readonly GetLineItemWarehouse = GetLineItemWarehouse;

  public readonly AmsOrderStatusEnum = AmsOrderStatusEnum;
  public readonly router = this.$router;

  @pickerNamespace.Getter(PickerGetter.order)
  readonly order!: OrderViewModel;
  @ConfigurationNamespace.Getter(ConfigurationGetter.configurations)
  readonly configuration!: ConfigurationViewModel;

  get headers() {
    const tHeaders: any[] = [
      { text: 'Lane/s', value: 'laneReservations' },
      { text: 'Varietal', value: 'varietal', width: '20%' },
      { text: 'Status', value: 'status' },
      { text: 'Vintage', value: 'vintage' },
      { text: 'SKU', value: 'partNo' },
      { text: 'Bottle Size / Configuration', value: 'size' },
      { text: '# Cases', value: 'totalCases' }
    ];

    if (this.$vuetify.breakpoint.smAndUp) {
      tHeaders.push({ value: 'direct' });
    }

    return tHeaders;
  }

  search = '';
  STATUS_PICKED = STATUS_PICKED;

  get orderItems(): OrderItemViewModel[] {
    return this.order!.orderItems || [];
  }

  localtz = '';
  lastPausedDetail: OrderInterruptionViewModel | undefined | null = null;
  lastReturnedDetail: OrderInterruptionViewModel | undefined | null = null;

  get reasons(): { text: string | undefined; value: string | undefined }[] {
    return this.createReason(this.configuration?.lookupAndReasonTypes);
  }

  get lastPauseUserFullName(): string {
    return this.lastPausedDetail && this.lastPausedDetail.interrupterUser
      ? `${this.lastPausedDetail.interrupterUser.firstName} ${this.lastPausedDetail.interrupterUser.lastName}`
      : '';
  }

  get lastReturnUserFullName(): string {
    return this.lastReturnedDetail?.interrupter?.name || '';
  }

  public async mounted(): Promise<void> {
    this.localtz = moment.tz.guess();
    const lastPauseInterruption =
      this.order?.orderInterruptionsPaused && this.order?.orderInterruptionsPaused.length > 0
        ? this.order?.orderInterruptionsPaused[0]
        : undefined;
    const lastReturnInterruption =
      this.order?.orderInterruptionsReturned && this.order?.orderInterruptionsReturned.length > 0
        ? this.order?.orderInterruptionsReturned[0]
        : undefined;
    this.lastPausedDetail = lastPauseInterruption;
    this.lastReturnedDetail = lastReturnInterruption;
  }

  navigateToLineItem(
    item: OrderLineItemViewModel | { orderId: number; id: number; upcCode: string },
    withUpcQuery = false
  ): RawLocation {
    const location = NwdLocations.picker.orderDetail.orderLineItem({
      orderId: item.orderId,
      orderItemId: item.id
    });

    if (withUpcQuery) {
      location.query = {
        upc: item.upcCode!
      };
    }
    return location;
  }

  navigateToLineItemFromRow(item: OrderLineItemViewModel) {
    this.router.push(this.navigateToLineItem(item));
  }

  public async onSendToBackPicker(reasonCode: string, reasonNote: string): Promise<void> {
    const data = {
      reasonCode: reasonCode,
      reasonNote: reasonNote
    } as ReturnOrderInput;
    const ordersClient = new AmsOrdersClient();
    await ordersClient.returnOrder(data, this.order!.id!);
    dispatchAlertAction(AlertAction.showSuccess, 'Sent back to clerk.');
    this.goBackHome();
  }

  public async goBackHome(): Promise<void> {
    const { $router } = this;
    const homePath: RawLocation = NwdLocations.picker.orders;
    try {
      await $router.push(homePath);
    } catch (error) {
      console.error('Error while navigating to home:', error);
    }
  }

  public onSearch(val: string): void {
    const matchLineItem = this.order.orderItems?.find((li) => li.upc === val);
    if (matchLineItem) {
      const location = this.navigateToLineItem(
        {
          orderId: matchLineItem.orderId,
          id: matchLineItem.id,
          upcCode: matchLineItem.upc!
        },
        true
      );

      this.$router.push(location);
    }
  }

  public getBackgroundColor(): string {
    const { orderStatusCode } = this.order;

    if (orderStatusCode === AmsOrderStatusEnum.Paused) {
      return 'var(--color-progress-bar-paused)';
    } else if (orderStatusCode === AmsOrderStatusEnum.Returned) {
      return 'var(--color-progress-bar-returned)';
    } else {
      return '';
    }
  }

  public getProcessDate(date: Date): string {
    if (!date) {
      return '';
    }
    return `${getFormatStringDate(date, this.localtz)} ${this.getHour(date)}`;
  }

  getReturnReasonText(reasonCode: string | undefined): string {
    const reasonDescription = this.configuration?.lookupAndReasonTypes?.find((x) => x.code == reasonCode)?.name;

    return reasonDescription ? reasonDescription : '';
  }

  private createReason(
    arr: LookupAndReasonTypesViewModel[] | undefined
  ): { text: string | undefined; value: string | undefined }[] {
    if (arr === undefined) return [];
    const queryCodes = arr.filter((c) => codes.includes(c.code as string));
    return queryCodes.map((s) => ({ text: s.name, value: s.code }));
  }

  private getHour(date: Date): string {
    return getFormatStringHour(date, this.localtz);
  }

  getItemClass(item: OrderItemViewModel) {
    return {
      'color-checked': item.statusCode === AmsOrderLineStatusEnum.Picked,
      'complete-bg': item.statusCode === AmsOrderLineStatusEnum.Picked,
      'returned-item': item.returnReason != null,
      'returned-item-line': item.returnReason != null
    };
  }
}

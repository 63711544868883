import {
  AmsOrderLineItemsClient,
  OrderLineItemViewModel,
  AmsOrdersClient,
  ReportsClient,
  OrderItemViewModel,
  OrderQCViewModel,
  OrderViewModel
} from '@/api/api';
import { ISection } from '../_components/QcOrderItemSection';
import {
  QcAction,
  QcGetter,
  QcModuleType,
  QcMutation,
  QcState
} from './qc-module-type';
import { isIosSafari } from '@/utilities/utils';
import { NwdRootState } from '@/store/nwd-web-root-state';
import { DispatchReturnType } from '@/store/utility/dispatch-return-type';
import { ActionContext } from 'vuex';

const initialSections: ISection[] = [
  {
    title: 'Production Date/s',
    icon: 'mdi-map-marker',
    key: 'laneReservations',
    isConfirm: undefined,
    reasonCode: 'OLN'
  },
  {
    title: 'SKU',
    icon: 'mdi-qrcode-scan',
    key: 'partNo',
    isConfirm: undefined,
    reasonCode: 'XXX'
  },
  {
    title: 'Storage Customer',
    icon: 'mdi-store',
    key: 'wineryName',
    isConfirm: undefined,
    reasonCode: 'OLCM'
  },

  // redundant with description
  // {
  //   title: 'Varietal',
  //   icon: 'mdi-bottle-wine',
  //   key: 'name',
  //   isConfirm: undefined,
  //   reasonCode: 'OLN'
  // },
  {
    title: 'Vintage',
    icon: 'mdi-calendar-today',
    key: 'yearCode',
    isConfirm: undefined,
    reasonCode: 'OLYC'
  },
  {
    title: 'Description',
    icon: 'mdi-reorder-horizontal',
    key: 'description',
    isConfirm: undefined,
    reasonCode: 'OLDS'
  },
  {
    title: 'Bottle Size',
    icon: 'mdi-soy-sauce',
    key: 'sizeDescription',
    isConfirm: undefined,
    reasonCode: 'OLSD'
  },
  {
    title: 'Configuration',
    icon: 'mdi-tally-mark-5',
    key: 'unitsPerCase',
    isConfirm: undefined,
    reasonCode: 'OLSD'
  },
  {
    title: 'Special Instructions',
    icon: 'mdi-exclamation-thick',
    key: 'specialInstructions',
    isConfirm: undefined,
    reasonCode: 'OLSI'
  },
  {
    title: 'Cases / Bottles Quantity',
    icon: 'mdi-package-variant-closed',
    key: 'quantity',
    isConfirm: undefined,
    reasonCode: 'OLQT'
  }
];

export function ConvertLineItemToSections(
  orderLineItem: OrderLineItemViewModel
): ISection[] {
  const sections = initialSections.map((s) => ({ ...s }));
  if (orderLineItem.isValid !== undefined && orderLineItem.isValid !== null) {
    sections.forEach((s) => {
      const reason = orderLineItem.orderLineItemAndReasons?.find(
        (li) => li.reasonCode === s.reasonCode
      );
      s.isConfirm = !reason;
      s.reasonForFailed = reason?.reasonNote;
    });
  }
  return sections;
}

export const qcModule: QcModuleType = {
  namespaced: true,
  state: {
    [QcState.orders]: [],
    [QcState.order]: undefined,
    [QcState.orderLineItem]: undefined,
    [QcState.previousOrderLineItem]: undefined,
    [QcState.nextOrderLineItem]: undefined,
    [QcState.orderLineItemSections]: initialSections.map((s) => ({ ...s })),
    [QcState.completeOrderDialog]: false,
    [QcState.checkItemDialog]: false,
    [QcState.lastPrintOrderId]: undefined,
    [QcState.orderQcInput]: { searchText: '', timeframe: 2 }
  },
  mutations: {
    [QcMutation.setOrders]: (state, payload) => {
      state.orders = payload;
    },
    [QcMutation.setOrder]: (state, payload) => {
      state.order = payload;
    },
    [QcMutation.setOrderLineItem]: (state, payload) => {
      state.orderLineItem = payload;
    },
    [QcMutation.setPreviousOrderLineItem]: (state, payload) => {
      state.previousOrderLineItem = payload;
    },
    [QcMutation.setNextOrderLineItem]: (state, payload) => {
      state.nextOrderLineItem = payload;
    },
    [QcMutation.setOrderLineItemSections]: (state, payload) => {
      state.orderLineItemSections = payload;
    },
    [QcMutation.setCompleteOrderDialog]: (state, payload) => {
      state.completeOrderDialog = payload;
    },
    [QcMutation.setCheckItemDialog]: (state, payload) => {
      state.checkItemDialog = payload;
    },
    [QcMutation.setLastPrintOrderId]: (state, payload) => {
      state.lastPrintOrderId = payload;
    },
    [QcMutation.setOrderQcInput]: (state, payload) => {
      state.orderQcInput = payload;
    }
  },
  actions: {
    [QcAction.getOrders]: async ({ commit, state }) => {
      const { searchText, timeframe } = state.orderQcInput;
      console.log('searchText', searchText);
      const client = new AmsOrdersClient();
      const res = await client.getOrdersQC(searchText, timeframe);
      commit(QcMutation.setOrders, res);
    },
    [QcAction.getOrder]: async ({ commit }, payload) => {
      const client = new AmsOrdersClient();
      const order = await client.getOrderQc(payload.orderId);
      order.orderItems = order.orderItems?.sort((a, _) => a.isValid ? -1 : 1);
      commit(QcMutation.setOrder, order);
      return order;
    },
    [QcAction.getOrderLineItem]: async ({ commit, state }, payload) => {
      const client = new AmsOrderLineItemsClient();
      const orderLineItem = await client.getOrderLineItem(
        payload.orderLineItemId
      );
      commit(QcMutation.setOrderLineItem, orderLineItem);

      const sections: ISection[] = initialSections.map((s) => ({
        ...s,
        isConfirm: undefined
      }));

      if (orderLineItem.isValid !== undefined &&
        orderLineItem.isValid !== null) {
        sections.forEach((s) => {
          const reason = orderLineItem.orderLineItemAndReasons?.find(
            (li) => li.reasonCode === s.reasonCode
          );
          s.isConfirm = !reason;
          s.reasonForFailed = reason?.reasonNote;
        });
      }

      commit(QcMutation.setOrderLineItemSections, sections);

      const order = state.order;

      if (order) {
        order.orderItems?.sort((a, _) => a.isValid ? -1 : 1);
        commit(QcMutation.setOrder, state.order);

        commit(
          QcMutation.setPreviousOrderLineItem,
          order.orderItems?.slice().reverse().find(x => x.isValid && x.id != orderLineItem.id)
        );
        commit(
          QcMutation.setNextOrderLineItem,
          state.order?.orderItems?.find(x => !x.isValid && x.id != orderLineItem.id)
        );
      }

      return orderLineItem;
    },
    [QcAction.goNextOrderLineItem]: async ({ dispatch, state }) => {
      if (!state.nextOrderLineItem) {
        return;
      }
      await dispatch(QcAction.getOrderLineItem, {
        orderLineItemId: state.nextOrderLineItem.id
      });
    },
    [QcAction.goBackOrderLineItem]: async ({ dispatch, state }) => {
      if (!state.previousOrderLineItem) {
        return;
      }
      await dispatch(QcAction.getOrderLineItem, {
        orderLineItemId: state.previousOrderLineItem.id
      });
    },
    [QcAction.goToOrderLineItem]: async ({ dispatch, state }, payload) => {
      if (!payload.orderLineItemId) {
        return;
      }
      await dispatch(QcAction.getOrderLineItem, {
        orderLineItemId: payload.orderLineItemId
      });
    },
    [QcAction.updateOrderLineItem]: async ({ commit, state }, payload) => {
      const index = state.order?.orderItems?.findIndex(x => x.id == payload.orderLineItem.id);
      if (index != null && index > -1) {
        state.order?.orderItems?.splice(index, 1, payload.orderLineItem);
        commit(QcMutation.setOrder, state.order);
      }
    },
    [QcAction.showCompleteOrderDialog]: async ({ commit }) => {
      commit(QcMutation.setCompleteOrderDialog, true);
    },
    [QcAction.hideCompleteOrderDialog]: async ({ commit }) => {
      commit(QcMutation.setCompleteOrderDialog, false);
    },
    [QcAction.showCheckItemDialog]: async ({ commit }) => {
      commit(QcMutation.setCheckItemDialog, true);
    },
    [QcAction.hideCheckItemDialog]: async ({ commit }) => {
      commit(QcMutation.setCheckItemDialog, false);
    },
    [QcAction.printPickTicket]: async ({ commit, state }, payload) => {
      if (!state.order || !state.order.id) {
        return;
      }
      const baseUrl = process.env.VUE_APP_API_BASE_URL;
      const pdfUrl = `${baseUrl}/api/Reports/PickTicketReport/pdf?orderId=${payload.orderId}`;

      if (isIosSafari()) {
        window.location.href = pdfUrl;
      } else {
        window.open(pdfUrl, '_blank');
      }

      commit(QcMutation.setLastPrintOrderId, payload.orderId);
    },
    [QcAction.updateOrderQcInput]: async ({ commit, dispatch }, payload) => {
      commit(QcMutation.setOrderQcInput, payload);
      await dispatch(QcAction.getOrders);
    },
  },
  getters: {
    [QcGetter.orders]: (state) => state.orders,
    [QcGetter.order]: (state) => state.order,
    [QcGetter.orderLineItem]: (state) => state.orderLineItem,
    [QcGetter.previousOrderLineItem]: (state) => state.previousOrderLineItem,
    [QcGetter.nextOrderLineItem]: (state) => state.nextOrderLineItem,
    [QcGetter.orderLineItemSections]: (state) => state.orderLineItemSections
  }
};

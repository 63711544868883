import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import {
  ApplicationUserViewModel,
  AuthResultViewModel,
  ClockedTimeModel,
  UserLoginInput,
  ScanToPickUserStatusEnum
} from '@/api/api';
import { NwdRootState } from '../nwd-web-root-state';
import { TypedModule } from '../utility/module-utility-types';

export const enum UserState {
  lastClockedTime = 'lastClockedTime',
  currentStatus = 'currentStatus'
}

export interface UserStateType {
  [UserState.lastClockedTime]: ClockedTimeModel | null;
  [UserState.currentStatus]: ScanToPickUserStatusEnum | null;
}

export const enum UserMutation {
  setLastClockedTime = 'setLastClockedTime',
  setCurrentStatus = 'setCurrentStatus'
}

interface UserMutationTypes {
  [UserMutation.setLastClockedTime]: ClockedTimeModel | null;
  [UserMutation.setCurrentStatus]: ScanToPickUserStatusEnum | null;
}

export const enum UserAction {
  refreshClockedTime = 'refreshLastClockedTime',
  setStatus = 'setStatus',
  startClock = 'startClock',
  pauseClock = 'pauseClock',
  stopClock = 'stopClock'
}

export interface UserActions {
  [UserAction.refreshClockedTime]: () => Promise<ClockedTimeModel | null>;
  [UserAction.setStatus]: (status: ScanToPickUserStatusEnum) => Promise<ClockedTimeModel | null>;
  [UserAction.startClock]: () => Promise<ClockedTimeModel | null>;
  [UserAction.pauseClock]: () => Promise<ClockedTimeModel | null>;
  [UserAction.stopClock]: () => Promise<ClockedTimeModel | null>;
}

export const enum UserGetter {
  currentStatus = 'currentStatus',
  lastClockedTime = 'lastClockedTime'
}

export interface UserGetterType {
  [UserGetter.lastClockedTime]: ClockedTimeModel | null;
  [UserGetter.currentStatus]: ScanToPickUserStatusEnum | null;
}

export const USER_STATE_NAMESPACE = 'UserState';
export const UserNamespace = namespace(USER_STATE_NAMESPACE);

export type UserModule = Override<
  Module<UserStateType, NwdRootState>,
  TypedModule<
    UserStateType,
    UserMutationTypes,
    UserActions,
    UserGetterType,
    NwdRootState
  >
>;


import { AdminClient, WarehousePrinterClientModel } from '@/api/api';
import NwdLocations from '@/router/nwd-router';
import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import { AlertAction } from '@/store/alert/alert.module-types';
import { Component, Vue } from 'vue-property-decorator';
import moment from 'moment';

@Component({})
export default class AdminPrinterClients extends Vue {
  public readonly NwdLocations = NwdLocations;
  public readonly moment = moment;

  public isLoading = false;
  public printerClients: WarehousePrinterClientModel[] = [];
  public headers = [
    { text: 'Computer Name', value: 'machineName' },
    { text: 'Is Online', value: 'isOnline' },
    { text: '# of Printers', value: 'printerCount' },
    { text: 'Client ID', value: 'clientId' },
    { text: 'Last Seen', value: 'dateModified' },
  ];

  async mounted(): Promise<void> {
    const adminClient = new AdminClient();
    try {
      this.isLoading = true;
      this.printerClients = await adminClient.getPrinterClients();
    } catch (error) {
      console.error(error);
      dispatchAlertAction(AlertAction.showError, 'Failed to load printers');

    } finally {
      this.isLoading = false;
    }
  }
}

import {
  ConfigurationAction,
  ConfigurationMutation,
  ConfigurationState,
  ConfigurationModule,
  ConfigurationGetter
} from './configuration.module.types';
import { ConfigurationsClient } from '@/api/api';

export const configurationModule: ConfigurationModule = {
  namespaced: true,
  state: {
    [ConfigurationState.configurations]: null
  },
  mutations: {
    [ConfigurationMutation.loadConfigurations]: (state, payload) => {
      state[ConfigurationState.configurations] = payload;
    }
  },
  actions: {
    [ConfigurationAction.loadConfigurations]: async ({ commit, state }) => {
      if (state.configurations) {
        return;
      }
      const client = new ConfigurationsClient();
      const configurations = await client.getConfigurations();
      commit(ConfigurationMutation.loadConfigurations, configurations);
    }
  },
  getters: {
    [ConfigurationGetter.configurations]: (state) => {
      return state[ConfigurationGetter.configurations];
    }
  }
};

import { AmsOrderLineItemModel, OrderItemViewModel, OrderLineItemViewModel, OrderViewModel } from '@/api/api';
import moment from 'moment-timezone';

function murmurhash3_32_gc(key: string, seed = 0) {
  const remainder = key.length % 4;
  const bytes = key.length - remainder;
  const c1 = 0xcc9e2d51;
  const c2 = 0x1b873593;
  let i = 0,
    h1 = seed;

  while (i < bytes) {
    let k1 =
      (key.charCodeAt(i) & 0xff) |
      ((key.charCodeAt(++i) & 0xff) << 8) |
      ((key.charCodeAt(++i) & 0xff) << 16) |
      ((key.charCodeAt(++i) & 0xff) << 24);
    ++i;

    k1 = Math.imul(k1, c1);
    k1 = (k1 << 15) | (k1 >>> 17);
    k1 = Math.imul(k1, c2);

    h1 ^= k1;
    h1 = (h1 << 13) | (h1 >>> 19);
    h1 = Math.imul(h1, 5) + 0xe6546b64;
  }

  let k1 = 0;

  switch (remainder) {
    case 3:
      k1 ^= key.charCodeAt(i + 2) << 16;
    case 2:
      k1 ^= key.charCodeAt(i + 1) << 8;
    case 1:
      k1 ^= key.charCodeAt(i);
      k1 = Math.imul(k1, c1);
      k1 = (k1 << 15) | (k1 >>> 17);
      k1 = Math.imul(k1, c2);
      h1 ^= k1;
  }

  h1 ^= key.length;

  // Finalization mix
  h1 ^= h1 >>> 16;
  h1 = Math.imul(h1, 0x85ebca6b);
  h1 ^= h1 >>> 13;
  h1 = Math.imul(h1, 0xc2b2ae35);
  h1 ^= h1 >>> 16;

  return h1 >>> 0;
}

export const GetWarehouseBadgeColor = (str: string): string => {
  function hslToHex(h: number, s: number, l: number): string {
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n: number) => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color)
        .toString(16)
        .padStart(2, '0'); // Convert to hex and pad with zero if necessary
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }

  // Generate a hash from the input string
  const hash = murmurhash3_32_gc(str);
  if (str.length === 0) return '#000000'; // Return black for empty strings

  // Map the hash value to HSL color space
  const h = (hash / 0xffffffff) * 360; // Hue value between 0 and 359
  const s = 40; // Saturation at 70%
  const l = 80; // Lightness at 30% (dark enough for white text)

  // Convert HSL color to hexadecimal format
  return hslToHex(h, s, l);
};

export const GetLineItemWarehouse = (
  lineItem: AmsOrderLineItemModel | OrderItemViewModel | OrderLineItemViewModel,
  order: OrderViewModel
): string => {
  // ensure string is not null or whitespace
  const stringFallback = (strings: (string | undefined)[]): string =>
    strings.find((s) => s != null && s.trim() !== '') || '';

  return stringFallback([lineItem.shippingLocCode, order.locationCode, '??']);
};

/**
 * Returns the text to display for the lane. If only a single lane is reserved, just displays the lane.
 * @param item
 * @returns
 */
export const GetLaneReservationsCaseBottleText = (
  item: AmsOrderLineItemModel | OrderItemViewModel | OrderLineItemViewModel
) => {
  if (item.laneReservations === undefined || item.laneReservations.length === 0) {
    return 'Unknown';

    // just return the lane if there is only one, we always display the total cases/bottles to pick anyways
  } else if (item.laneReservations.length === 1) {
    return item.laneReservations[0].lane;
  }

  const laneTextArr: string[] = [];
  item.laneReservations?.forEach((lane, i) => laneTextArr.push(GetLaneReservationText(item, i)));
  return laneTextArr.join(', ');
};

export const GetLaneReservationText = (
  item: AmsOrderLineItemModel | OrderItemViewModel | OrderLineItemViewModel,
  reservationIndex: number
) => {
  if (item.laneReservations === undefined || item.laneReservations.length === 0) {
    return 'Unknown';
  } else if (reservationIndex >= item.laneReservations.length) {
    console.warn(`Reservation index ${reservationIndex} is out of bounds for item ${item.id}`);
    return 'Unknown';
  }

  let unitsPerCase = 1;
  // in this case, we need to actually do some math
  // check if item has key 'unitsPercase', then check 'unitsPerCase' if not found

  if (Object.hasOwn(item, 'unitsPercase')) {
    unitsPerCase = (item as any).unitsPercase ?? 1;
  } else if (Object.hasOwn(item, 'unitsPerCase')) {
    unitsPerCase = (item as any).unitsPerCase ?? 1;
  }

  const lane = item.laneReservations[reservationIndex];
  const numCases = lane.reservationQuantity / unitsPerCase;
  const numBottles = lane.reservationQuantity % unitsPerCase;

  const casesStr = Number.isNaN(numCases) ? '?' : numCases.toFixed(0);
  const bottlesStr = Number.isNaN(numBottles) ? '?' : numBottles.toFixed(0);

  // these are actually in PST already, so just convert to UTC
  const formattedProdDate = moment(item.laneReservations[reservationIndex].productionDate).tz('UTC');

  const prodDateStr = formattedProdDate.isValid() ? ` - ${formattedProdDate.format('M/D/YYYY')}` : '';

  return `${lane.lane} (${casesStr} / ${bottlesStr})${prodDateStr}`;
};

export const GetCaseBottleText = (
  item: AmsOrderLineItemModel | OrderItemViewModel | OrderLineItemViewModel,
  reservationIndex: number
) => {
  if (item.laneReservations === undefined || item.laneReservations.length === 0) {
    return 'Unknown';
  } else if (reservationIndex >= item.laneReservations.length) {
    console.warn(`Reservation index ${reservationIndex} is out of bounds for item ${item.id}`);
    return 'Unknown';
  }

  let unitsPerCase = 1;
  // in this case, we need to actually do some math
  // check if item has key 'unitsPercase', then check 'unitsPerCase' if not found

  if (Object.hasOwn(item, 'unitsPercase')) {
    unitsPerCase = (item as any).unitsPercase ?? 1;
  } else if (Object.hasOwn(item, 'unitsPerCase')) {
    unitsPerCase = (item as any).unitsPerCase ?? 1;
  }

  const lane = item.laneReservations[reservationIndex];
  const numCases = lane.reservationQuantity / unitsPerCase;
  const numBottles = lane.reservationQuantity % unitsPerCase;

  const casesStr = Number.isNaN(numCases) ? '?' : numCases.toFixed(0);
  const bottlesStr = Number.isNaN(numBottles) ? '?' : numBottles.toFixed(0);

  return `${casesStr} / ${bottlesStr}`;
};

export const GetProductionDateText = (
  item: AmsOrderLineItemModel | OrderItemViewModel | OrderLineItemViewModel,
  reservationIndex: number
) => {
  if (item.laneReservations === undefined || item.laneReservations.length === 0) {
    return 'Unknown';
  } else if (reservationIndex >= item.laneReservations.length) {
    console.warn(`Reservation index ${reservationIndex} is out of bounds for item ${item.id}`);
    return 'Unknown';
  }

  // these are actually in PST already, so we need to add a day to get the correct date
  const formattedProdDate = moment(item.laneReservations[reservationIndex].productionDate).tz('UTC');
  const prodDateStr = formattedProdDate.isValid() ? `${formattedProdDate.format('M/D/YYYY')}` : '';
  return `${prodDateStr}`;
};

/**
 * Mainly intended for showing just the Production dates to QC users
 * @param item
 * @returns
 */
export const GetLineItemProductionDates = (
  item: AmsOrderLineItemModel | OrderItemViewModel | OrderLineItemViewModel
) => {
  if (item.laneReservations === undefined || item.laneReservations.length === 0) {
    return 'Unknown';
  }

  const prodDates: string[] = [];
  item.laneReservations?.forEach((lane, i) => prodDates.push(GetProductionDateText(item, i)));
  return prodDates.join(', ');
};

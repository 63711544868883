import { TypedModule } from '@/store/utility/module-utility-types';
import { namespace } from 'vuex-class';
import { Module } from 'vuex';
import { NwdRootState } from '../nwd-web-root-state';
import { PickerViewModel } from '@/api/api';

export interface PickerFilter {
  statusCode?: number;
  searchText?: string;
}

export const enum PickerCardState {
  pickerCards = 'pickerCards',
  filters = 'filters'
}

export interface PickerCardStateTypes {
  pickerCards: PickerViewModel[];
  filters: PickerFilter;
}

export const enum PickerCardMutation {
  setPickerCards = 'setPickerCards',
  setFilters = 'setFilters'
}

interface PickerCardMutationPayloadTypes {
  [PickerCardMutation.setPickerCards]: PickerViewModel[];
  [PickerCardMutation.setFilters]: PickerFilter;
}

export const enum PickerCardAction {
  setFilters = 'setFilters',
  loadPickers = 'loadPickers'
}

export interface PickerCardActions {
  [PickerCardAction.setFilters]: (filters: PickerFilter) => Promise<void>;
  [PickerCardAction.loadPickers]: () => Promise<void>;
}

export const enum PickerCardGetter {
  pickerCards = 'pickerCards',
  filters = 'filters',
  filteredPickers = 'filteredPickers'
}

export interface PickerCardGetterTypes {
  [PickerCardGetter.pickerCards]: PickerViewModel[];
  [PickerCardGetter.filters]: PickerFilter;
  [PickerCardGetter.filteredPickers]: any[];
}
export interface PickerCardModel {
  id: string;
  name: string | null;
  status: string | null;
  statusCode: number | null;
  inqueue: number | null;
  time: string | null;
  date: string | null;
  orders: Array<PickerViewModel>;
}

export const PICKER_CARD_STATE_NAMESPACE = 'pickerCardState';
export const PickerCardNamespace = namespace(PICKER_CARD_STATE_NAMESPACE);

export type PickerCardModule = Override<
  Module<PickerCardStateTypes, NwdRootState>,
  TypedModule<
    PickerCardStateTypes,
    PickerCardMutationPayloadTypes,
    PickerCardActions,
    PickerCardGetterTypes,
    NwdRootState
  >
>;

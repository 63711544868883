import { dispatchAlertAction } from '@/store/alert/alert.dispatch';
import {
  AlertAction,
  AlertGetter,
  AlertNamespace
} from '@/store/alert/alert.module-types';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Snackbar extends Vue {
  @AlertNamespace.Getter(AlertGetter.message)
  message!: string;

  @AlertNamespace.Getter(AlertGetter.canBeClosed)
  readonly canBeClosed!: boolean;

  @AlertNamespace.Getter(AlertGetter.timeout)
  readonly timeout!: number;

  @AlertNamespace.Getter(AlertGetter.color)
  readonly color!: string;

  visible = false;

  closeSnackbar(): void {
    this.visible = false;
    dispatchAlertAction(AlertAction.closeSnackbar);
  }

  created(): void {
    this.$watch(
      () => this.message,
      () => {
        this.message ? (this.visible = true) : (this.visible = false);
      }
    );

    this.$watch(
      () => this.visible,
      (value) => {
        if (value === false) {
          dispatchAlertAction(AlertAction.closeSnackbar);
        }
      }
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Validator = (v: any) => string | boolean;
export type ValidatorFactory = (label?: string) => Validator;

declare type FormNumber = number | string | null | undefined;

const isRequired = (label?: string) => (label ? `${label} is required` : '');
const isInvalid = (label?: string) => (label ? `${label} is invalid` : '');
const isEmptyNumber = (v: FormNumber) => v == null || v === '';

const REGEXP_ZIP = /^\d{5}$/;
const REGEXP_PHONE_NUMBER = /^\(?\d{3}\)?\s*-?\s*\d{3}\s*-?\s*\d{4}$/;
const REGEXP_EMAIL =
  /^[a-zA-Z0-9]+(\.[^@. ]+)?(@{1,1})[a-zA-Z0-9]+((\.[a-zA-Z]{2,}){1,2})$/;
const REGEXP_ROUTING_NUMBER = /^[0-9]{9}$/;
const REGEXP_CHECKNUMBER = /^\d{1,6}$/;
const REGEXP_ALPHANUMERIC = /^\w*$/;
const REGEXP_PASSWORD =
  /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{5,}).*/;

const NON_NUMERIC = /[^0-9.-]/g;
export function asNumber(value: FormNumber): number {
  return +value!.toString().replace(NON_NUMERIC, '');
}

export class Validators {
  static required: ValidatorFactory =
    (label?: string) =>
    (v: unknown): string | boolean =>
      !!v || v === 0 || isRequired(label);
  static requiredBoolean: ValidatorFactory =
    (label?: string) =>
    (v: boolean | null | undefined): string | boolean =>
      v != null || isRequired(label);
  static requireTrue: ValidatorFactory =
    (label?: string) =>
    (v: boolean): string | boolean =>
      v || isRequired(label);
  static requireSelection: ValidatorFactory =
    (label?: string) =>
    (v: unknown[]): string | boolean =>
      !!v.length || isRequired(label);
  static requireSelectionDifferent(value: unknown): ValidatorFactory {
    return (label?: string) => (v: unknown) => v !== value || isRequired(label);
  }
  static equalTo(value: number, message?: string): ValidatorFactory {
    return (label?: string) => (v: FormNumber) =>
      isEmptyNumber(v) || asNumber(v) === value || message || isInvalid(label);
  }
  static notEqualTo(value: number, message?: string): ValidatorFactory {
    return (label?: string) => (v: FormNumber) =>
      isEmptyNumber(v) || asNumber(v) !== value || message || isInvalid(label);
  }
  static greaterThan(value: number, message?: string): ValidatorFactory {
    return (label?: string) => (v: FormNumber) =>
      isEmptyNumber(v) || asNumber(v) > value || message || isInvalid(label);
  }
  static greaterThanOrEqualTo(
    value: number,
    message?: string
  ): ValidatorFactory {
    return (label?: string) => (v: FormNumber) =>
      isEmptyNumber(v) || asNumber(v) >= value || message || isInvalid(label);
  }
  static lessThan(value: number, message?: string): ValidatorFactory {
    return (label?: string) => (v: FormNumber) =>
      isEmptyNumber(v) || asNumber(v) < value || message || isInvalid(label);
  }
  static lessThanOrEqualTo(value: number, message?: string): ValidatorFactory {
    return (label?: string) => (v: FormNumber) =>
      isEmptyNumber(v) || asNumber(v) <= value || message || isInvalid(label);
  }

  static isInteger: ValidatorFactory =
    (label?: string) =>
    (v: FormNumber): string | boolean =>
      isEmptyNumber(v) || asNumber(v) % 1 === 0 || isInvalid(label);

  static isDecimal: ValidatorFactory =
    (label?: string) =>
    (v: FormNumber): string | boolean =>
      isEmptyNumber(v) ||
      (!isNaN(asNumber(v)) && isFinite(asNumber(v))) ||
      isInvalid(label);

  static pattern(
    pattern: RegExp,
    customErrorFunction?: (userInput: string | undefined) => string
  ): ValidatorFactory {
    return (label?: string) => (v: string) =>
      !v ||
      pattern.test(v) ||
      (customErrorFunction && customErrorFunction(label)) ||
      isInvalid(label);
  }
  static zipCode(): ValidatorFactory {
    return this.pattern(REGEXP_ZIP);
  }
  static validateZipCode(zipCode: string | undefined): boolean {
    return !!zipCode && REGEXP_ZIP.test(zipCode);
  }
  static date(): ValidatorFactory {
    return this.pattern(/^\d{1,2}\/\d{1,2}\/\d{4}$/);
  }
  static phone(): ValidatorFactory {
    return this.pattern(REGEXP_PHONE_NUMBER);
  }
  static validatePhone(phone: string | undefined): boolean {
    return !!phone && REGEXP_PHONE_NUMBER.test(phone);
  }
  static email(): ValidatorFactory {
    return this.pattern(REGEXP_EMAIL);
  }
  static password(): ValidatorFactory {
    return this.pattern(
      REGEXP_PASSWORD,
      () => 'Password is at least 5 characters, 1 special, 1 numeric.'
    );
  }
  static checkNumber(): ValidatorFactory {
    return this.pattern(REGEXP_CHECKNUMBER);
  }
  static routingNumber(): ValidatorFactory {
    return this.pattern(REGEXP_ROUTING_NUMBER);
  }
  static alphaNumeric(): ValidatorFactory {
    return this.pattern(REGEXP_ALPHANUMERIC);
  }
  static isLength(length: number): ValidatorFactory {
    return (label?: string) => (v: string) =>
      !v || v.length === length || `${label} must be ${length} characters`;
  }
  static matchPassword(otherPassword: string): ValidatorFactory {
    return () => (v: string) =>
      !v || v === otherPassword || 'Passwords must match';
  }
  static for(
    label?: string,
    ...validatorFactories: ValidatorFactory[]
  ): Validator[] {
    return validatorFactories.map((vf) => vf(label));
  }
}


import { Component, Prop, Vue } from 'vue-property-decorator';
import { OrderScanStatusEnum, OrderScanReportViewModel } from '@/api/api';

@Component
export default class QuaggaWrapper extends Vue {
  public readonly OrderScanStatusEnum = OrderScanStatusEnum;

  /** Whether or not the Quagga instance is actively reading. */
  @Prop({ default: null }) readonly scanReport!: OrderScanReportViewModel | null;
}

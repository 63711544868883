import { Module } from 'vuex';
import { namespace } from 'vuex-class';
import { ConfigurationViewModel } from '@/api/api';
import { NwdRootState } from '../nwd-web-root-state';
import { TypedModule } from '../utility/module-utility-types';

export const enum ConfigurationState {
  configurations = 'configurations'
}

export interface ConfigurationStateType {
  configurations: ConfigurationViewModel | null;
}

export const enum ConfigurationMutation {
  loadConfigurations = 'loadConfigurations'
}

interface ConfigurationMutationTypes {
  [ConfigurationMutation.loadConfigurations]: ConfigurationViewModel | null;
}

export const enum ConfigurationAction {
  loadConfigurations = 'loadConfigurations'
}

export interface ConfigurationActions {
  [ConfigurationAction.loadConfigurations]: () => Promise<void>;
}

export const enum ConfigurationGetter {
  configurations = 'configurations'
}

export interface ConfigurationGetterType {
  [ConfigurationGetter.configurations]: ConfigurationViewModel | null;
}

export const CONFIGURATION_STATE_NAMESPACE = 'ConfigurationState';
export const ConfigurationNamespace = namespace(CONFIGURATION_STATE_NAMESPACE);

export type ConfigurationModule = Override<
  Module<ConfigurationStateType, NwdRootState>,
  TypedModule<
    ConfigurationStateType,
    ConfigurationMutationTypes,
    ConfigurationActions,
    ConfigurationGetterType,
    NwdRootState
  >
>;
